<div class="clr-row">
    <div class="clr-col-lg-12 clr-col-md-12 clr-col-sm-12 clr-col-xs-12">
        <div class="card">
            <div class="card-block">
                <div class="card-title">
                    Company Lookup
                </div>
                <div class="card-text">
                    <one-field-search [label]="'Customer Number'"
                        [loading]="getCompanyRequest.isLoading || getAdminsRequest.isLoading"
                        (searchClicked)="searchCurrentCompany($event)"></one-field-search>
                </div>
            </div>

            <request-presenter [request]="getCompanyRequest">
                <ng-template>
                    <div class="card-block">
                        <h2 class="card-title">Informations</h2>
                        <div class="card-text">
                            <object-view [object]="currentCompany"></object-view>
                        </div>
                    </div>

                    <div class="card-block">
                        <h2 class="card-title">Admins</h2>
                        <div class="card-text">
                            <request-presenter [request]="getAdminsRequest">
                                <ng-template>
                                    <clr-datagrid [(clrDgSelected)]="selectedAdmins">
                                        <clr-dg-action-bar>
                                            <button class="btn btn-secondary btn-sm"
                                                [disabled]="selectedAdmins.length == 0"
                                                (click)="isRemoveAdminModalOpen = true">
                                                <clr-icon shape="minus-circle"></clr-icon>
                                                <span>Remove admin rights</span>
                                            </button>
                                            <button class="btn btn-secondary btn-sm"
                                                [disabled]="selectedAdmins.length == 0"
                                                (click)="showResendModal = true">
                                                <clr-icon shape="envelope"></clr-icon>
                                                Resend Admin Welcome Mail
                                            </button>

                                        </clr-dg-action-bar>

                                        <clr-dg-column>First Name</clr-dg-column>
                                        <clr-dg-column>Last Name</clr-dg-column>
                                        <clr-dg-column>Email</clr-dg-column>

                                        <clr-dg-row *clrDgItems="let user of admins" [clrDgItem]="user">
                                            <clr-dg-cell>{{user.givenName}}</clr-dg-cell>
                                            <clr-dg-cell>{{user.surname}}</clr-dg-cell>
                                            <clr-dg-cell>{{user.email}}</clr-dg-cell>
                                        </clr-dg-row>
                                    </clr-datagrid>
                                    <button class="btn btn-primary" style="margin-top: 12px"
                                        (click)="openAddAdminModal()">
                                        <clr-icon shape="user"></clr-icon> Add Admin
                                    </button>
                                    <clr-modal [(clrModalOpen)]="showResendModal">
                                        <h3 class="modal-title">Resend Admin Welcome Mail</h3>
                                        <div class="modal-body">
                                            <p>Are you sure you want to resend admin welcome mail to the following users?</p>
                                            <clr-datagrid>
                                                <clr-dg-column>First Name</clr-dg-column>
                                                <clr-dg-column>Last Name</clr-dg-column>
                                                <clr-dg-column>Email</clr-dg-column>
                                                <clr-dg-row *clrDgItems="let user of selectedAdmins" [clrDgItem]="user">
                                                    <clr-dg-cell>{{user.givenName}}</clr-dg-cell>
                                                    <clr-dg-cell>{{user.surname}}</clr-dg-cell>
                                                    <clr-dg-cell>{{user.email}}</clr-dg-cell>
                                                </clr-dg-row>
                                            </clr-datagrid>
                                            <form clrForm #checkForm="ngForm"
                                                (ngSubmit)="showResendModal = false; resendAdminWelcomeEmail();">
                                                <button type="button" class="btn btn-outline"
                                                    (click)="showResendModal = false">Cancel</button>
                                                <button type="submit" class="btn btn-primary">Submit</button>
                                            </form>
                                        </div>
                                        <div class="modal-backdrop" aria-hidden="true"></div>
                                    </clr-modal>
                                </ng-template>
                            </request-presenter>
                        </div>
                    </div>
                </ng-template>
            </request-presenter>
        </div>
    </div>
</div>

<clr-modal [(clrModalOpen)]="isAddAdminModalOpen" [clrModalStaticBackdrop]="true" [clrModalSize]="'lg'"
    [clrModalClosable]="false">
    <h3 class="modal-title">Add admin</h3>
    <div class="modal-body">
        <request-presenter [request]="addAdminRequest" [locker]="true"></request-presenter>
        <user-form #newAdminForm [getUserRequest]="getUserRequest"></user-form>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline" type="button"
            (click)="isAddAdminModalOpen = false; newAdminForm.reset();">Cancel</button>
        <button class="btn btn-primary" type="button" (click)="addAdmin()" [disabled]="!newAdminForm.isValid()">Add
            Admin</button>
    </div>
</clr-modal>

<clr-modal [(clrModalOpen)]="isRemoveAdminModalOpen" [clrModalStaticBackdrop]="true">
    <h3 class="modal-title">Remove admin</h3>
    <div class="modal-body">
        <fragment-request-presenter [fragmenter]="removeAdminRequestFragmenter" [locker]="'true'">
        </fragment-request-presenter>
        <p>Are you sure you want to remove {this.selectedAdmins.length, plural, =1 {1 admin} other
            {{{this.selectedAdmins.length}} admins}}?</p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline" type="button" (click)="isRemoveAdminModalOpen = false">Cancel</button>
        <button class="btn btn-danger btn-primary" type="button" (click)="removeAdmin()">Remove Admin</button>
    </div>
</clr-modal>
