<clr-datagrid>
    <clr-dg-column [clrDgField]="'decisionDate'">Decision Date</clr-dg-column>
    <clr-dg-column [clrDgField]="'creationDate'">Record Creation Date</clr-dg-column>
    <clr-dg-column [clrDgField]="'status'">Export Status</clr-dg-column>
    <clr-dg-column [clrDgField]="'source'">Source</clr-dg-column>
    <clr-dg-column [clrDgField]="'evaluationStatus'">Evaluation Status</clr-dg-column>

    <clr-dg-row *clrDgItems="let exportDetail of exportCheckDetails" [clrDgItem]="exportDetail">
        <clr-dg-cell>{{exportDetail.decisionDate}}</clr-dg-cell>
        <clr-dg-cell>{{exportDetail.creationDate}}</clr-dg-cell>
        <clr-dg-cell>
            <export-screening-icon [exportStatus]="exportDetail.status"></export-screening-icon>
            {{exportDetail.status}}
        </clr-dg-cell>
        <clr-dg-cell>{{exportDetail.source}}</clr-dg-cell>
        <clr-dg-cell>{{exportDetail.status ? "Complete" : "Incomplete"}}</clr-dg-cell>
        <ng-container ngProjectAs="clr-dg-row-detail" *ngIf="exportDetail.response">
            <clr-dg-row-detail *clrIfExpanded>
                <ng-container *ngIf="exportDetail.responseTree && exportDetail.status; else fallback">
                    <tree-view [tree]="exportDetail.responseTree"></tree-view>
                </ng-container>
                <ng-template #fallback>
                    {{exportDetail.response}}
                </ng-template>
            </clr-dg-row-detail>
        </ng-container>
    </clr-dg-row>
    <clr-dg-placeholder i18n>Export screening details are not available.</clr-dg-placeholder>
</clr-datagrid>