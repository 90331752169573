<clr-datagrid [(clrDgSelected)]="fulfillmentFailure.selected" [clrDgLoading]="fulfillmentFailure.loading">
    <clr-dg-action-bar>
        <button type="button" class="btn btn-sm btn-secondary" (click)="fulfillmentFailure.handleResubmit()"
            [disabled]="fulfillmentFailure.selected.length == 0">Resubmit</button>
        <button type="button" class="btn btn-sm btn-secondary btn-separated"
            (click)="fulfillmentFailure.showDelete = true"
            [disabled]="fulfillmentFailure.selected.length == 0">Delete</button>
        <button type="button" class="btn btn-sm btn-secondary btn-separated"
            (click)="fulfillmentFailure.handleRefresh()">Refresh</button>
    </clr-dg-action-bar>

    <clr-dg-column *ngFor="let column of fulfillmentFailure.orderDisplay">
        {{column.name}}
    </clr-dg-column>

    <clr-dg-placeholder>{{fulfillmentFailure.error || 'No Results'}}</clr-dg-placeholder>

    <clr-dg-row *clrDgItems="let item of fulfillmentFailure.failures" [clrDgItem]="item">
        <clr-dg-cell *ngFor="let column of fulfillmentFailure.orderDisplay">{{column.value(item.order)}}</clr-dg-cell>
    </clr-dg-row>

    <ng-container *ngIf="fulfillmentFailure.subscriptionDisplay.length > 0" ngProjectAs="clr-dg-detail">
        <clr-dg-detail *clrIfDetail="let item">
            <clr-dg-detail-header>Subscriptions for Order ID {{item.order.id}}</clr-dg-detail-header>
            <clr-dg-detail-body>
                <clr-datagrid>
                    <clr-dg-column *ngFor="let column of fulfillmentFailure.subscriptionDisplay">
                        {{column.name}}
                    </clr-dg-column>

                    <clr-dg-row *clrDgItems="let subscription of item.subscriptions" [clrDgItem]="subscription">
                        <clr-dg-cell *ngFor="let column of fulfillmentFailure.subscriptionDisplay">
                            {{column.value(subscription)}}</clr-dg-cell>
                    </clr-dg-row>
                </clr-datagrid>

            </clr-dg-detail-body>
        </clr-dg-detail>
    </ng-container>
</clr-datagrid>

<clr-modal [(clrModalOpen)]="fulfillmentFailure.showDelete">
    <h3 class="modal-title">Delete</h3>
    <div class="modal-body">
        <p>Are you sure you want to delete the selected orders?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="fulfillmentFailure.showDelete = false">Cancel</button>
        <button type="button" class="btn btn-primary"
            (click)="fulfillmentFailure.showDelete = false; fulfillmentFailure.handleDelete();">Ok</button>
    </div>
</clr-modal>