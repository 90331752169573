//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { LoggingService } from 'services/logging.service';
import { SharedModule } from '@Shared/Shared.module';
import { MsalModule } from '@Msal/msal.module';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Globals } from "services/globals.service";
import { LoginService } from 'services/login.service';

import { AppRoutingModule } from './app-routing.module';

import { AdminGuard } from "guards/admin.guard"

import { ClarityModule } from "@clr/angular";

import { ChartsModule } from 'ng2-charts';

import { AppComponent } from './app.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { UsersComponent } from '../users/users.component';
import { BusinessComponent } from '../business/business.component';
import { CompanyComponent } from '../company/company.component';
import { DiscoverySubscriptionWizardComponent } from '../business/subscriptionWizard/discoverySubscriptionWizard.component';
import { ACCTrialsWizardComponent } from '../business/subscriptionWizard/ACCTrialsWizard.component';
import { CloudSubscriptionWizardComponent } from '../business/subscriptionWizard/cloudSubscriptionWizard.component';
import { OnlineSubscriptionWizardComponent } from '../business/subscriptionWizard/onlineSubscriptionWizard.component';
import { SubscriptionTableComponent } from '../business/subscriptionTable/subscriptionTable.component';
import { WhitelistComponent } from '../userregistration/whitelist.component';
import { BlacklistComponent } from '../userregistration/blacklist.component';
import { UpdatableCollectionComponent } from 'userregistration/updatablecollection.component';
import { RecordsComponent } from '../records/records.component';
import { FilterService } from 'services/filter.service';
import { FulfillmentComponent } from './fulfillment/fulfillment.component';
import { ExportScreeningComponent } from './exportScreening/exportScreening.component';
import { ExportScreeningDetailsComponent } from './exportScreening/exportScreeningDetails.component';
import { ScreeningGuard } from 'guards/screening.guard';
import { FulfillmentTableComponent } from './fulfillment/fulfillment-table.component';
import { FulfillmentTableAppstoreComponent } from './fulfillment/fulfillment-table-appstore.component';
import { CompanyEditComponent } from 'business/subscriptionWizard/companyEdit.component';
import { CommonSubscriptionWizardComponent } from 'business/subscriptionWizard/commonSubscriptionWizard.component';
import { RenewSubscriptionWizardComponent } from 'business/subscriptionWizard/renewSubscriptionWizard.component';
import { MinEndDatePipe } from './pipes/min-end-date.pipe';
import { ErrorHandlerService } from 'services/errors.service';
import { CsvFileInputComponent } from './files/csvFileInput.component';
import { TreeViewComponent } from './exportScreening/treeView/treeView.component';
import { ExportScreeningIcon } from './exportScreening/exportScreeningIcon.component';

@NgModule({
    declarations: [
        AppComponent,
        UsersComponent,
        DashboardComponent,
        BusinessComponent,
        CompanyComponent,
        DiscoverySubscriptionWizardComponent,
        ACCTrialsWizardComponent,
        CloudSubscriptionWizardComponent,
        OnlineSubscriptionWizardComponent,
        SubscriptionTableComponent,
        WhitelistComponent,
        BlacklistComponent,
        RecordsComponent,
        UpdatableCollectionComponent,
        FulfillmentComponent,
        FulfillmentTableComponent,
        FulfillmentTableAppstoreComponent,
        ExportScreeningComponent,
        ExportScreeningDetailsComponent,
        CompanyEditComponent,
        CommonSubscriptionWizardComponent,
        RenewSubscriptionWizardComponent,
        MinEndDatePipe,
        CsvFileInputComponent,
        TreeViewComponent,
        ExportScreeningIcon
    ],
    imports: [
        BrowserModule,
        FormsModule,
        BrowserAnimationsModule,
        ClarityModule,
        ChartsModule,
        AppRoutingModule,
        SharedModule,
        MsalModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [
        Globals,
        AdminGuard,
        ScreeningGuard,
        LoggingService,
        LoginService,
        FilterService,
        { provide: ErrorHandler, useClass: ErrorHandlerService }
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA]  // to avoid errors with transclusion (https://scotch.io/tutorials/angular-2-transclusion-using-ng-content)
})

export class AppModule {
    constructor() {
    }
}
