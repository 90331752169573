<clr-datagrid [(clrDgSelected)]="selectedMessages" [clrDgLoading]="loading">
    <clr-dg-action-bar>
        <button type="button" class="btn btn-sm btn-secondary" (click)="handleResubmit()"
            [disabled]="selectedMessages.length == 0">Resubmit</button>
        <button type="button" class="btn btn-sm btn-secondary btn-separated"
            (click)="showDelete = true"
            [disabled]="selectedMessages.length == 0">Delete</button>
        <button type="button" class="btn btn-sm btn-secondary btn-separated"
            (click)="handleRefresh()">Refresh</button>
    </clr-dg-action-bar>

    <clr-dg-column *ngFor="let column of messageDisplay">
        {{column.name}}
    </clr-dg-column>

    <clr-dg-placeholder>{{error || 'No Results'}}</clr-dg-placeholder>

    <clr-dg-row *clrDgItems="let item of messages" [clrDgItem]="item">
        <clr-dg-cell *ngFor="let column of messageDisplay">{{column.value(item)}}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-detail *clrIfDetail="let message">
        <clr-dg-detail-header>Ordered Apps</clr-dg-detail-header>
        <clr-dg-detail-body>
          <clr-datagrid>
              <clr-dg-column>Product Code</clr-dg-column>
              <clr-dg-column>Quantity</clr-dg-column>

              <clr-dg-placeholder>No Results</clr-dg-placeholder>

              <clr-dg-row *clrDgItems="let app of message.order.apps" [clrDgItem]="app">
                  <clr-dg-cell>{{app.productCode}}</clr-dg-cell>
                  <clr-dg-cell>{{app.quantity}}</clr-dg-cell>
              </clr-dg-row>
          </clr-datagrid>
        </clr-dg-detail-body>
    </clr-dg-detail>
</clr-datagrid>

<clr-modal [(clrModalOpen)]="showDelete">
    <h3 class="modal-title">Delete</h3>
    <div class="modal-body">
        <p>Are you sure you want to delete the selected orders?</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="showDelete = false">Cancel</button>
        <button type="button" class="btn btn-primary"
            (click)="showDelete = false; handleDelete();">Ok</button>
    </div>
</clr-modal>