<div class="card">
    <div class="card-block">
        <div class="card-title">
            User Search
        </div>
        <form clrForm clrLayout="vertical">
            <clr-select-container>
                <label for="typeInput">Select</label>
                <select clrSelect id="typeInput" name="typeInput" [(ngModel)]="typeExportScreeningInput">
                    <option value="one">Single user</option>
                    <option value="multiple">Multiple users</option>
                </select>
            </clr-select-container>
            <csv-file-input *ngIf="typeExportScreeningInput == 'multiple'" itemName="User" fileInputLabel="Emails"
                (fileRowsEvent)="getFileRows($event)">
            </csv-file-input>

            <div *ngIf="typeExportScreeningInput == 'one'">
                <clr-input-container>
                    <label for="UserMail">User Email</label>
                    <input clrInput id="UserMail" name="UserMail" [(ngModel)]="searchMail" />
                </clr-input-container>
                <button id="searchBtn" class="btn btn-primary" (click)="searchUser()"
                    [disabled]="!searchMail || searchMail.trim() == ''">Search</button>
            </div>

        </form>
    </div>
    <div class="alert alert-success" role="alert" *ngIf="statusSuccess">
        <div class="alert-items">
            <div class="alert-item static">
                <clr-container class="alert-icon-wrapper">
                    <clr-icon class="alert-icon" shape="check-circle"></clr-icon>
                </clr-container>
                <span class="alert-text">
                    {{statusSuccess}}
                </span>
            </div>
        </div>
    </div>
    <div class="alert alert-danger" role="alert" *ngIf="statusFailure">
        <div class="alert-items">
            <div class="alert-item static">
                <clr-container class="alert-icon-wrapper">
                    <clr-icon class="alert-icon" shape="error-standard"></clr-icon>
                </clr-container>
                <span class="alert-text">
                    {{statusFailure}}
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="typeExportScreeningInput == 'multiple'">
        <div class="alert alert-danger" role="alert" *ngIf="this.notFoundUserEmails.length > 0 && !statusLoading">
            <div class="alert-items">
                <div class="alert-item static">
                    <clr-container class="alert-icon-wrapper">
                        <clr-icon class="alert-icon" shape="error-standard"></clr-icon>
                    </clr-container>
                    <span class="alert-text">
                        {{this.notFoundUserEmails.length}} user(s) not found. Please verify details.
                    </span>
                </div>
            </div>
        </div>
        <div *ngIf="this.multipleUserInfo.length > 0 && !statusLoading" class="card-block">
            <h3>Users Found</h3>

            <clr-datagrid class="datagrid-compact" [(clrDgSelected)]="selectedUsers">
                <clr-dg-action-bar>
                    <button type="button" class="btn btn-secondary btn-sm" (click)="rescreenAll()"
                        [disabled]="selectedUsers.length == 0">
                        Rescreen
                    </button>
                    <button type="button" class="btn btn-secondary btn-sm btn-separated"
                        (click)="addAllToGraylistManualCheck()"
                        [disabled]="shouldDisableAddAllToGraylist() || selectedUsers.length == 0">
                        Add to Graylist Manual Check
                    </button>
                    <button type="button" class="btn btn-secondary btn-sm btn-separated"
                        (click)="sendAllScreeningFailed()"
                        [disabled]="shouldDisableAddAllToGraylist() || selectedUsers.length == 0">
                        Send Screening Check Failed
                    </button>
                    <button type="button" class="btn btn-secondary btn-sm btn-separated"
                        (click)="setAllExportScreeningUsers(true)"
                        [disabled]="shouldDisableAddRemoveExportScreeningUsers(true)">
                        Add to Export Screening Users
                    </button>
                    <button type="button" class="btn btn-secondary btn-sm btn-separated"
                        (click)="setAllExportScreeningUsers(false)"
                        [disabled]="shouldDisableAddRemoveExportScreeningUsers(false)">
                        Remove from Export Screening Users
                    </button>
                    <a class="btn btn-secondary btn-sm btn-separated"
                        target="_blank"
                        href="{{exportScreeningEscalationFormLink}}"
                        *ngIf="showEscalateExportScreeningButton(selectedUsers)"
                       >
                        Escalate Export Screening
                    </a>
                </clr-dg-action-bar>

                <clr-dg-column>Export Screening Status</clr-dg-column>
                <clr-dg-column>Partner ID</clr-dg-column>
                <clr-dg-column>Email</clr-dg-column>
                <clr-dg-column>First Name</clr-dg-column>
                <clr-dg-column>Last Name</clr-dg-column>
                <clr-dg-column>Job Title</clr-dg-column>
                <clr-dg-column>Company</clr-dg-column>
                <clr-dg-column>Street Address</clr-dg-column>
                <clr-dg-column>City</clr-dg-column>
                <clr-dg-column>Postal Code</clr-dg-column>
                <clr-dg-column>State</clr-dg-column>
                <clr-dg-column>Country</clr-dg-column>
                <clr-dg-column>Marketing Phone</clr-dg-column>
                <clr-dg-column>Receive Updates and Offers</clr-dg-column>
                <clr-dg-column>Export Screening Users</clr-dg-column>

                <clr-dg-row *clrDgItems="let userInfo of this.multipleUserInfo" [clrDgItem]="userInfo">
                    <clr-dg-cell>
                        <export-screening-icon [exportStatus]="userInfo.exportScreeningStatus.status"></export-screening-icon>
                        {{userInfo.exportScreeningStatus.status}}{{userInfo.exportScreeningStatus.reason ?
                        ' - ' + userInfo.exportScreeningStatus.reason : ''}}
                    </clr-dg-cell>
                    <clr-dg-cell>{{userInfo.profile.id}}
                    </clr-dg-cell>
                    <clr-dg-cell>{{userInfo.profile.email}}
                    </clr-dg-cell>
                    <clr-dg-cell>{{userInfo.profile.givenName}}
                    </clr-dg-cell>
                    <clr-dg-cell>{{userInfo.profile.surname}}
                    </clr-dg-cell>
                    <clr-dg-cell>{{userInfo.profile.jobTitle}}
                    </clr-dg-cell>
                    <clr-dg-cell>{{userInfo.profile.companyName}}
                    </clr-dg-cell>
                    <clr-dg-cell>{{userInfo.profile.streetAddress}}
                    </clr-dg-cell>
                    <clr-dg-cell>{{userInfo.profile.city}}
                    </clr-dg-cell>
                    <clr-dg-cell>{{userInfo.profile.postalCode}}
                    </clr-dg-cell>
                    <clr-dg-cell>{{userInfo.profile.state}}
                    </clr-dg-cell>
                    <clr-dg-cell>{{userInfo.profile.country.firstName}}
                    </clr-dg-cell>
                    <clr-dg-cell>{{userInfo.profile.phoneNumber}}
                    </clr-dg-cell>
                    <clr-dg-cell>{{userInfo.profile.optsInForMarketingCommunication? "yes": "no"}}
                    </clr-dg-cell>
                    <clr-dg-cell>{{userInfo.isExportScreeningUser? "yes": "no"}}
                    </clr-dg-cell>

                </clr-dg-row>

                <clr-dg-detail *clrIfDetail="let userInfo">
                    <clr-dg-detail-header>Export Screening Details</clr-dg-detail-header>
                    <clr-dg-detail-body>
                        <export-screening-details [exportChecks]="userInfo.exportChecks"></export-screening-details>
                    </clr-dg-detail-body>
                </clr-dg-detail>

                <clr-dg-footer>
                    <clr-dg-pagination #pagination [clrDgPageSize]="10">
                        <clr-dg-page-size [clrPageSizeOptions]="[5,10,25,50,100]">Users per page</clr-dg-page-size>
                        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} users
                    </clr-dg-pagination>
                </clr-dg-footer>
            </clr-datagrid>
        </div>


        <div *ngIf="this.notFoundUserEmails.length > 0 && !statusLoading" class="card-block">
            <h3>Users Not Found</h3>

            <clr-datagrid class="datagrid-compact">
                <clr-dg-column>Email</clr-dg-column>
                <clr-dg-row *clrDgItems="let email of this.notFoundUserEmails">
                    <clr-dg-cell [style.color]="'rgb(255, 0, 0)'">
                        <clr-icon shape="error-standard" class="is-solid is-error"></clr-icon>
                        {{email}}
                    </clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>
                    <clr-dg-pagination #pagination [clrDgPageSize]="10">
                        <clr-dg-page-size [clrPageSizeOptions]="[5,10,25,50,100]">Users per page</clr-dg-page-size>
                        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{pagination.totalItems}} users
                    </clr-dg-pagination>
                </clr-dg-footer>
            </clr-datagrid>
        </div>

    </div>

    <clr-modal [clrModalOpen]="createExportCheck" (clrModelOpenChange)="createExportCheck = null">
        <h3 class="modal-title">{{createExportCheck?.title}}</h3>
        <div class="modal-body">
            <form clrForm *ngIf="createExportCheck">
                <clr-textarea-container>
                    <label for="justification">Justification</label>
                    <textarea clrTextarea id="justification" rows="5" cols="500" name="justification"
                        [(ngModel)]="createExportCheck.justification"></textarea>
                </clr-textarea-container>
                <br />
                <br />
                <div style="text-align: right;">
                    <button type="submit" class="btn btn-primary"
                        (click)="createExportCheck.process(); createExportCheck = null;">
                        Submit
                    </button>
                    <button type="button" class="btn btn-outline" (click)="createExportCheck = null">
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    </clr-modal>


    <div class="card-block" *ngIf="statusLoading">
        <div style="text-align:center; width:100%;">
            <span class='spinner spinner-lg'></span>
        </div>
    </div>

    <div *ngIf="typeExportScreeningInput == 'one'">

        <div class="card-block" *ngIf="!statusLoading && currentUserInfo?.exportScreeningStatus">
            <div class="card-title">
                Export Screening
            </div>
            <div class="card-text" style="display: flex; align-items: center;">
                <export-screening-icon [exportStatus]="currentUserInfo.exportScreeningStatus.status"></export-screening-icon>
                <div style="margin-left: 5px;">
                    {{currentUserInfo.exportScreeningStatus.status}}{{currentUserInfo.exportScreeningStatus.reason ?
                    ' - ' + currentUserInfo.exportScreeningStatus.reason : ''}}</div>
            </div>
        </div>
        <div class="card-block" *ngIf="!statusLoading && currentUserInfo?.exportChecks">
            <div class="card-title">
                Export Screening Details
            </div>
            <export-screening-details [exportChecks]="currentUserInfo.exportChecks"></export-screening-details>
        </div>
        <div class="card-block" *ngIf="!statusLoading && currentUserInfo">
            <div class="card-title">
                Profile
            </div>
            <form clrForm clrLayout="horizontal">
                <clr-input-container>
                    <label for="UserId">Partner ID</label>
                    <label style="font-weight: 400;">{{currentUserInfo.profile.id}}</label>
                </clr-input-container>

                <clr-input-container>
                    <label for="FirstName">First Name</label>
                    <input clrInput id="FirstName" name="FirstName" required pattern="^$|[\x00-\x7F]+"
                        [(ngModel)]="currentUserInfo.uiProfileModel.givenName" />
                    <clr-control-error>Please enter only
                        alphanumeric characters (A-Z, a-z, 0-9).</clr-control-error>
                </clr-input-container>

                <clr-input-container>
                    <label for="LastName">Last Name</label>
                    <input clrInput id="LastName" name="LastName" required pattern="^$|[\x00-\x7F]+"
                        [(ngModel)]="currentUserInfo.uiProfileModel.surname" />
                    <clr-control-error>Please enter only
                        alphanumeric characters (A-Z, a-z, 0-9).</clr-control-error>
                </clr-input-container>

                <clr-input-container>
                    <label for="Email">Email</label>
                    <label style="font-weight: 100;">{{currentUserInfo.profile.email}}</label>
                </clr-input-container>

                <clr-input-container>
                    <label for="Admin">Admin</label>
                    <label style="font-weight: 100;">{{currentUserInfo.isAdmin}}</label>
                </clr-input-container>

                <clr-input-container>
                    <label for="Job Title">Job Title</label>
                    <input clrInput id="JobTitle" name="JobTitle" [(ngModel)]="currentUserInfo.uiProfileModel.jobTitle"
                        pattern="^$|[\x00-\x7F]+" />
                    <clr-control-error>Please enter only
                        alphanumeric characters (A-Z, a-z, 0-9).</clr-control-error>
                </clr-input-container>

                <clr-input-container>
                    <label for="Company">Company</label>
                    <input clrInput id="Company" name="Company" [(ngModel)]="currentUserInfo.uiProfileModel.company"
                        pattern="^$|[\x00-\x7F]+" required />
                    <clr-control-error>Please enter only
                        alphanumeric characters (A-Z, a-z, 0-9).</clr-control-error>
                </clr-input-container>

                <address-enrichment #address [url]="environment.oidc" [(street)]="currentUserInfo.uiProfileModel.street"
                    [(city)]="currentUserInfo.uiProfileModel.city" [(postal)]="currentUserInfo.uiProfileModel.postal"
                    [(state)]="currentUserInfo.uiProfileModel.state"
                    [(country)]="currentUserInfo.uiProfileModel.country">
                </address-enrichment>

                <clr-input-container>
                    <label for="Phone">Marketing Phone</label>
                    <input clrInput id="Phone" name="Phone" pattern="^$|[\x00-\x7F]+"
                        [(ngModel)]="currentUserInfo.uiProfileModel.phone" />
                    <clr-control-error>Please enter only
                        alphanumeric characters (A-Z, a-z, 0-9).</clr-control-error>
                </clr-input-container>

                <clr-checkbox-container>
                    <clr-checkbox-wrapper>
                        <input type="checkbox" id="marketing" clrCheckbox
                            [(ngModel)]="currentUserInfo.uiProfileModel.marketingOption" value="marketingOption"
                            name="marketingOption" />
                        <label>I want to receive updates and offers from Ansys and its partners. I can unsubscribe
                            at any time.</label>
                    </clr-checkbox-wrapper>
                </clr-checkbox-container>
                <br />
                <div class="export-check buttons-container">
                    <div class="export-check">
                        <button type="button" class="btn btn-primary" (click)="updateUserProfile()"
                            [disabled]="shouldDisableUpdateUserProfile()">
                            Save Changes
                        </button>
                        <button type="button" class="btn btn-secondary" (click)="reset()">Close</button>
                    </div>

                    <div class="export-check">
                        <button type="button" class="btn btn-primary" (click)="rescreen()"
                            [disabled]="shouldDisableRescreen()">
                            Rescreen
                        </button>
                        <button type="button" class="btn btn-secondary"
                            *ngIf="currentUserInfo.exportScreeningStatus.canGraylistManualCheck"
                            (click)="createGraylistManualCheck()">
                            Add Graylist Manual Check
                        </button>
                        <button type="button" class="btn btn-secondary"
                            *ngIf="currentUserInfo.exportScreeningStatus.canGraylistManualCheck"
                            (click)="sendScreeningFailed()">
                            Send Screening Check Failed
                        </button>
                        <button type="button" class="btn btn-secondary"
                            *ngIf="isAdmin && currentUserInfo.canSetExportScreeningUser(true)"
                            (click)="setScreeningUser(true)">Add to Export Screening Users</button>
                        <button type="button" class="btn btn-secondary"
                            *ngIf="isAdmin && currentUserInfo.canSetExportScreeningUser(false)"
                            (click)="setScreeningUser(false)">Remove from Export Screening Users</button>
                        <a class="btn btn-secondary"
                            href="{{exportScreeningEscalationFormLink}}"
                            *ngIf="showEscalateExportScreeningButton([currentUserInfo])"
                            target="_blank"
                            >Escalate Export Screening</a>    
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>