//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Globals } from '../services/globals.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from 'environments/environment';
import { AuthService } from '@Authentication';
import { LoginService } from '../services/login.service';
import { Subscription } from '@rxjs';
import { LoggingService } from 'services/logging.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {

    public title: string = 'Portal';
    public environmentName: string;

    public token: string | null;
    private tokenSubscription: Subscription;

    private isLoggingOut: boolean;

    constructor(
        private auth: AuthService,
        private globals: Globals,
        private loggingService: LoggingService,
        public loginService: LoginService
    ) { }

    ngOnInit() {
        this.environmentName = environment.envName;

        this.tokenSubscription = this.auth.token.subscribe({
            next: token => {
                this.token = token;
                if (!this.isOnline && !this.isLoggingOut) {
                    this.login();
                }
            }
        });
    }

    ngOnDestroy() {
        this.tokenSubscription.unsubscribe();
    }

    login() {
        this.auth.login();
    }

    logout() {
        this.isLoggingOut = true;
        this.auth.logout();
    }

    public get inProd(): boolean {
        return environment.production;
    }

    public get isOnline() {
        return this.token != null;
    }

    public get isAuthorized(): boolean {
        return this.isAdmin || this.isExportScreeningUser;
    }

    public get isAdmin(): boolean {
        return this.globals.IsAdmin;
    }

    public get isExportScreeningUser(): boolean {
        return this.globals.IsExportScreeningUser;
    }
}
