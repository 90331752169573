//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, OnInit } from '@angular/core';
import { MsalHttpRequestService } from '@Msal/services/msalHttpRequest.service';
import { HttpRequestType } from '@Shared/utils/httpRequest';
import { FulfillmentFailure, DisplayData } from './fulfillment';
import { CloudOnboardingOrder, CloudOnboardingOrderSubscription, LearningHubOrder, LearningHubOrderSubscription, Order, OrderBase, OrderSubscription, SubscriptionBase } from './fulfillment-orders';


@Component({
  selector: 'app-fulfillment',
  templateUrl: './fulfillment.component.html',
  styleUrls: ['./fulfillment.component.css']
})
export class FulfillmentComponent implements OnInit {

  fulfillmentFailures: FulfillmentFailure<OrderBase, SubscriptionBase>[];

  constructor(private httpService: MsalHttpRequestService) { }

  ngOnInit(): void {
    this.fulfillmentFailures = [
      this.buildFailedOrders(),
      this.buildFailedLearningHubOrders(),
      this.buildFailedCloudOnboardingOrders()
    ];
  }

  buildFailedOrders(): FulfillmentFailure<Order, OrderSubscription> {
    const refreshRequest = this.httpService.getRequest(HttpRequestType.GET, 'Admin/EntitlementsFromPoisonQueue');
    const updateRequest = this.httpService.getRequest(HttpRequestType.POST, 'Admin/UpdateEntitlementsFromPoisonQueue');

    const orderDisplay = [
      new DisplayData<Order>('Message ID', x => x.messageId),
      new DisplayData<Order>('Order ID', x => x.id),
      new DisplayData<Order>('User Email', x => x.userEmail),
      new DisplayData<Order>('Company', x => x.companyOrg),
      new DisplayData<Order>('Source', x => x.source),
      new DisplayData<Order>('Subscriptions', x => x.subscriptionCount.toString()),
    ];

    const subscriptionDisplay = [
      new DisplayData<OrderSubscription>('Activation ID', x => x.activationId),
      new DisplayData<OrderSubscription>('Part Number', x => x.partNumber),
      new DisplayData<OrderSubscription>('Name', x => x.name),
    ];

    return new FulfillmentFailure<Order, OrderSubscription>('Failed Orders', refreshRequest, updateRequest, orderDisplay, subscriptionDisplay);
  }

  buildFailedLearningHubOrders(): FulfillmentFailure<LearningHubOrder, LearningHubOrderSubscription> {
    const refreshRequest = this.httpService.getRequest(HttpRequestType.GET, 'Admin/LearningHubEntitlementsFromPoisonQueue');
    const updateRequest = this.httpService.getRequest(HttpRequestType.POST, 'Admin/UpdateLearningHubEntitlementsFromPoisonQueue');

    const orderDisplay = [
      new DisplayData<LearningHubOrder>('Message ID', x => x.messageId),
      new DisplayData<LearningHubOrder>('Order ID', x => x.id),
      new DisplayData<LearningHubOrder>('User Email', x => x.userEmail),
      new DisplayData<LearningHubOrder>('Source', x => x.source),
      new DisplayData<LearningHubOrder>('Subscriptions', x => x.subscriptionCount.toString()),
    ];

    const subscriptionDisplay = [
      new DisplayData<LearningHubOrderSubscription>('Part Number', x => x.partNumber),
      new DisplayData<LearningHubOrderSubscription>('Name', x => x.name),
    ];

    return new FulfillmentFailure<LearningHubOrder, LearningHubOrderSubscription>('Failed Learning Hub Orders', refreshRequest, updateRequest, orderDisplay, subscriptionDisplay);
  }

  buildFailedCloudOnboardingOrders(): FulfillmentFailure<CloudOnboardingOrder, CloudOnboardingOrderSubscription> {
    const refreshRequest = this.httpService.getRequest(HttpRequestType.GET, 'Admin/CloudOnboardingEntitlementsFromPoisonQueue');
    const updateRequest = this.httpService.getRequest(HttpRequestType.POST, 'Admin/UpdateCloudOnboardingEntitlementsFromPoisonQueue');

    const orderDisplay = [
      new DisplayData<CloudOnboardingOrder>('Message ID', x => x.messageId),
      new DisplayData<CloudOnboardingOrder>('Internal Order ID', x => x.id),
      new DisplayData<CloudOnboardingOrder>('Order/PO ID', x => x.orderReference),
      new DisplayData<CloudOnboardingOrder>('User Email', x => x.userEmail),
      new DisplayData<CloudOnboardingOrder>('Company', x => x.companyOrg),
      new DisplayData<CloudOnboardingOrder>('Source', x => x.source),
      new DisplayData<CloudOnboardingOrder>('Error', x => x.errorMessage),
      new DisplayData<CloudOnboardingOrder>('Subscriptions', x => x.subscriptionCount.toString()),
    ];

    const subscriptionDisplay = [
      new DisplayData<CloudOnboardingOrderSubscription>('Activation ID', x => x.activationId),
      new DisplayData<CloudOnboardingOrderSubscription>('Part Number', x => x.partNumber),
      new DisplayData<CloudOnboardingOrderSubscription>('Name', x => x.name),
    ];

    return new FulfillmentFailure<CloudOnboardingOrder, CloudOnboardingOrderSubscription>('Failed Cloud Onboarding', refreshRequest, updateRequest, orderDisplay, subscriptionDisplay);
  }
}
