//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component } from '@angular/core';
import { AppStoreMessage } from './fulfillment-orders';
import { DisplayData } from './fulfillment';
import { HttpRequest, HttpRequestType } from '@Shared/utils/httpRequest';
import { MsalHttpRequestService } from '@Msal/services/msalHttpRequest.service';


@Component({
    selector: 'app-fulfillment-table-appstore',
    templateUrl: './fulfillment-table-appstore.component.html',
    styleUrls: ['./fulfillment.component.css']
})
export class FulfillmentTableAppstoreComponent {
    messageDisplay: DisplayData<AppStoreMessage>[] = [];
    messages: AppStoreMessage[] = [];
    selectedMessages: AppStoreMessage[] = [];
    showDelete: boolean = false;
    error: string | null = null;

    private refreshRequest: HttpRequest;
    private updateRequest: HttpRequest;
    
    get loading(): boolean {
        return this.refreshRequest.isLoading || this.updateRequest.isLoading;
    }
    
    constructor(private httpService: MsalHttpRequestService) {
        this.refreshRequest = this.httpService.getRequest(HttpRequestType.GET, 'Admin/AppStoreOrdersFromPoisonQueue');
        this.refreshRequest.onSuccess.on(request => this.messages = request.responseObj as AppStoreMessage[]);
        this.refreshRequest.onError.on(request => this.error = request.errorMessage);
        this.refreshRequest.send();
        
        this.updateRequest = this.httpService.getRequest(HttpRequestType.POST, 'Admin/UpdateAppStoreOrdersFromPoisonQueue');
        this.updateRequest.onComplete.on(() => this.refreshRequest.send());
        this.updateRequest.onError.on(request => this.error = request.errorMessage);

        this.messageDisplay = [
            new DisplayData<AppStoreMessage>('Message ID', x => x.messageId),
            new DisplayData<AppStoreMessage>('Order ID', x => x.order.id),
            new DisplayData<AppStoreMessage>('User Email', x => x.order.userEmail),
            new DisplayData<AppStoreMessage>('Source', x => x.order.source),
            new DisplayData<AppStoreMessage>('Order Reference', x => x.order.orderReference),
            new DisplayData<AppStoreMessage>('Network Host ID', x => x.order.networkHostId),
            new DisplayData<AppStoreMessage>('Network Host Name', x => x.order.networkHostName),
            new DisplayData<AppStoreMessage>('Error Message', x => x.errorMessage),
        ];
    }

    handleRefresh(): void {
        this.refreshRequest.send();
    }

    handleResubmit(): void {
        this.updateRequest.body = { messageIdsToResubmit: this.selectedMessages.map(x => x.messageId) };
        this.updateRequest.send();
    }

    handleDelete(): void {
        this.updateRequest.body = { messageIdsToDelete: this.selectedMessages.map(x => x.messageId) };
        this.updateRequest.send();
    }
}
