
//
// Copyright (C) 2023 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { ExportCheck } from "../exportCheck";
import { ITreeObject } from "../treeView/treeObject";

export class ExportScreeningDetail extends ExportCheck {
    public responseTree: ITreeObject | null;

    constructor(exportCheck: ExportCheck, responseTree: ITreeObject | null) {
        super();
        this.id = exportCheck.id;
        this.isCompliant = exportCheck.isCompliant;
        this.response = exportCheck.response;
        this.source = exportCheck.source;
        this.status = exportCheck.status;
        this.decisionDate = exportCheck.decisionDate;
        this.creationDate = exportCheck.creationDate;
        this.responseTree = responseTree;
    }
}