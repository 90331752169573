//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { HttpRequest, HttpRequestType } from '@Shared/utils/httpRequest';
import { MsalHttpRequestService } from "@Msal/services/msalHttpRequest.service";
import { Component, OnInit, ViewChild } from '@angular/core';
import 'chart.js';
import { BaseChartDirective } from "ng2-charts"
import { Observable } from 'rxjs/Rx';

class ObjectId {
    creationTime: Date;
}

class Localization {
    public ip: string;
    public country_code: string;
    public country_name: string;
    public region_code: string;
    public region_name: string;
    public city: string;
    public zip_code: string;
    public time_zone: string;
    public latitude: number;
    public longitude: number;
    public metro_code: number;
}

class Ping {
    localization: Localization;
    _id: ObjectId;
}

class Stats {
    public userCount: number;
    public userValidCount: number;
    public userDlDiscoveryCount: number;
    public dlDiscoveryCount: number;
    public sessionCount: number;
    public userPerCountry: Array<any>;
    public userPerDay: Array<any>;
    public dlPerCountryTop: Array<any>;
    public userPerSourceTop: Array<any>;
    public dlPerDay: Array<any>;
    public sessionPerCountry: Array<any>;
    public lastPings: Array<Ping>;
    public pingPerDay: Array<any>;
    public averageSessionDuration: number;
    public groupSessionDuration: Array<any>;
}

@Component({
    selector: 'dashboard',
    templateUrl: 'dashboard.component.html',
    styleUrls: ['dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
    @ViewChild("downloadChart", { static: false })
    private chart: BaseChartDirective;

    public getStatsRequest: HttpRequest;
    public stats: Stats | null = null;

    public downloadData: Array<any> = [{ data: [], label: "Downloads" }];
    public downloadLabel: Array<any> = [];

    public sessionsData: Array<any> = [{ data: [], label: "Users" }];
    public sessionsLabel: Array<any> = [];

    public sessionPerCountryData: number[] = [];
    public sessionPerCountryLabel: string[] = [];

    public sessionPerCategoryData: Array<any> = [{ data: [], label: "Sessions count" }];
    public sessionPerCategoryLabel: string[] = [];

    public userPerDayData: Array<any> = [{ data: [], label: "Users" }, { data: [], label: "ValidUsers" }, { data: [], label: "Users Dl DiscoveryLive" }];
    public userPerDayLabel: Array<any> = [];

    public chartOptions = {
        legend: {
            display: false,
        }
    }

    constructor(private httpService: MsalHttpRequestService) { }

    public refresh() {
        this.stats = null;
        this.getStatsRequest.send();
    }

    public onStatsReceived(s: Stats) {
        this.stats = s;
        this.downloadData[0].data = [];
        this.downloadLabel = [];
        let dlPerday = this.stats.dlPerDay;
        for (let i = 0; i < dlPerday.length; i++) {
            var kv = dlPerday[i];
            var date = (<string>kv.key).split("T")[0];
            this.downloadLabel.push(date);
            this.downloadData[0].data.push(kv.value);
        }

        this.sessionsData[0].data = [];
        this.sessionsLabel = [];
        let pingPerDay = this.stats.pingPerDay;
        for (let i = 0; i < pingPerDay.length; i++) {
            var kv = pingPerDay[i];
            var date = (<string>kv.key).split("T")[0];
            this.sessionsLabel.push(date);
            this.sessionsData[0].data.push(kv.value);
        }

        this.userPerDayData[0].data = [];
        this.userPerDayData[1].data = [];
        this.userPerDayData[2].data = [];
        this.userPerDayLabel = [];
        let userPerDay = this.stats.userPerDay;
        for (let i = 0; i < userPerDay.length; i++) {
            var kv = userPerDay[i];
            var date = (<string>kv.key).split("T")[0];
            this.userPerDayLabel.push(date);
            this.userPerDayData[0].data.push(kv.value.count);
            this.userPerDayData[1].data.push(kv.value.validCount);

            var dlDisc = kv.value.firstDownloadPerProductCount.discoverylive;
            this.userPerDayData[2].data.push(dlDisc == null ? 0 : dlDisc);
        }

        this.sessionPerCountryData = [];
        this.sessionPerCountryLabel = [];
        for (let i = 0; i < this.stats.sessionPerCountry.length; i++) {
            let kv = this.stats.sessionPerCountry[i];
            if (kv.value == 0)
                continue;

            this.sessionPerCountryLabel.push(kv.key);
            this.sessionPerCountryData.push(kv.value);
        }

        this.sessionPerCategoryData[0].data = [];
        this.sessionPerCategoryLabel = [];
        for (let i = 0; i < this.stats.groupSessionDuration.length; i++) {
            this.sessionPerCategoryData[0].data.push(this.stats.groupSessionDuration[i]);

            let label: string;
            if (i == this.stats.groupSessionDuration.length - 1) {
                label = ">" + (i * 10);
            } else {
                label = (i * 10) + "," + ((i + 1) * 10) + " min";
            }
            this.sessionPerCategoryLabel.push(label);
        }
    }

    ngOnInit() {
        this.getStatsRequest = this.httpService.getRequest(HttpRequestType.GET, "Admin/Stats");
        this.getStatsRequest.onSuccess.on(h => this.onStatsReceived(h.responseObj));
        this.refresh();
    }
}
