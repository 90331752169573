//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

/** Represents the required information to edit a subscription in the wizard. */

export class Product {
    public name: string;
    public partNumber: string;
    public productCategory: string;
}

export enum OpenMode {
    Creation,
    Renewal
}

export enum EndDateType {
    Expiration = "Expiration",
    Tecs = "Tecs"
}

export interface IGroupingKey {
    product: string | undefined,
    startDate: string | undefined,
    endDate: string | undefined,
    readonly endDateType: EndDateType | undefined,
    equals(other: IGroupingKey): boolean
}

export interface IEditableSubscription {
    product: string | undefined;
    quantity: string;
    startDate: string | undefined;
    endDate: string | undefined;
    isValid: boolean;
    endDateType: EndDateType;
    isLicenseExtensionChecked: boolean;

    getModelSubscriptions(): Array<Subscription>;
}

export class Subscription implements IEditableSubscription {
    public product: string | undefined;
    public quantity: string = "";
    public startDate: string | undefined;
    public endDate: string | undefined;
    public endDateType: EndDateType = EndDateType.Expiration;
    public activationId: string;

    public constructor() {
    }

    private quantityIsInteger() {
        return /^\+?[1-9]\d*$/.test(this.quantity);
    }

    public get isValid(): boolean {
        // if 04/dd/yyyy => undefined
        if (this.startDate === undefined || this.endDate === undefined) {
            return false;
        }

        return this.quantity != "" && this.quantityIsInteger() &&
            this.product !== undefined && this.product != "" &&
            new Date(this.startDate) < new Date(this.endDate);
    }

    public get isLicenseExtensionChecked(): boolean {
        return this.endDateType === EndDateType.Tecs;
    }

    public set isLicenseExtensionChecked(value: boolean) {
        this.endDateType = value ? EndDateType.Tecs : EndDateType.Expiration;
    }

    public getModelSubscriptions(): Array<Subscription> {
        return new Array<Subscription>(this);
    }
}

export class SubscriptionGroup implements IEditableSubscription {
    private _subscriptions: Array<Subscription> = new Array<Subscription>();

    public get product(): string | undefined {
        return this._subscriptions[0].product;
    }

    public set product(product: string | undefined) {
        this._subscriptions.forEach(subs => { subs.product = product; });
    }

    public get startDate(): string | undefined {
        return this._subscriptions[0].startDate;
    }

    public set startDate(date: string | undefined) {
        this._subscriptions.forEach(subs => { subs.startDate = date; });
    }

    public get endDate(): string | undefined {
        return this._subscriptions[0].endDate;
    }

    public set endDate(date: string | undefined) {
        this._subscriptions.forEach(subs => { subs.endDate = date; });
    }

    public get endDateType(): EndDateType {
        return this._subscriptions[0].endDateType;
    }

    public get isLicenseExtensionChecked(): boolean {
        return this.endDateType === EndDateType.Tecs;
    }

    public set isLicenseExtensionChecked(value: boolean) {
        let endDateType: EndDateType = value ? EndDateType.Tecs : EndDateType.Expiration;
        this._subscriptions.forEach(subs => { subs.endDateType = endDateType; });
    }

    public addSubscription(subscription: Subscription) {
        this._subscriptions.push(subscription);
    }

    public get subscriptions(): Array<Subscription> {
        return this._subscriptions;
    }

    public get isValid(): boolean {
        for (let subscription of this._subscriptions) {
            if (!subscription.isValid) return false;
        }
        return true;
    }

    public get quantity(): string {
        let sum = 0;
        for (let subscription of this._subscriptions) {
            sum += +subscription.quantity;
        }
        return sum.toString();
    }

    public getModelSubscriptions(): Array<Subscription> {
        return this._subscriptions;
    }
}
