<renewSubscriptionWizard #renewSubscriptionWizard [(open)]="openRenewSubscriptionsWizard" [products]="products">
</renewSubscriptionWizard>

<div>
    <h1>Lookup</h1>
    <form clrForm>
        <clr-control-container>
            <label for="companyId">Customer Number</label>
            <input clrControl type="text" id="companyId" name="companyId" [(ngModel)]="companyId" />
            <button class="btn btn-secondary btn-sm" (click)="findByCompany()"
                [disabled]="!searchIsNullOrEmpty(companyId)">Find</button>
        </clr-control-container>
        <clr-toggle-container>
            <clr-toggle-wrapper>
                <input type="checkbox" clrCheckbox name="includeExpired" [(ngModel)]="includeExpired" />
                <label>Include Companies With All Expired Subscriptions</label>
            </clr-toggle-wrapper>
        </clr-toggle-container>
    </form>
    <form clrForm>
        <clr-control-container>
            <label for="orderId">Order/PO ID</label>
            <input clrControl type="text" id="orderId" name="orderId" [(ngModel)]="orderId" />
            <button class="btn btn-secondary btn-sm" (click)="findByOrder()"
                [disabled]="!searchIsNullOrEmpty(orderId)">Find</button>
        </clr-control-container>
    </form>
    <form clrForm>
        <clr-control-container>
            <label for="userId">User Id</label>
            <input clrControl type="text" id="userId" name="userId" [(ngModel)]="userId" />
            <button class="btn btn-secondary btn-sm" (click)="findByUserId()"
                [disabled]="!searchIsNullOrEmpty(userId)">Find</button>
        </clr-control-container>
    </form>
    <form clrForm>
        <clr-control-container>
            <label for="userMail">User Mail</label>
            <input clrControl type="text" id="userMail" name="userMail" [(ngModel)]="userMail" />
            <button class="btn btn-secondary btn-sm" (click)="findByUserMail()"
                [disabled]="!searchIsNullOrEmpty(userMail)">Find</button>
        </clr-control-container>
    </form>
</div>
<div *ngIf="errorMessage" class="errorColor">Error - {{errorMessage}}</div>
<loading-presenter [isLoading]="isGetSubscriptionsRequestInProgress()" [isCenter]="true"></loading-presenter>
<locker [enabled]="isGetSubscriptionsRequestInProgress()"></locker>

<div *ngIf="subscriptions">
    <clr-datagrid [(clrDgSelected)]="selectedSubscriptions"
        [clrDgLoading]="isGetSubscriptionsRequestInProgress()">
        <clr-dg-action-bar>
            <button class="btn btn-secondary btn-sm" [disabled]="selectedSubscriptions.length == 0"
                (click)="openWizardForRenewal()">
                <clr-icon shape="times-calendar"></clr-icon>
                <span>Renew</span>
            </button>

            <button class="btn btn-secondary btn-sm btn-separated" [disabled]="selectedSubscriptions.length == 0"
                (click)="showDeleteConfirmationModel = true">
                <clr-icon shape="times-circle"></clr-icon>
                <span>Delete</span>
            </button>
            <clr-modal [(clrModalOpen)]="showDeleteConfirmationModel">
                <h3 class="modal-title">Delete subscriptions?</h3>
                <div class="modal-body">
                    <h5>This subscription will be permanently deleted.</h5>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline" (click)="showDeleteConfirmationModel = false" i18n>
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary"
                        (click)="this.deleteSelectedSubscriptions(); showDeleteConfirmationModel = false" i18n>
                        Delete
                    </button>
                </div>
            </clr-modal>

            <button class="btn btn-secondary btn-sm btn-separated" [disabled]="selectedSubscriptions.length == 0"
                (click)="showResendOrderConfirmationModel = true">
                <clr-icon shape="envelope"></clr-icon>
                <span>Resend Order Confirmation</span>
            </button>

            <clr-modal [(clrModalOpen)]="showResendOrderConfirmationModel">
                <h3 class="modal-title">Resend Order Confirmation</h3>
                <div class="modal-body">
                    <form clrForm clrLayout="vertical" #checkForm="ngForm"
                        (ngSubmit)="showResendOrderConfirmationModel = false; resendSubscriptionEmail();">
                        <clr-input-container class="full-width">
                            <label for="try_resendmail">Optional cc Email</label>
                            <input clrInput id="try_resendmail" name="mail" email #status="ngModel"
                                [(ngModel)]="tryResendMailModel.mail">
                        </clr-input-container>
                    </form>
                </div>
                <div class="modal-backdrop" aria-hidden="true"></div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-outline" (click)="showResendOrderConfirmationModel = false"
                        i18n>
                        Cancel
                    </button>
                    <button type="button" class="btn btn-primary"
                        (click)="this.resendSubscriptionEmail(); showResendOrderConfirmationModel = false" i18n>
                        Resend Order Confirmation
                    </button>
                </div>
            </clr-modal>
        </clr-dg-action-bar>

        <clr-dg-column>Activation ID</clr-dg-column>
        <clr-dg-column>Order/PO ID</clr-dg-column>
        <clr-dg-column [clrDgField]="'creationDate'">Creation Date</clr-dg-column>
        <clr-dg-column [clrDgField]="'startDate'">Start Date</clr-dg-column>
        <clr-dg-column [clrDgField]="'expirationDate'">Expiration Date</clr-dg-column>
        <clr-dg-column [clrDgField]="'tecsDate'">Tecs Date</clr-dg-column>
        <clr-dg-column>Product</clr-dg-column>
        <clr-dg-column>Renew Period</clr-dg-column>
        <clr-dg-column [clrDgField]="'assignment'">Assignment</clr-dg-column>
        
        <clr-dg-placeholder *ngIf="!includeExpired">Target does not exist or target does not have any active subscription.</clr-dg-placeholder>

        <clr-dg-row *clrDgItems="let subscription of subscriptions" [clrDgItem]="subscription"
            [clrDgSelectable]="isSubscriptionSelectable(subscription)">
            <clr-dg-cell>{{subscription.activationId}}</clr-dg-cell>
            <clr-dg-cell>{{subscription.orderId}}</clr-dg-cell>
            <clr-dg-cell>{{subscription.creationDate}}</clr-dg-cell>
            <clr-dg-cell>{{subscription.startDate}}</clr-dg-cell>
            <clr-dg-cell>{{subscription.expirationDate}}</clr-dg-cell>
            <clr-dg-cell>{{subscription.tecsDate}}</clr-dg-cell>
            <clr-dg-cell>{{subscription.productName}}</clr-dg-cell>
            <clr-dg-cell>{{subscription.renewalPeriod}}</clr-dg-cell>
            <ng-container *ngIf="subscription.assignment() == 'Deleted'">
                <clr-dg-cell class="errorColor">Deleted</clr-dg-cell>
            </ng-container>
            <ng-container *ngIf="subscription.assignment() != 'Deleted'">
                <clr-dg-cell>{{subscription.assignment()}}</clr-dg-cell>
            </ng-container>  
        </clr-dg-row>

        <clr-dg-footer>
            <request-page-presenter [controller]="getSubscriptionsController"></request-page-presenter>
        </clr-dg-footer>
    </clr-datagrid>
</div>