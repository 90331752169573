//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, Input } from '@angular/core';
import { FulfillmentFailure } from './fulfillment';
import { OrderBase, SubscriptionBase } from './fulfillment-orders';


@Component({
    selector: 'app-fulfillment-table',
    templateUrl: './fulfillment-table.component.html',
    styleUrls: ['./fulfillment.component.css']
})
export class FulfillmentTableComponent {

    @Input() fulfillmentFailure: FulfillmentFailure<OrderBase, SubscriptionBase>;

}
