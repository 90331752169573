
//
// Copyright (C) 2023 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, Input, OnInit } from "@angular/core";
import { ExportCheck } from "./exportCheck";
import { ExportScreeningDetail } from "./exportScreeningDetail/exportScreeningDetail";
import { ExportScreeningDetailFactory } from './exportScreeningDetail/detailFactory'

@Component({
    selector: 'export-screening-details',
    templateUrl: './exportScreeningDetails.component.html'
})
export class ExportScreeningDetailsComponent implements OnInit {
    public exportCheckDetails: ExportScreeningDetail[];

    @Input() private exportChecks: ExportCheck[];

    ngOnInit(): void {
        // reverse to order from newest to oldest
        this.exportCheckDetails = this.exportChecks.map<ExportScreeningDetail>(
            x => ExportScreeningDetailFactory.createDetail(x)).reverse();
    }
}