
//
// Copyright (C) 2023 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

export class ExportCheck {
  public id: string;
  public isCompliant: boolean;
  public response: string;
  public source: string;
  public status: string;
  public decisionDate: string;
  public creationDate: string;
}