<style>
    input {
        width: 250px
    }

    input[type="date"] {
        width: 200px;
    }
</style>

<clr-wizard #wizard (clrWizardOnCancel)="close.emit()" [(clrWizardOpen)]="open" clrWizardSize="xl">
    <clr-wizard-title>{{this.title}}</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'" [clrWizardButtonDisabled]="loading">Cancel</clr-wizard-button>
    <clr-wizard-button [type]="'previous'" [clrWizardButtonDisabled]="loading">Back</clr-wizard-button>
    <clr-wizard-button [type]="'next'" [clrWizardButtonDisabled]="loading">Next</clr-wizard-button>
    <clr-wizard-button [type]="'finish'" [clrWizardButtonDisabled]="loading">{{this.isInRenewalMode ? "Process Renewal"
        : "Create"}}</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="!companyEdit.isValid">
        <ng-template clrPageTitle>Company</ng-template>
        <form clrForm clrLayout="vertical">
            <clr-control-container class="full-width">
                <label for="companyId">Customer Number</label>
                <input clrControl type="text" placeholder="Customer Number" id="companyId" [(ngModel)]="companyId"
                    name="companyId" [disabled]="isInRenewalMode">
                <button *ngIf="!isInRenewalMode" class="btn btn-outline btn-sm" (click)="companyEdit.check(companyId)"
                    [disabled]="!companyId">Check</button>
            </clr-control-container>
        </form>
        <company-edit #companyEdit [(company)]="company"></company-edit>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!ascForm.isValid()">
        <ng-template clrPageTitle>Ansys Support Coordinator</ng-template>
        <user-form #ascForm [getUserRequest]="userRequest"></user-form>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!allSubscriptionsValid || !isOrderIdValid()">
        <ng-template clrPageTitle>Subscriptions</ng-template>
        <form clrForm>
            <clr-input-container>
                <label for="orderId">Order ID</label>
                <input clrInput type="text" id="orderId" [(ngModel)]="orderId" name="orderId">
            </clr-input-container>
        </form>
        <ng-content select="subscriptions"></ng-content>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="additionalCC.invalid.length > 0">
        <ng-template clrPageTitle>Confirmation email CC</ng-template>
        <div>
            Optional: use the CC field below to specify additional contact(s) you wish to CC. To copy multiple people,
            enter email addresses separated by commas.
        </div>
        <form clrForm>
            <clr-textarea-container>
                <label for="additionalCC">CC</label>
                <textarea clrTextarea id="additionalCC" [(ngModel)]="additionalCC.emails" name="additionalCC"
                    style="width: 100%" (blur)="additionalCC.findInvalidAddresses()">
                </textarea>
            </clr-textarea-container>
        </form>

        <div *ngIf="additionalCC.invalid.length > 0" class="errorBlock">
            <span>Invalid address:</span>
            <ul>
                <li *ngFor="let invalidAddress of additionalCC.invalid">
                    {{invalidAddress}}
                </li>
            </ul>
        </div>
    </clr-wizard-page>

    <clr-wizard-page clrWizardPagePreventDefaultNext="true" [clrWizardPagePreventDefaultCancel]="loading"
        (clrWizardPageOnCommit)="handleSubmit()">
        <ng-template clrPageTitle>Validation</ng-template>
        <locker [enabled]="loading"></locker>
        <div>
            <h4>Company Information</h4>
            <object-view [object]="company"></object-view>
        </div>
        <div>
            <h4>Ansys Support Coordinator Information</h4>
            <object-view [object]="ascForm.model"></object-view>
        </div>
        <div>
            <h4>Additional CC</h4>
            <ul *ngIf="additionalCC.getEmails().length > 0">
                <li *ngFor="let cc of additionalCC.getEmails()">
                    {{cc}}
                </li>
            </ul>
            <div *ngIf="additionalCC.getEmails().length == 0">None</div>
        </div>
        <div>
            <h4>Subscriptions</h4>
            <ul class="list">
                <li>Id : {{orderId}}</li>
            </ul>
            <ng-content select="summary"></ng-content>
        </div>
    </clr-wizard-page>
</clr-wizard>