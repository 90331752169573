//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Globals } from '../services/globals.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class AdminGuard implements CanActivate {

    constructor(
        private router: Router,
        private globals: Globals
    ) {
    }

    canActivate() {
        if (this.globals.IsAdmin) {
            return true;
        }

        this.router.navigate([this.globals.DefaultRoute]);
        return false;
    }
}
