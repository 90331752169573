//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MsalHttpRequestService } from "@Msal/services/msalHttpRequest.service";
import { HttpRequest, HttpRequestType } from "@Shared/utils/httpRequest";
import { Company } from "./company";
import { environment } from 'environments/environment';

@Component({
    selector: 'company-edit',
    templateUrl: 'companyEdit.component.html',
})
export class CompanyEditComponent implements OnInit {

    @Input() company: Company = new Company();
    @Output() companyChange = new EventEmitter<Company>();

    companyFound: boolean;
    companyRequest: HttpRequest;

    environment = environment;

    constructor(private httpService: MsalHttpRequestService) { }

    ngOnInit(): void {
        this.companyRequest = this.httpService.getRequest(HttpRequestType.GET, 'Admin/GetCompanyById');

        this.companyRequest.onStart.on(() => {
            this.companyFound = false;
        });

        this.companyRequest.onError.on(() => {
            this.companyFound = false;
        });

        this.companyRequest.onSuccess.on(request => {
            const resp = request.responseObj;
            this.company = new Company();
            this.company.id = request.query.id;

            if (!resp.error) {
                this.company.displayName = resp.displayName;
                this.company.city = resp.city;
                this.company.country = resp.country;
                this.company.state = resp.state;
                this.company.streetAddress = resp.streetAddress;
                this.company.postalCode = resp.postalCode;
                this.company.isNew = false;
                this.companyFound = true;
            } else {
                this.company.isNew = true;
                this.companyFound = false;
            }

            this.companyChange.emit(this.company);
        });
    }

    check(companyId: string): void {
        this.companyRequest.query.id = companyId;
        this.companyRequest.send();
    }

    handleStreetChange(street: string): void {
        this.company.streetAddress = street;
        this.companyChange.emit(this.company);
    }

    handleCityChange(city: string): void {
        this.company.city = city;
        this.companyChange.emit(this.company);
    }

    handlePostalChange(postal: string): void {
        this.company.postalCode = postal;
        this.companyChange.emit(this.company);
    }

    handleStateChange(state: string): void {
        this.company.state = state;
        this.companyChange.emit(this.company);
    }

    handleCountryChange(country: string): void {
        this.company.country = country;
        this.companyChange.emit(this.company);
    }

    reset(): void {
        this.companyChange.emit(new Company());
        this.companyFound = false;
        this.companyRequest.reset();
    }

    get isValid(): boolean {
        return this.companyFound || this.company.isValid;
    }
}
