
//
// Copyright (C) 2023 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { ExportCheck } from "../exportCheck";
import { ExportScreeningDetail } from "./exportScreeningDetail";
import { ITreeObject } from "../treeView/treeObject";
import { XmlResponseTreeObject } from "./responseTree/xmlResponseTree";
import { JsonResponseTreeObject } from "./responseTree/jsonResponseTree";

export module ExportScreeningDetailFactory {
    class ParseOutput<T> {
        isSuccessful: boolean;
        parseResult: T | null;
    }

    function tryParseXmlString(s: string): ParseOutput<Element> {
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(s, "text/xml");
        const errorNode = xmlDoc.querySelector("parsererror");

        if (errorNode) {
            return { isSuccessful: false, parseResult: null };
        } else {
            return { isSuccessful: true, parseResult: xmlDoc.documentElement };
        }
    }

    function tryParseJsonString(s: string): ParseOutput<any> {
        var parseResult: any | null;

        try {
            parseResult = JSON.parse(s);
        } catch (_) {
            parseResult = null;
        }

        return { isSuccessful: parseResult != null, parseResult: parseResult };
    }

    export function createDetail(exportCheck: ExportCheck): ExportScreeningDetail {
        const xml = tryParseXmlString(exportCheck.response);
        const json = tryParseJsonString(exportCheck.response);
        var responseTree : ITreeObject | null;
        if (xml.isSuccessful) {
            responseTree = new XmlResponseTreeObject(xml.parseResult!)
        } else if (json.isSuccessful) {
            responseTree = new JsonResponseTreeObject(json.parseResult);
        } else {
            responseTree = null;
        }
        return new ExportScreeningDetail(exportCheck, responseTree);
    }
}