<h1>User Action Records</h1>

<clr-datagrid [clrDgLoading]="loading" (clrDgRefresh)="refreshWithState($event)">
    <clr-dg-column [clrDgField]="'id'"> ID</clr-dg-column>
    <clr-dg-column [clrDgField]="'targetEmail'"> Target</clr-dg-column>
    <clr-dg-column [clrDgField]="'authorEmail'"> Author</clr-dg-column>
    <clr-dg-column [clrDgField]="'company'"> Company</clr-dg-column>
    <clr-dg-column [clrDgField]="'product'"> Product</clr-dg-column>
    <clr-dg-column>
        <clr-dg-filter>
            <select-filter [availableValues]="types" [property]="'actionName'"></select-filter>
        </clr-dg-filter>
        Action
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'date'"> Date</clr-dg-column>

    <clr-dg-placeholder>No Result {{noResultInfo}}</clr-dg-placeholder>

    <!-- if server driven use ngFor and not clrDgItems ! -->
    <clr-dg-row *ngFor="let record of records" [clrDgItem]="record">
        <clr-dg-cell>{{record.id}}</clr-dg-cell>
        <clr-dg-cell>{{record.targetEmail}}</clr-dg-cell>
        <clr-dg-cell>{{record.authorEmail}}</clr-dg-cell>
        <clr-dg-cell>{{record.company}}</clr-dg-cell>
        <clr-dg-cell>{{record.product}}</clr-dg-cell>
        <clr-dg-cell>{{record.actionName}}</clr-dg-cell>
        <clr-dg-cell>{{record.date}}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}}
        <clr-dg-pagination #pagination [(clrDgPage)]="currentPage" [clrDgPageSize]="pageSize"
            [clrDgTotalItems]="totalItem">
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>

<button class="btn btn-primary" (click)="refresh()">Refresh</button>