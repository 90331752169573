
//
// Copyright (C) 2023 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { ITreeObject } from "app/exportScreening/treeView/treeObject";

export class XmlResponseTreeObject implements ITreeObject {
    private children: Element[];

    constructor(element: Element) {
        this.children = Array.from(element.children);
    }

    private getChildElement(nodeName: string): Element | null {
        // Search all children to find the child with its name matching nodeName.
        for (let index = 0; index < this.children.length; index++) {
            if (this.children[index].nodeName == nodeName) {
                return this.children[index];
            }
        }
        return null;
    }

    getChildSubtree(childName: string): ITreeObject | null {
        const childElement = this.getChildElement(childName);
        if (childElement) {
            return new XmlResponseTreeObject(childElement!);
        } else {
            return null;
        }
    }
    getChidrenNames(): String[] {
        return this.children.map(x => x.nodeName);
    }
    isNullOrEmpty(nodeName: string): boolean {
        const childElement = this.getChildElement(nodeName);
        return childElement == null || childElement.textContent == null || childElement.textContent == "";
    }
    getChildValue(nodeName: string): string {
        return this.getChildElement(nodeName)!.textContent!;
    }
    hasChildren(nodeName: string): boolean {
        return this.getChildElement(nodeName)!.children.length > 0;
    }

}