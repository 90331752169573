//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { StringUtils } from "@Shared/utils/stringUtils";

export class Company {
    public isNew: boolean = false;
    public id: string | null;
    public displayName: string | null;
    public streetAddress: string | null;
    public postalCode: string | null;
    public city: string | null;
    public state: string | null;
    public country: string | null;

    public get isValid(): boolean {
        if (StringUtils.IsNullOrEmpty(this.id)) {
            return false;
        }

        if (!this.isNew) {
            return true;
        }

        return !StringUtils.IsNullOrEmpty(this.displayName) &&
            !StringUtils.IsNullOrEmpty(this.streetAddress) &&
            !StringUtils.IsNullOrEmpty(this.city) &&
            !StringUtils.IsNullOrEmpty(this.country);
    }
}
