//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { HttpRequest, HttpRequestType } from '@Shared/utils/httpRequest';
import { MsalHttpRequestService } from "@Msal/services/msalHttpRequest.service";
import { Component, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from "@clr/angular";
import { environment } from "environments/environment";
import { FilterService } from 'services/filter.service';
import { HttpErrorResponse } from '@angular/common/http';

class Users {
    public items: UserIndex[];
    public start: number;
    public count: number;
}

class UserIndex {
    public id: string;
    public displayName: string;
    public company: string;
    public email: string;
    public country: string;
    public creationDate: Date;
}

class UserProfile {
    public id: string;
    public givenName: string;
    public surname: string;
    public displayName: string;
    public companyName: string;
    public email: string;
    public city: string;
    public country: Country;
    public streetAddress: string;
    public state: string;
    public postalCode: string;
    public phoneNumber: string;
    public jobTitle: string;
    public optsInForMarketingCommunication: boolean;
}

class Country {
    public code: string;
    public names: string[];
    public firstName: string;
    public isInvalid: boolean
}

class User {
    public id: string;
    public welcomeDiscoveryLiveEmail: boolean;
    public thanksDiscoveryLiveEmail: boolean;
    public firstAccessSource: string;
    public profile: UserProfile;
    public exportChecks: ExportCheck[];
    public agreements: Agreement[];
    public lastProductDownload: any;
    public lastLocalization: any;
    public creationDate: Date;
    public showDeleteModal: boolean = false;
    public showSetARModal: boolean = false;
    public isExportScreeningUser: boolean = false;
    public isAdmin: boolean | null;
}

class ExportCheck {
    public id: string;
    public isCompliant: boolean;
    public response: string;
    public source: string;
    public status: string;
}

class Agreement {
    public isAccepted: boolean;
    public isSigned: boolean;
    public date: Date;
    public method: AgreementSignOffMethod;
}

class AgreementSignOffMethod {
    public description: string;
    public userEmailAddress: string;
}

@Component({
    selector: 'users',
    templateUrl: 'users.component.html',
})
export class UsersComponent implements OnInit {

    public noResultInfo: string | null = null;

    public currentState: ClrDatagridStateInterface | null = null;

    public currentPage: number = 1;
    public pageSize: number = 20;
    public totalItem: number = 0;
    public users: UserIndex[] = [];
    public selectedUsers: (UserIndex | undefined)[] = [];
    public selectedUsersDetails: User[] = [];
    public userDeletedStatus: string = "User deletion not started";
    public isConfirmationModalOpen: boolean = false;
    public exportControlSources: string[] = [
        '',
        'Amber Road',
        'E2Open',
        'Manual',
        'Invitation',
        'Asc Account',
        'Whitelist/Blacklist',
        'External Push Notification'
    ];

    public loading = false;
    public refreshingSelectedUsers = false;

    private refreshTimer: any = null;

    public getSelectedUserSubscriptionsRequest: HttpRequest;
    public getUsersRequest: HttpRequest;
    public getSearchedUserSubscriptionsRequest: HttpRequest;
    public getUserRequest: HttpRequest;

    constructor(
        private cloudService: MsalHttpRequestService,
        private filterService: FilterService
    ) { }

    public ngOnInit(): void {
        const getSubscriptionsEnpoint = "admin/GetSubscriptions";
        this.getSelectedUserSubscriptionsRequest = this.cloudService.getRequest(HttpRequestType.GET, getSubscriptionsEnpoint);
        this.getSearchedUserSubscriptionsRequest = this.cloudService.getRequest(HttpRequestType.GET, getSubscriptionsEnpoint);

        this.getUsersRequest = this.cloudService.getRequest(HttpRequestType.GET, "Admin/Users");
        this.getUsersRequest.onSuccess.on(h => {
            let us: Users = h.responseObj;

            this.refreshTimer = null;
            this.users = us.items;

            this.totalItem = us.count;
            this.selectedUsers = [];
            this.loading = false;
        });

        this.getUsersRequest.onError.on(h => {
            this.noResultInfo = "Error " + h.errorMessage.toString();
            this.loading = false;
        })

        this.getUserRequest = this.cloudService.getRequest(HttpRequestType.GET, "Admin/UserByEmail");
        this.getUserRequest.onSuccess.on(req => {
            this.azureUser.additionalInformation = req.responseObj;
        });
    }

    private resetFiltersChanged(filters: Array<any>) {
        for (var i = 0; i < filters.length; i++) {
            filters[i].changed = false;
        }
    }

    private filtersChanged(filtersA: Array<any>, filtersB: Array<any>): boolean {
        let res: boolean;
        if (filtersA.length != filtersB.length) {
            res = false;
        } else {
            res = true;
            for (var i = 0; i < filtersA.length; i++) {
                let filterA: any = filtersA[i];
                let filterB: any = filtersB[i];
                if (filterA.property != filterB.property || filterA.value != filterB.value || filterB.changed) {
                    res = false;
                    break;
                }
            }
        }

        this.resetFiltersChanged(filtersA);
        this.resetFiltersChanged(filtersB);
        return res;
    }

    public refresh() {
        this.refreshWithState(<ClrDatagridStateInterface>this.currentState);
    }

    private ignoreNextRefresh = false;
    public refreshWithState(state: ClrDatagridStateInterface) {
        if (this.ignoreNextRefresh) {
            this.ignoreNextRefresh = false;
            return;
        }

        let oldFiltersU: Array<any> | undefined = undefined;
        if (this.currentState != null) {
            oldFiltersU = this.currentState.filters;
        }
        if (oldFiltersU == undefined) {
            oldFiltersU = new Array();
        }
        let oldFilters: Array<any> = <Array<any>>oldFiltersU;

        this.currentState = state;
        this.noResultInfo = null;

        let sortBy: string = "Id";
        let sortReverse: boolean = false;
        if (state.sort != null) {
            sortBy = state.sort.by.toString();
            sortReverse = state.sort.reverse;
        }

        let filtersU: any[] | undefined = state.filters;
        if (filtersU == undefined) {
            filtersU = new Array();
        }
        let filters = <any[]>filtersU;

        if (this.refreshTimer) {
            clearTimeout(this.refreshTimer);
            this.refreshTimer = null;
        }
        if (!this.filtersChanged(oldFilters, filters)) {
            // add a timeout to not call request data to soon. let the user time to specify his filter value.
            this.refreshTimer = setTimeout(() => {
                if (this.currentPage != 1) {
                    this.ignoreNextRefresh = true;  // cause currentPage = 1 will call refresh
                }
                this.currentPage = 1;
                this.refreshUsers(0, this.pageSize, sortBy, sortReverse, filters);
            }, 1000);
        } else {
            const start = (state.page!.from || -1) >= 0
                ? state.page!.from as number
                : 0;
            this.refreshUsers(start, this.pageSize, sortBy, sortReverse, filters);
        }
    }

    private refreshUsers(pageStart: number, pageSize: number, sortBy: string, sortReverse: boolean, filters: Array<any>) {
        this.loading = true;

        this.getUsersRequest.query = {
            start: pageStart,
            count: pageSize,
            sortBy: sortBy,
            sortReverse: sortReverse
        };
        for (let i = 0; i < filters.length; i++) {
            this.getUsersRequest.query["filter_" + filters[i].property] = filters[i].value;
        }
        this.getUsersRequest.send();
    }

    selectedUserChange() {
        if (this.selectedUsers.length == 0) {
            this.selectedUsersDetails = [];
            return;
        }

        const idsToRemove: string[] = [];
        for (let selectedUserDetail of this.selectedUsersDetails) {
            if (!this.selectedUsers.some(x => x!.id == selectedUserDetail.id)) {
                idsToRemove.push(selectedUserDetail.id);
            }
        }

        this.selectedUsersDetails = this.selectedUsersDetails.filter(x => !idsToRemove.includes(x.id));

        const tracking: HttpRequest[] = [];
        for (let selectedUser of this.selectedUsers) {
            if (this.selectedUsersDetails.some(x => x.id == selectedUser!.id)) {
                continue;
            }

            const lookupUserById = this.cloudService.getRequest(HttpRequestType.GET, "Admin/GetUser");
            lookupUserById.query.userId = selectedUser?.id;
            lookupUserById.onSuccess.on(r => {
                const user = r.getReponseObj<User>();
                this.selectedUsersDetails.push(user);

                this.refreshIsAdmin(user);

                this.getSelectedUserSubscriptionsRequest.query.userEmail = selectedUser!.email;
                this.getSelectedUserSubscriptionsRequest.send();
            });
            lookupUserById.onComplete.on(() => {
                if (tracking.every(x => x.isComplete)) {
                    this.refreshingSelectedUsers = false;
                }
            });

            tracking.push(lookupUserById);
        }

        if (tracking.length > 0) {
            this.refreshingSelectedUsers = true;
            tracking.forEach(request => request.send());
        }
    }

    private downloadContentAsFile(filename: string, type: string, content: string) {
        let file = new Blob([content], { type: type });
        let fileURL = URL.createObjectURL(file);
        let a = document.createElement("a");
        a.setAttribute("href", fileURL);
        a.setAttribute("target", "_blank");
        a.setAttribute("download", "AnsysCloudUsers.csv");
        document.body.appendChild(a);
        a.click();
        a.remove();
    }

    public exportProgression: number | undefined = undefined;
    public exportingUsers: boolean = false;
    export() {
        this.exportingUsers = true;
        this.exportProgression = 0;
        this.updateExport();
    }

    private updateExport() {
        let getExportCsvRequest = this.cloudService.getRequest(HttpRequestType.GET, "admin/exportUsersCsv");

        getExportCsvRequest.onSuccess.on(h => {
            var json = h.responseObj;
            this.exportProgression = json.progression;
            if (json.finish) {
                let csv = "\ufeff" + json.csv;
                this.downloadContentAsFile("users.csv", "text/csv;charset=UTF-8", csv);
                this.exportingUsers = false;
            } else {
                // need update on the progression
                setTimeout(() => this.updateExport(), 2000);
            }
        });

        getExportCsvRequest.onError.on(h => {
            console.error("export fail");
            console.error(h.errorMessage);
            this.exportingUsers = false;
        });

        getExportCsvRequest.send();
    }

    setAdmin(selectedUser: User) {
        selectedUser!.isAdmin = null;
        let id = selectedUser!.id;
        let roleId = environment.adminGroupId;

        let addGroupRequest = this.cloudService.getRequest(HttpRequestType.POST, "Admin/UserAddGroup");

        addGroupRequest.onSuccess.on(_ => {
            console.log("success to upgrade");
            this.refreshIsAdmin(selectedUser);
        });
        addGroupRequest.onError.on(h => {
            console.error("fail to upgrade");
        });

        addGroupRequest.query.userId = id;
        addGroupRequest.query.groupId = roleId;
        addGroupRequest.send();
    }

    removeAdmin(selectedUser: User) {
        selectedUser!.isAdmin = null;
        let id = selectedUser!.id;
        let roleId = environment.adminGroupId;

        let removeAdminRequest = this.cloudService.getRequest(HttpRequestType.POST, "Admin/UserRemoveGroup");
        removeAdminRequest.query.userId = id;
        removeAdminRequest.query.groupId = roleId;

        removeAdminRequest.onSuccess.on(h => {
            console.log("success to downgrade");
            this.refreshIsAdmin(selectedUser);
        });
        removeAdminRequest.onError.on(h => {
            console.error("fail to downgrade");
        });

        removeAdminRequest.send();
    }

    refreshIsAdmin(selectedUser: User) {
        selectedUser!.isAdmin = null;
        if (!selectedUser) {
            return;
        }
        let id = selectedUser!.id;

        let getGroupsRequest = this.cloudService.getRequest(HttpRequestType.GET, "Admin/UserGetGroups");

        getGroupsRequest.onSuccess.on((h: HttpRequest) => {
            let validGroups = h.getReponseObj<string[]>().find(str => str == environment.adminGroupId);
            selectedUser!.isAdmin = validGroups != null;
        });

        getGroupsRequest.query.userId = id;
        getGroupsRequest.send();
    }

    public delete(selectedUser: User) {
        if (!selectedUser) {
            return;
        }

        this.userDeletedStatus = "The user deletion is in progress.";

        let deleteUserRequest = this.cloudService.getRequest(HttpRequestType.DELETE, "Admin/UserDelete");
        deleteUserRequest.onSuccess.on(h => {
            var resp = h.responseObj;
            if(resp.userDeletionResult != 0 || resp.dataDeletionResult != 0){
                console.error("User deletion failed.");
                this.userDeletedStatus = resp.errorInfo.concat("Please try again later or contact support.");
                (<HTMLInputElement>document.getElementById("confirmationModalOK")).disabled = false;
                this.refresh();
            } else {
                console.log(selectedUser!.id + " delete");
                this.userDeletedStatus = "The deletion of the user has succeeded.";
                (<HTMLInputElement>document.getElementById("confirmationModalOK")).disabled = false;
                let index: number = this.selectedUsers.findIndex(u => u!.id == selectedUser.id);
                this.selectedUsers.splice(index, 1);
                this.refresh();
            }
        });
        deleteUserRequest.onError.on(h => {
            console.error("User deletion failed.");
            this.userDeletedStatus = "The deletion of the user has failed. Please try again later or contact support.";
            (<HTMLInputElement>document.getElementById("confirmationModalOK")).disabled = false;
            this.refresh();
        });
        this.isConfirmationModalOpen = true;
        console.log("delete the user");
        deleteUserRequest.query.userId = selectedUser!.id;
        deleteUserRequest.send();
    }

    public disableUserDeletedConfirmationModalOKButton() {
        (<HTMLInputElement>document.getElementById("confirmationModalOK")).disabled = true;
        this.userDeletedStatus = "User deletion not started";
        this.isConfirmationModalOpen = false;
    }

    public addManualCheckModel = { "status": "No Match", "justification": "" };
    public addManualCheck(selectedUser: (UserIndex | undefined)) {
        let addManualCheckRequest = this.cloudService.getRequest(HttpRequestType.GET, "Admin/AddManualCheckAndEmail");
        addManualCheckRequest.onSuccess.on(h => {
            console.log("Group added");
            this.refresh();
        });
        addManualCheckRequest.onError.on(h => {
            console.error("Error while manual export");
            console.error(h.errorMessage);
        });
        console.log("add checks");
        addManualCheckRequest.query.user = selectedUser!.id;
        addManualCheckRequest.query.status = this.addManualCheckModel.status;
        addManualCheckRequest.query.justification = this.addManualCheckModel.justification;
        addManualCheckRequest.send();

        this.addManualCheckModel = { "status": "No Match", "justification": "" };
    }

    public tryMailModel = { "mail": "", "name": "" };
    public sendTryAgainMail() {
        let tryAgainrequest = this.cloudService.getRequest(HttpRequestType.GET, "Notification/TryAgain");
        tryAgainrequest.query.userMail = this.tryMailModel.mail;
        tryAgainrequest.query.userName = this.tryMailModel.name;
        return tryAgainrequest.send();
    }

    public azureUser: any | null = null;
    public azureUserLoading: boolean = false;
    public azureUserError: string | null = null;
    public azureSearchId: string | null = null;
    public azureSearchMail: string | null = null;

    public AzureSearchChanged(field: string, value: string) {
        if (!value || value.length == 0)
            return;

        this.azureUserLoading = true;
        this.azureUser = null;
        this.azureUserError = null;

        let request: HttpRequest | undefined;
        if (field == "mail") {
            request = this.cloudService.getRequest(HttpRequestType.GET, "Admin/GetADB2CUserWithMail");
            request.query.mail = value;
        } else if (field == "id") {
            request = this.cloudService.getRequest(HttpRequestType.GET, "Admin/GetADB2CUserWithId");
            request.query.user = value;
        }

        if (request === undefined) {
            console.error("Not implemented field");
            return;
        }

        request.onSuccess.on(h => {
            this.azureUserLoading = false;

            let response = h.responseObj;
            if (response.error) {
                this.azureUserError = "Not found";
            } else {
                const responseEmail = response.email;
                this.azureUser = response;
                this.tryMailModel.mail = responseEmail;
                this.tryMailModel.name = response.displayName;

                this.getUserRequest.query.userEmail = responseEmail;
                this.getUserRequest.send();

                this.getSearchedUserSubscriptionsRequest.query.userEmail = responseEmail;
                this.getSearchedUserSubscriptionsRequest.send();
            }
        });

        request.onError.on(h => {
            this.azureUserLoading = false;
            this.azureUserError = "Server error";
        });

        request.send();
    }

    public searchUser() {
        // first check if any of the fields has been filled
        if (!this.azureSearchId && !this.azureSearchMail) {
            console.log("Fields are all empty. Can't search.");
            this.azureUserError = "All fields all empty. Please enter at least one of the fields.";
            this.azureUser = null;
            return;
        }
        // check if User Id has been filled in
        if (this.azureSearchId) {
            this.AzureSearchChanged("id", this.azureSearchId);
        } else if (this.azureSearchMail) {
            this.AzureSearchChanged('mail', this.azureSearchMail);
        }
    }

    public reset() {
        this.azureUser = null;
        this.azureUserLoading = false;
        this.azureUserError = null;
        this.azureSearchId = null;
        this.azureSearchMail = null
    }
}
