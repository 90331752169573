<style>
    input {
        width: 250px
    }

    input[type="date"] {
        width: 200px;
    }
</style>

<common-subscription-wizard #wizard [open]="open" [loading]="postSubscriptionsRequest.isLoading || postCloudSubscriptionsRequest.isLoading" [mode]="mode"
    [products]="products" [allSubscriptionsValid]="areAllSubscriptionsValidForRenewal()"
    (close)="openChange.emit(false)" (dispose)="reset()" (process)="submit($event)">
    <subscriptions>
        <form clrForm *ngIf="containCloudSubscriptions">
            <clr-radio-container clrInline>
                <label>Region</label>
                <clr-radio-wrapper>
                    <input type="radio" clrRadio [name]="regionTypes.Americas" required [value]="regionTypes.Americas"
                        [(ngModel)]="region" />
                    <label>{{regionTypes.Americas}}</label>
                </clr-radio-wrapper>
                <clr-radio-wrapper>
                    <input type="radio" clrRadio [name]="regionTypes.EMEA" required [value]="regionTypes.EMEA"
                        [(ngModel)]="region" />
                    <label>{{regionTypes.EMEA}}</label>
                </clr-radio-wrapper>
                <clr-radio-wrapper>
                    <input type="radio" clrRadio [name]="regionTypes.APAC" required [value]="regionTypes.APAC"
                        [(ngModel)]="region" />
                    <label>{{regionTypes.APAC}}</label>
                </clr-radio-wrapper>
            </clr-radio-container>
            <clr-radio-container clrInline>
                <label>Type of Customer</label>
                <clr-radio-wrapper>
                    <input type="radio" clrRadio [name]="customerTypes.Customer" required
                        [value]="customerTypes.Customer" [(ngModel)]="customerType" />
                    <label>{{customerTypes.Customer}}</label>
                </clr-radio-wrapper>
                <clr-radio-wrapper>
                    <input type="radio" clrRadio [name]="customerTypes.Ansys" required [value]="customerTypes.Ansys"
                        [(ngModel)]="customerType" />
                    <label>{{customerTypes.Ansys}}</label>
                </clr-radio-wrapper>
                <clr-radio-wrapper>
                    <input type="radio" clrRadio [name]="customerTypes.ChannelPartner" required
                        [value]="customerTypes.ChannelPartner" [(ngModel)]="customerType" />
                    <label>{{customerTypes.ChannelPartner}}</label>
                </clr-radio-wrapper>
            </clr-radio-container>
        </form>
        <ng-container *ngFor="let subscription of subscriptionManager.subscriptions; let idx = index;">
            <div style="margin-bottom: 5px;" [class.mainBorder]="subscription.isValid"
                [class.errorBorder]="!subscription.isValid">
                <div style="margin: 10px 20px 10px 20px;">
                    <form clrForm>
                        <clr-select-container>
                            <label [for]="'productInput-' + idx">Product</label>
                            <select clrSelect *ngIf="products?.length > 0" [id]="'productInput-' + idx"
                                [disabled]="true" required [(ngModel)]="subscription.product"
                                [name]="'productInput-' + idx">
                                <option *ngFor="let p of products" [value]="p.partNumber">{{p.name}}</option>
                            </select>
                        </clr-select-container>
                        <clr-input-container>
                            <label [for]="'quantityInput-' + idx">Quantity</label>
                            <input clrInput type="text" [id]="'quantityInput-' + idx" required
                                [(ngModel)]="subscription.quantity" [disabled]="true" [name]="'quantityInput-' + idx"
                                style="width:50px">
                        </clr-input-container>
                        <clr-date-container>
                            <label [for]="'startDate-' + idx">Start Date</label>
                            <input clrDate type="date" [id]="'startDate-' + idx" required
                                [(ngModel)]="subscription.startDate" [disabled]="true" [name]="'startDate-' + idx">
                        </clr-date-container>
                        <clr-checkbox-container
                            *ngIf="subscriptionManager.isLicenseExtensionCheckboxVisible(mode, products, subscription)">
                            <clr-checkbox-wrapper>
                                <label [for]="'checkbox-' + idx">License Extension</label>
                                <input clrCheckbox type="checkbox" [id]="'checkbox-' + idx" [name]="'checkbox-' + idx"
                                    [(ngModel)]="subscription.isLicenseExtensionChecked">
                            </clr-checkbox-wrapper>
                        </clr-checkbox-container>
                        <clr-date-container>
                            <label [for]="'endDate-' + idx">{{subscription.endDateType}} Date</label>
                            <input clrDate type="date" [id]="'endDate-' + idx" required
                                [(ngModel)]="subscription.endDate" [min]="subscription.startDate | minEndDate"
                                [name]="'endDate-' + idx">
                        </clr-date-container>
                    </form>
                </div>
            </div>
        </ng-container>
    </subscriptions>
    <summary>
        <ul *ngIf="containCloudSubscriptions" class="list">
            <li>Region : {{region}}</li>
            <li>Type of Customer : {{customerType}}</li>
        </ul>
        <table class="table">
            <thead>
                <tr>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Start Date</th>
                    <th>TECS Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let subscription of subscriptionManager.subscriptions">
                    <td>{{subscriptionManager.getProductNameFromPartNumber(products, subscription.product)}}</td>
                    <td>{{subscription.quantity}}</td>
                    <td>{{subscription.startDate}}</td>
                    <td>{{subscription.endDate}}</td>
                </tr>
            </tbody>
        </table>
    </summary>
</common-subscription-wizard>