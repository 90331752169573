//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { DatagridController } from "./datagridController";
import { HttpRequest } from "./httpRequest";

export abstract class ServerDatagridController<ElementType = any> extends DatagridController<ElementType> {
    protected _request:HttpRequest;

    public constructor(request:HttpRequest, page: number = 1, pageSize: number = 10) {
        super(page, pageSize);

        this._request = request;
        this._request.onStart.on(_ => this.onRequestStart());
        this._request.onSuccess.on(_ => this.onRequestSuccess());

        this.totalCount = null;
    }

    protected onRequestStart():void {
        // Cancel pending refresh if any.
        this.cancelFetchDataTimer();

        // sort
        if (this.sort) {
            this.request.query.sortBy = this.sort.sortBy;
            this.request.query.sortReverse = this.sort.isReversed;
        } else {
            delete this.request.query.sortBy;
            delete this.request.query.sortReverse;
        }

        // filters
        var queryFilterKeys = Object.keys(this.request.query).filter(k => k.startsWith("filter_"));
        for (let queryFilterKey of queryFilterKeys) {
            delete this.request.query[queryFilterKey];
        }

        let filters: any[] = this.filters || new Array<any>();
        for (let propertyName in filters) {
            let filter: any = filters[propertyName];
            this.request.query["filter_" + filter.property] = filter.value;
        }
    }

    protected onRequestSuccess():void  {
        this.fetchDataTimer = null;
    }

    public get request(): HttpRequest {
        return this._request;
    }

    public refresh(): void {
    }

    public isLoading():boolean {
        return this._request.isLoading;
    }
}
