//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { HttpRequest } from "@Shared/utils/httpRequest";
import { OrderBase, SubscriptionBase } from "./fulfillment-orders";

export class DisplayData<T> {
    name: string;
    value: (item: T) => string | null;

    constructor(name: string, value: (item: T) => string | null) {
        this.name = name;
        this.value = value;
    }
}

export class PoisonedOrder<TOrder extends OrderBase, TSubscription extends SubscriptionBase> {
    order: TOrder;
    subscriptions: TSubscription[];
}

export class FulfillmentFailure<TOrder extends OrderBase, TSubscription extends SubscriptionBase> {
    title: string;
    orderDisplay: DisplayData<TOrder>[] = [];
    subscriptionDisplay: DisplayData<TSubscription>[] = [];
    failures: PoisonedOrder<TOrder, TSubscription>[] = [];
    selected: PoisonedOrder<TOrder, TSubscription>[] = [];
    showDelete: boolean = false;
    error: string | null = null;

    private refreshRequest: HttpRequest;
    private updateRequest: HttpRequest;

    get loading(): boolean {
        return this.refreshRequest.isLoading || this.updateRequest.isLoading;
    }

    constructor(title: string, refreshRequest: HttpRequest, updateRequest: HttpRequest, orderDisplay: DisplayData<TOrder>[], subscriptionDisplay: DisplayData<TSubscription>[]) {
        this.title = title;

        this.refreshRequest = refreshRequest;
        this.refreshRequest.onError.on(request => this.error = request.errorMessage);
        this.refreshRequest.onSuccess.on(request => this.failures = request.responseObj as PoisonedOrder<TOrder, TSubscription>[]);
        this.refreshRequest.send();

        this.updateRequest = updateRequest;
        this.updateRequest.onError.on(request => this.error = request.errorMessage);
        this.updateRequest.onComplete.on(() => this.refreshRequest.send());

        this.orderDisplay = orderDisplay;
        this.subscriptionDisplay = subscriptionDisplay;
    }

    handleRefresh(): void {
        this.refreshRequest.send();
    }

    handleResubmit(): void {
        this.updateRequest.body = { messageIdsToResubmit: this.selected.map(x => x.order.messageId) };
        this.updateRequest.send();
    }

    handleDelete(): void {
        this.updateRequest.body = { messageIdsToDelete: this.selected.map(x => x.order.messageId) };
        this.updateRequest.send();
    }
}
