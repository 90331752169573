//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { HttpRequest, HttpRequestType } from '@Shared/utils/httpRequest';
import { HttpRequestFragmenter } from '@Shared/utils/httpRequestFragmenter';
import { MsalHttpRequestService } from "@Msal/services/msalHttpRequest.service";
import { UserFormComponent } from '@Shared/components/userForm.component';

interface Company {
    id: string | null;
    displayName: string | null;
    streetAddress: string | null;
    zipcode: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
}

@Component({
    selector: 'company',
    templateUrl: 'company.component.html',
})
export class CompanyComponent implements AfterViewInit {
    public getCompanyRequest: HttpRequest;
    public getAdminsRequest: HttpRequest;
    public getUserRequest: HttpRequest;
    public addAdminRequest: HttpRequest;
    public resendAdminWelcomeEmailRequest: HttpRequest;

    public removeAdminRequest: HttpRequest;
    public removeAdminRequestFragmenter: HttpRequestFragmenter;

    public currentCompany: Company | null;
    public admins: any[] | null;

    public isAddAdminModalOpen: boolean = false;
    @ViewChild("newAdminForm", { static: false }) public newAdminForm: UserFormComponent;

    public isRemoveAdminModalOpen: boolean = false;
    public selectedAdmins: any[] = [];

    public constructor(private httpService: MsalHttpRequestService) {
        this.getCompanyRequest = this.httpService.getRequest(HttpRequestType.GET, "Admin/GetCompanyById");
        this.getCompanyRequest.onSuccess.on(_ => {
            if (this.getCompanyRequest.responseObj.error) {
                throw Error(`Company ${this.getCompanyRequest.query.id} not found.`);
            }

            this.currentCompany = this.getCompanyRequest.responseObj;        
        });


        this.getAdminsRequest = this.httpService.getRequest(HttpRequestType.GET, "Admin/GetCompanyAdminById");
        this.getAdminsRequest.onStart.on(_ => {
            this.admins = null;
            this.selectedAdmins = [];
        });
        this.getAdminsRequest.onSuccess.on(_ => {
            this.admins = this.getAdminsRequest.responseObj;
        });

        this.getUserRequest = this.httpService.getRequest(HttpRequestType.GET, "getuser/GetUserWithEmail");

        this.addAdminRequest = this.httpService.getRequest(HttpRequestType.PUT, "Admin/CompanyAdmin");
        this.addAdminRequest.onSuccess.on(_ => {
            this.newAdminForm.reset();
            this.isAddAdminModalOpen = false;
            this.getAdminsRequest.send();
        });

        // Init remove admin request fragmenter:
        this.removeAdminRequest = this.httpService.getRequest(HttpRequestType.DELETE, "Admin/CompanyAdmin");

        this.removeAdminRequestFragmenter = new HttpRequestFragmenter(this.removeAdminRequest);

        this.removeAdminRequestFragmenter.onStart.on(_ => {
            this.removeAdminRequestFragmenter.maxIndex = this.selectedAdmins.length;
        });

        this.removeAdminRequestFragmenter.onStartFragment.on(_ => {
            let nextAdmin = this.selectedAdmins[this.removeAdminRequestFragmenter.currentIndex];
            this.removeAdminRequest.query.userEmail = nextAdmin.email
        });

        this.removeAdminRequestFragmenter.onStop.on(_ => {
            this.isRemoveAdminModalOpen = false;
            this.selectedAdmins = [];
            this.getAdminsRequest.send();
        });
    }

    public ngAfterViewInit(): void {
        this.newAdminForm.onUserNotFound.on(model => {
            // prefill user info from company info
            model.city = this.currentCompany!.city;
            model.country = this.currentCompany!.country;
            model.state = this.currentCompany!.state;
            model.postalCode = this.currentCompany!.zipcode;
            model.streetAddress = this.currentCompany!.streetAddress;
        });
    }

    public searchCurrentCompany(companyId: string) {
        this.getCompanyRequest.query = {
            id: companyId
        };
        this.getCompanyRequest.send();

        this.getAdminsRequest.query = {
            id: companyId
        };
        this.getAdminsRequest.send();
    }

    public openAddAdminModal() {
        this.addAdminRequest.reset();
        this.isAddAdminModalOpen = true;
    }

    public addAdmin() {
        this.addAdminRequest.query = {
            fnoCompanyId: this.currentCompany!.id
        }
        this.addAdminRequest.body = this.newAdminForm.model;

        this.addAdminRequest.send();
    }

    public openRemoveAdminModal(user: any) {
        this.removeAdminRequestFragmenter.reset();
        this.isRemoveAdminModalOpen = true;
    }

    public removeAdmin() {
        this.removeAdminRequestFragmenter.reset();
        this.removeAdminRequest.query = {
            fnoCompanyId: this.currentCompany!.id,
        }
        this.removeAdminRequestFragmenter.start();
    }

    public tryMailModel = { "mail": "", "name": "" };
    public resendAdminWelcomeEmail() {
        let resendAdminWelcomeEmailRequest = this.httpService.getRequest(HttpRequestType.GET, "Admin/ResendAdminUserWelcomeEmail");
        for (let admin of this.selectedAdmins) {
            resendAdminWelcomeEmailRequest.query.userMail = admin.email;
            resendAdminWelcomeEmailRequest.query.userName = admin.displayName;
            resendAdminWelcomeEmailRequest.send();
        }
    }
}
