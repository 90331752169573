//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class FilterService {
    getPropertyForFilter(property: string): string {
        const segments = property.split('.');

        return segments
            .filter(x => x.length > 0)
            .map(x => `${x[0].toUpperCase()}${x.substring(1)}`)
            .reduce((a, b) => `${a}.${b}`);
    }
}
