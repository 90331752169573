<style>
    input {
        width: 250px
    }

    input[type="date"] {
        width: 200px;
    }
</style>

<common-subscription-wizard [open]="open" [loading]="postSubscriptionsRequest.isLoading" [mode]="mode"
    [products]="products" [allSubscriptionsValid]="subscriptionManager.areAllSubscriptionsValid()"
    (close)="openChange.emit(false)" (dispose)="reset()" (process)="submit($event)">
    <subscriptions>
        <ng-container *ngFor="let subscription of subscriptionManager.subscriptions; let idx = index;">
            <div style="margin-bottom: 5px;" [class.mainBorder]="subscription.isValid"
                [class.errorBorder]="!subscription.isValid">
                <div style="margin: 10px 20px 10px 20px;">
                    <form clrForm>
                        <clr-select-container>
                            <label [for]="'productInput-' + idx">Product</label>
                            <select clrSelect *ngIf="products?.length > 0" [id]="'productInput-' + idx"
                                [disabled]="isInRenewalMode" required [(ngModel)]="subscription.product"
                                [name]="'productInput-' + idx">
                                <option *ngFor="let p of products" [value]="p.partNumber">{{p.name}}</option>
                            </select>
                        </clr-select-container>
                        <clr-input-container>
                            <label [for]="'quantityInput-' + idx">Quantity</label>
                            <input clrInput type="text" [id]="'quantityInput-' + idx" required
                                [(ngModel)]="subscription.quantity" [disabled]="isInRenewalMode"
                                [name]="'quantityInput-' + idx" style="width:50px">
                        </clr-input-container>
                        <clr-date-container>
                            <label [for]="'startDate-' + idx">Start Date</label>
                            <input clrDate type="date" [id]="'startDate-' + idx" required
                                [(ngModel)]="subscription.startDate" [disabled]="isInRenewalMode"
                                [name]="'startDate-' + idx">
                        </clr-date-container>
                        <clr-checkbox-container
                            *ngIf="subscriptionManager.isLicenseExtensionCheckboxVisible(mode, products, subscription)">
                            <clr-checkbox-wrapper>
                                <label [for]="'checkbox-' + idx">License Extension</label>
                                <input clrCheckbox type="checkbox" [id]="'checkbox-' + idx" [name]="'checkbox-' + idx"
                                    [(ngModel)]="subscription.isLicenseExtensionChecked">
                            </clr-checkbox-wrapper>
                        </clr-checkbox-container>
                        <clr-date-container>
                            <label [for]="'endDate-' + idx">{{subscription.endDateType}} Date</label>
                            <input clrDate type="date" [id]="'endDate-' + idx" required
                                [(ngModel)]="subscription.endDate" [min]="subscription.startDate | minEndDate"
                                [name]="'endDate-' + idx">
                        </clr-date-container>
                    </form>
                    <button class="btn btn-outline btn-sm" [disabled]="!subscriptionManager.canRemoveSubscription()"
                        (click)="subscriptionManager.removeSubscription(subscription)">Delete</button>
                </div>
            </div>
        </ng-container>
        <button class="btn btn-outline btn-sm" (click)="subscriptionManager.addSubscription()">Add
            Subscription</button>
    </subscriptions>
    <summary>
        <table class="table">
            <thead>
                <tr>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>License Extension</th>
                    <th>Start Date</th>
                    <th>TECS Date/End Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let subscription of subscriptionManager.subscriptions">
                    <td>{{subscriptionManager.getProductNameFromPartNumber(products, subscription.product)}}</td>
                    <td>{{subscription.quantity}}</td>
                    <td>{{subscription.isLicenseExtensionChecked ? "True" : "False"}}</td>
                    <td>{{subscription.startDate}}</td>
                    <td>{{subscription.endDate}}</td>
                </tr>
            </tbody>
        </table>
    </summary>
</common-subscription-wizard>