<common-subscription-wizard
    [open]='open' (close)='openChange.emit(false)'
    [loading]='postSubscriptionsRequest.isLoading'
    [mode]='mode' [products]='products'
    [allSubscriptionsValid]='subscriptionManager.areAllSubscriptionsValid()'
    (dispose)='reset()' (process)='submit($event)'
>
    <subscriptions>
        <form clrForm>
            <clr-select-container>
                <label [for]='region'>Region</label>
                <select
                    clrSelect
                    [id]='region' [name]='region'
                    [(ngModel)]='region'
                    required
                >
                    <option *ngFor='let region of regions' [value]='region.name'>
                        {{region.caption}}
                    </option>
                </select>
            </clr-select-container>

            <clr-radio-container clrInline>
                <label>Type of Customer</label>

                <clr-radio-wrapper>
                    <input
                        clrRadio type='radio'
                        [name]='customerTypes.Customer'
                        [(ngModel)]='customerType' [value]='customerTypes.Customer'
                        required
                    />
                    <label>{{customerTypes.Customer}}</label>
                </clr-radio-wrapper>

                <clr-radio-wrapper>
                    <input
                        clrRadio type='radio'
                        [name]='customerTypes.Ansys'
                        [(ngModel)]='customerType' [value]='customerTypes.Ansys'
                        required
                    />
                    <label>{{customerTypes.Ansys}}</label>
                </clr-radio-wrapper>

                <clr-radio-wrapper>
                    <input
                        clrRadio type='radio'
                        [name]='customerTypes.ChannelPartner'
                        [(ngModel)]='customerType' [value]='customerTypes.ChannelPartner'
                        required
                    />
                    <label>{{customerTypes.ChannelPartner}}</label>
                </clr-radio-wrapper>
            </clr-radio-container>
        </form>

        <ng-container *ngFor='let subscription of subscriptionManager.subscriptions; let idx = index;'>
            <div class='subscription' [class.mainBorder]='subscription.isValid' [class.errorBorder]='!subscription.isValid'>
                <div class='subscription-content'>
                    <form clrForm>
                        <clr-select-container>
                            <label [for]='"productInput-" + idx'>Product</label>
                            <select
                                *ngIf='products?.length > 0'
                                clrSelect
                                [id]='"productInput-" + idx' [name]='"productInput-" + idx'
                                [(ngModel)]='subscription.product'
                                required
                            >
                                <option *ngFor='let product of products' [value]='product.partNumber'>
                                    {{product.name}}
                                </option>
                            </select>
                        </clr-select-container>

                        <clr-input-container>
                            <label [for]='"quantityInput-" + idx'>Quantity</label>
                            <input
                                class='quantity'
                                clrInput type='text'
                                [id]='"quantityInput-" + idx' [name]='"quantityInput-" + idx'
                                [(ngModel)]='subscription.quantity'
                                required
                            />
                        </clr-input-container>

                        <clr-date-container>
                            <label [for]='"startDate-" + idx'>Start Date</label>
                            <input
                                clrDate type='date'
                                [id]='"startDate-" + idx' [name]='"startDate-" + idx'
                                [(ngModel)]='subscription.startDate'
                                required
                            />
                        </clr-date-container>

                        <clr-checkbox-container
                            *ngIf='subscriptionManager.isLicenseExtensionCheckboxVisible(mode, products, subscription)'
                        >
                            <clr-checkbox-wrapper>
                                <label [for]='"checkbox-" + idx'>License Extension</label>
                                <input
                                    clrCheckbox type='checkbox'
                                    [id]='"checkbox-" + idx' [name]='"checkbox-" + idx'
                                    [(ngModel)]='subscription.isLicenseExtensionChecked'
                                />
                            </clr-checkbox-wrapper>
                        </clr-checkbox-container>

                        <clr-date-container>
                            <label [for]='"endDate-" + idx'>{{subscription.endDateType}} Date</label>
                            <input
                                clrDate type='date'
                                [id]='"endDate-" + idx' [name]='"endDate-" + idx'
                                [(ngModel)]='subscription.endDate'
                                required [min]='subscription.startDate | minEndDate'
                            />
                        </clr-date-container>
                    </form>

                    <button
                        class='btn btn-outline btn-sm'
                        [disabled]='!subscriptionManager.canRemoveSubscription()'
                        (click)='subscriptionManager.removeSubscription(subscription)'
                    >
                        Delete
                    </button>
                </div>
            </div>
        </ng-container>

        <button class='btn btn-outline btn-sm' (click)='subscriptionManager.addSubscription()'>
            Add Subscription
        </button>
    </subscriptions>



    <summary>
        <ul class='list'>
            <li>Region: {{region}}</li>
            <li>Type of Customer: {{customerType}}</li>
        </ul>

        <table class='table'>
            <thead>
                <tr>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>License Extension</th>
                    <th>Start Date</th>
                    <th>TECS Date/End Date</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor='let subscription of subscriptionManager.subscriptions'>
                    <td>{{subscriptionManager.getProductNameFromPartNumber(products, subscription.product)}}</td>
                    <td>{{subscription.quantity}}</td>
                    <td>{{subscription.isLicenseExtensionChecked ? 'True' : 'False'}}</td>
                    <td>{{subscription.startDate}}</td>
                    <td>{{subscription.endDate}}</td>
                </tr>
            </tbody>
        </table>
    </summary>
</common-subscription-wizard>
