//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from '../dashboard/dashboard.component';
import { UsersComponent } from '../users/users.component';
import { WhitelistComponent } from '../userregistration/whitelist.component';
import { BlacklistComponent } from '../userregistration/blacklist.component';
import { RecordsComponent } from '../records/records.component';
import { BusinessComponent } from '../business/business.component';
import { CompanyComponent } from 'company/company.component';
import { FulfillmentComponent } from './fulfillment/fulfillment.component';
import { ExportScreeningComponent } from './exportScreening/exportScreening.component';
import { AdminGuard } from 'guards/admin.guard';
import { ScreeningGuard } from 'guards/screening.guard';

const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent, canActivate: [AdminGuard] },
  { path: 'users', component: UsersComponent, canActivate: [AdminGuard] },
  { path: 'subscriptions', component: BusinessComponent, canActivate: [AdminGuard] },
  { path: 'company', component: CompanyComponent, canActivate: [AdminGuard] },
  { path: 'whitelist', component: WhitelistComponent, canActivate: [AdminGuard] },
  { path: 'blacklist', component: BlacklistComponent, canActivate: [AdminGuard] },
  { path: 'records', component: RecordsComponent, canActivate: [AdminGuard] },
  { path: 'fulfillment', component: FulfillmentComponent, canActivate: [AdminGuard] },
  { path: 'screening', component: ExportScreeningComponent, canActivate: [ScreeningGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
