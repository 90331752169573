//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpRequest, HttpRequestType } from '@Shared/utils/httpRequest';
import { MsalHttpRequestService } from "@Msal/services/msalHttpRequest.service";
import { Globals } from "services/globals.service";
import { AddressComponent } from '@Shared/components/address/address.component';
import { ExportCheck } from './exportCheck';

class EditUserProfile {
  private readonly blank = "%%__BLANK__%%";

  public givenName: string;
  public surname: string;
  public phone: string;
  public jobTitle: string;
  public company: string;
  public street: string;
  public city: string;
  public postal: string;
  public state: string;
  public displayName: string;
  public country: string;
  public marketingOption: boolean;

  constructor(userProfile: UserProfile) {
    this.givenName = this.formatString(userProfile.givenName);
    this.surname = this.formatString(userProfile.surname);
    this.phone = this.formatString(userProfile.phoneNumber);
    this.jobTitle = this.formatString(userProfile.jobTitle);
    this.company = this.formatString(userProfile.companyName);
    this.street = this.formatString(userProfile.streetAddress);
    this.city = this.formatString(userProfile.city);
    this.postal = this.formatString(userProfile.postalCode);
    this.state = this.formatString(userProfile.state);
    this.displayName = this.formatString(userProfile.displayName);
    this.country = this.formatString(userProfile.country.firstName);
    this.marketingOption = userProfile.optsInForMarketingCommunication;
  }

  public isMatch(profile: EditUserProfile): boolean {
    return this.matchString(this.givenName, profile.givenName)
      && this.matchString(this.surname, profile.surname)
      && this.matchString(this.phone, profile.phone)
      && this.matchString(this.jobTitle, profile.jobTitle)
      && this.matchString(this.company, profile.company)
      && this.matchString(this.street, profile.street)
      && this.matchString(this.city, profile.city)
      && this.matchString(this.postal, profile.postal)
      && this.matchString(this.state, profile.state)
      && this.matchString(this.displayName, profile.displayName)
      && this.matchString(this.country, profile.country)
      && this.marketingOption == profile.marketingOption;
  }

  public isDataValid(): boolean {
    const invalid = this.isNullOrWhiteSpace(this.givenName)
      || this.isNullOrWhiteSpace(this.surname)
      || this.isNullOrWhiteSpace(this.company)
      || this.isNullOrWhiteSpace(this.street)
      || this.isNullOrWhiteSpace(this.city)
      || this.isNullOrWhiteSpace(this.displayName)
      || this.isNullOrWhiteSpace(this.country);

    return !invalid;
  }

  private isNullOrWhiteSpace(value: string): boolean {
    return value == null || value.trim() == '';
  }

  private formatString(value: string): string {
    return value == this.blank
      ? ''
      : value;
  }

  private matchString(a: string, b: string): boolean {
    const emptyMatch = this.isNullOrWhiteSpace(a) && this.isNullOrWhiteSpace(b);
    const valueMatch = a == b;
    return emptyMatch || valueMatch;
  }
}

class UserProfile {
  public id: string;
  public givenName: string;
  public surname: string;
  public displayName: string;
  public companyName: string;
  public email: string;
  public city: string;
  public country: Country;
  public streetAddress: string;
  public state: string;
  public postalCode: string;
  public phoneNumber: string;
  public jobTitle: string;
  public optsInForMarketingCommunication: boolean;
}

class Country {
  public code: string;
  public names: string[];
  public firstName: string;
  public isInvalid: boolean;
}

class User {
  public id: string;
  public welcomeDiscoveryLiveEmail: boolean;
  public thanksDiscoveryLiveEmail: boolean;
  public firstAccessSource: string;
  public profile: UserProfile;
  public exportChecks: ExportCheck[];
  public agreements: Agreement[];
  public lastProductDownload: any;
  public lastLocalization: any;
  public creationDate: Date;
  public showDeleteModal: boolean = false;
  public showSetARModal: boolean = false;
}

class UserInfo extends User {
  public uiProfileModel: EditUserProfile | null;
  public exportScreeningStatus: ExportScreeningStatus | null;
  public exportChecks: ExportCheck[];
  public isAdmin: boolean;
  public isExportScreeningUser: Boolean;

  constructor(user: User) {
    super();
    this.id = user.id;
    this.welcomeDiscoveryLiveEmail = user.welcomeDiscoveryLiveEmail;
    this.thanksDiscoveryLiveEmail = user.thanksDiscoveryLiveEmail;
    this.firstAccessSource = user.firstAccessSource;
    this.profile = user.profile;
    this.agreements = user.agreements;
    this.lastProductDownload = user.lastProductDownload;
    this.lastLocalization = user.lastLocalization;
    this.creationDate = user.creationDate;
    this.showDeleteModal = user.showDeleteModal;
    this.showSetARModal = user.showSetARModal;
    this.uiProfileModel = new EditUserProfile(this.profile);
  }

  get isDataValid(): boolean {
    return this.uiProfileModel!.isDataValid();
  }

  get isMatch(): boolean {
    return this.uiProfileModel!.isMatch(new EditUserProfile(this.profile));
  }

  public canSetExportScreeningUser(newValue: boolean): boolean {
    return !this.isAdmin && this.isExportScreeningUser !== newValue;
  }
}

class Agreement {
  public isAccepted: boolean;
  public isSigned: boolean;
  public date: Date;
  public method: AgreementSignOffMethod;
}

class AgreementSignOffMethod {
  public description: string;
  public userEmailAddress: string;
}

class ExportScreeningStatus {
  public isCompliant: boolean;
  public status: string;
  public reason: string | null;
  public canGraylistManualCheck: boolean;
}

class CreateExportCheck {
  public title: string;
  public justification: string | null = null;
  public process: () => void;

  constructor(title: string, process: () => void) {
    this.title = title;
    this.process = process;
  }
}

@Component({
  selector: 'app-export-screening',
  templateUrl: './exportScreening.component.html',
  styleUrls: ['./exportScreening.component.css']
})
export class ExportScreeningComponent implements OnInit {

  @ViewChild('address') address: AddressComponent;

  private exportScreeningEscalationFormLink : string;

  public environment = environment;

  public isAdmin: boolean;
  public isExportScreeningUser: boolean;

  public searchMail: string | null = null;

  public statusLoading: boolean = false;
  public statusSuccess: string | null = null;
  public statusFailure: string | null = null;

  public currentUserInfo: UserInfo | null = null;
  public multipleUsersFromFile: User[] = [];
  public multipleUserInfo: UserInfo[] = [];
  public notFoundUserEmails: string[] = [];
  public selectedUsers: UserInfo[] = [];

  public createExportCheck: CreateExportCheck | null = null;

  public searchUserByEmailRequest: HttpRequest;
  public editUserProfileRequest: HttpRequest;
  public sendScreeningFailedRequest: HttpRequest;
  public rescreenAmberRoadRequest: HttpRequest;
  public addGraylistManualCheckRequest: HttpRequest;
  public setScreeningUserRequest: HttpRequest;

  public typeExportScreeningInput: string = "one";

  constructor(
    private cloudService: MsalHttpRequestService,
    private globals: Globals
  ) { }

  ngOnInit(): void {
    this.isAdmin = this.globals.IsAdmin;
    this.isExportScreeningUser = this.globals.IsExportScreeningUser;
    
    this.globals.GetExportScreeningEscalationFormUrl().then(escalateFormUrl => {
      this.exportScreeningEscalationFormLink = escalateFormUrl;
    })
    .catch(console.error);

    this.searchUserByEmailRequest = this.cloudService.getRequest(HttpRequestType.GET, "ExportScreening/UserByEmail");
    this.searchUserByEmailRequest.onStart.on(x => {
      this.reset();
      this.statusLoading = true;
    });
    this.searchUserByEmailRequest.onComplete.on(x => this.statusLoading = false);
    this.searchUserByEmailRequest.onError.on(x => {
      const error = x.httpError.status == 403 // Forbidden
        ? `Unable to access user with email ${this.searchMail} due to insufficient permissions.`
        : `Unable to find user with email ${this.searchMail}.`;

      this.setStatus(error, false);
    });
    this.searchUserByEmailRequest.onSuccess.on(x => {
      this.currentUserInfo = new UserInfo(<User>x.responseObj.user);
      this.currentUserInfo.exportScreeningStatus = <ExportScreeningStatus>x.responseObj.lastCheck;
      this.currentUserInfo.exportChecks = <ExportCheck[]>x.responseObj.checks;
      this.currentUserInfo.isAdmin = <boolean>x.responseObj.admin;
      this.currentUserInfo.isExportScreeningUser = <boolean>x.responseObj.isExportScreeningUser;
    });

    this.editUserProfileRequest = this.cloudService.getRequest(HttpRequestType.POST, "ExportScreening/UpdateUser");
    this.editUserProfileRequest.onStart.on(x => {
      x.query.userEmail = this.currentUserInfo!.profile.email;
      x.body = this.currentUserInfo!.uiProfileModel;
      this.statusLoading = true;
    });
    this.editUserProfileRequest.onError.on(x => {
      this.searchUserByEmailRequest.send();
      this.setStatus(x.errorMessage, false);
      this.statusLoading = false; // do this in onError instead of onComplete since onSuccess will fire off another request
    });
    this.editUserProfileRequest.onSuccess.on(x => {
      this.setStatus('User profile has been updated.', true);
      this.searchUserByEmailRequest.send();
    });

    this.sendScreeningFailedRequest = this.cloudService.getRequest(HttpRequestType.POST, "ExportScreening/SendScreeningCheckFailed");
    this.sendScreeningFailedRequest.onStart.on(x => this.statusLoading = true);
    this.sendScreeningFailedRequest.onComplete.on(x => this.statusLoading = false);
    this.sendScreeningFailedRequest.onError.on(x => this.setStatus(x.errorMessage, false));
    this.sendScreeningFailedRequest.onSuccess.on(x => this.setStatus('Sent screening check failure to user.', true));

    this.rescreenAmberRoadRequest = this.cloudService.getRequest(HttpRequestType.POST, "ExportScreening/Rescreen");
    this.rescreenAmberRoadRequest.onStart.on(x => this.statusLoading = true);
    this.rescreenAmberRoadRequest.onError.on(x => {
      this.searchUserByEmailRequest.send();
      this.setStatus(x.errorMessage, false);
      this.statusLoading = false; // do this in onError instead of onComplete since onSuccess will fire off another request
    });
    this.rescreenAmberRoadRequest.onSuccess.on(x => {
      this.setStatus('Rescreened user.', true);
      this.searchUserByEmailRequest.send();
    });

    this.addGraylistManualCheckRequest = this.cloudService.getRequest(HttpRequestType.POST, "ExportScreening/AddGraylistManualCheckAndEmail");
    this.addGraylistManualCheckRequest.onStart.on(x => this.statusLoading = true);
    this.addGraylistManualCheckRequest.onError.on(x => {
      this.setStatus(x.errorMessage, false);
      this.statusLoading = false; // do this in onError instead of onComplete since onSuccess will fire off another request
    });
    this.addGraylistManualCheckRequest.onSuccess.on(x => {
      this.setStatus('Added Graylist Manual Check.', true);
      this.searchUserByEmailRequest.send();
    });

    this.setScreeningUserRequest = this.cloudService.getRequest(HttpRequestType.POST, "ExportScreening/SetExportScreeningUser");
    this.setScreeningUserRequest.onStart.on(x => this.statusLoading = true);
    this.setScreeningUserRequest.onError.on(x => {
      this.setStatus(x.errorMessage, false);
      this.statusLoading = false; // do this in onError instead of onComplete since onSuccess will fire off another request
    });
    this.setScreeningUserRequest.onSuccess.on(x => {
      this.setStatus('Updated Screening Privileges.', true);
      this.searchUserByEmailRequest.send();
    });
  }

  searchUser() {
    this.searchUserByEmailRequest.query.userEmail = this.searchMail;
    this.searchUserByEmailRequest.send();
  }

  shouldDisableUpdateUserProfile(): boolean {
    return !this.currentUserInfo!.isDataValid || this.currentUserInfo!.isMatch;
  }

  updateUserProfile() {
    this.editUserProfileRequest.send();
  }

  sendScreeningFailed() {
    this.sendScreeningFailedRequest.query.userId = this.currentUserInfo!.id;
    this.sendScreeningFailedRequest.send();
  }

  shouldDisableRescreen(): boolean {
    return !this.currentUserInfo!.isDataValid || !this.currentUserInfo!.isMatch;
  }

  rescreen() {
    this.rescreenAmberRoadRequest.query.userId = this.currentUserInfo!.id;
    this.rescreenAmberRoadRequest.send();
  }

  createGraylistManualCheck() {
    this.createExportCheck = new CreateExportCheck('Add Graylist Manual Check', () => {
      this.addGraylistManualCheckRequest.query.userId = this.currentUserInfo!.id;
      this.addGraylistManualCheckRequest.query.status = "No Match";
      this.addGraylistManualCheckRequest.query.justification = `${this.createExportCheck!.justification} (Graylist Override)`;
      this.addGraylistManualCheckRequest.send();
    });
  }

  setScreeningUser(isExportScreeningUser: boolean) {
    this.setScreeningUserRequest.query.userId = this.currentUserInfo!.id;
    this.setScreeningUserRequest.query.isExportScreeningUser = isExportScreeningUser;
    this.setScreeningUserRequest.send();
  }

  setStatus(message: string, success: boolean) {
    if (success) {
      this.statusSuccess = message;
      setTimeout(() => this.statusSuccess = null, 10000);
    } else {
      this.statusFailure = message;
    }
  }

  reset() {
    this.statusLoading = false;
    this.statusFailure = null;
    this.statusSuccess = null;
    this.currentUserInfo = null;
    this.createExportCheck = null;

    this.address?.reset(); // null chaining since this component isn't visible sometimes
  }

  public getFileRows(fileRows: string[]) {
    this.multipleUsersFromFile = fileRows.map(row => this.convertRowToUser(row));
    this.getUsersFromDatabase();
  }

  public convertRowToUser(row: string) {
    let userFromFile = new User;
    let userProfile = new UserProfile;

    let rowData = row.split(",");
    if (rowData.length > 0) userProfile.email = rowData[0].trim();

    userFromFile.profile = userProfile;
    return userFromFile;
  }

  //get users from database using the user emails obtained from csv file
  getUsersFromDatabase() {
    this.multipleUserInfo = [];
    this.notFoundUserEmails = [];
    const tracking: HttpRequest[] = [];

    for (let user of this.multipleUsersFromFile) {
      const getUserRequest = this.cloudService.getRequest(HttpRequestType.GET, "ExportScreening/UserByEmail");
      getUserRequest.query.userEmail = user.profile.email;

      getUserRequest.onComplete.on(() => {
        if (tracking.every(x => x.isComplete)) {
          this.statusLoading = false;
        }
      });
      getUserRequest.onError.on(x => {
        this.notFoundUserEmails.push(user.profile.email);
      });
      getUserRequest.onSuccess.on(x => {
        let currentUserInfo = new UserInfo(<User>x.responseObj.user);
        currentUserInfo.exportScreeningStatus = <ExportScreeningStatus>x.responseObj.lastCheck;
        currentUserInfo.exportChecks = <ExportCheck[]>x.responseObj.checks;
        currentUserInfo.isAdmin = <boolean>x.responseObj.admin;
        currentUserInfo.isExportScreeningUser = <boolean>x.responseObj.isExportScreeningUser;

        this.multipleUserInfo.push(currentUserInfo);
      });

      tracking.push(getUserRequest);
    }
    if (tracking.length > 0) {
      this.statusLoading = true;
      tracking.forEach(request => request.send());
    }
  }

  rescreenAll() {
    const tracking: HttpRequest[] = [];
    for (let userInfo of this.selectedUsers) {
      const rescreenAmberRoadRequest = this.cloudService.getRequest(HttpRequestType.POST, "ExportScreening/Rescreen");
      rescreenAmberRoadRequest.query.userId = userInfo.id;

      rescreenAmberRoadRequest.onComplete.on(() => {
        if (tracking.every(x => x.isComplete)) {
          this.statusLoading = false;
          this.getUsersFromDatabase();
          this.setStatus(`Rescreened ${tracking.filter(x => x.isCompleteWithoutError).length} user(s).`, true);
        }
      });
      rescreenAmberRoadRequest.onError.on(x => {
        this.setStatus(x.errorMessage, false);
        this.statusLoading = false; // do this in onError instead of onComplete since onSuccess will fire off another request
      });

      tracking.push(rescreenAmberRoadRequest);
    }
    if (tracking.length > 0) {
      this.statusLoading = true;
      tracking.forEach(request => request.send());
    }
  }

  addAllToGraylistManualCheck() {
    this.createExportCheck = new CreateExportCheck('Add Graylist Manual Check', () => {
      const tracking: HttpRequest[] = [];
      for (let userInfo of this.selectedUsers) {
        if (userInfo.exportScreeningStatus!.canGraylistManualCheck) {
          const addGraylistManualCheckRequest = this.cloudService.getRequest(HttpRequestType.POST, "ExportScreening/AddGraylistManualCheckAndEmail");
          addGraylistManualCheckRequest.query.userId = userInfo.id;
          addGraylistManualCheckRequest.query.status = "No Match";
          addGraylistManualCheckRequest.query.justification = `${this.createExportCheck!.justification} (Graylist Override)`;

          addGraylistManualCheckRequest.onComplete.on(() => {
            if (tracking.every(x => x.isComplete)) {
              this.statusLoading = false;
              this.getUsersFromDatabase();
              this.setStatus(`Added ${tracking.filter(x => x.isCompleteWithoutError).length} user(s) to Graylist Manual Check.`, true);
            }
          });
          addGraylistManualCheckRequest.onError.on(x => {
            this.setStatus(x.errorMessage, false);
            this.statusLoading = false; // do this in onError instead of onComplete since onSuccess will fire off another request
          });

          tracking.push(addGraylistManualCheckRequest);
        }
      }
      if (tracking.length > 0) {
        this.statusLoading = true;
        tracking.forEach(request => request.send());
      }
    });
  }

  sendAllScreeningFailed() {
    const tracking: HttpRequest[] = [];
    for (let userInfo of this.selectedUsers) {
      if (userInfo.exportScreeningStatus!.canGraylistManualCheck) {
        const sendScreeningFailedRequest = this.cloudService.getRequest(HttpRequestType.POST, "ExportScreening/SendScreeningCheckFailed");
        sendScreeningFailedRequest.query.userId = userInfo.id;

        sendScreeningFailedRequest.onComplete.on(() => {
          if (tracking.every(x => x.isComplete)) {
            this.statusLoading = false;
            this.setStatus(`Sent screening check failure to ${tracking.filter(x => x.isCompleteWithoutError).length} user(s).`, true);
          }
        });
        sendScreeningFailedRequest.onError.on(x => {
          this.setStatus(x.errorMessage, false);
          this.statusLoading = false; // do this in onError instead of onComplete since onSuccess will fire off another request
        });

        tracking.push(sendScreeningFailedRequest);
      }
    }
    if (tracking.length > 0) {
      this.statusLoading = true;
      tracking.forEach(request => request.send());
    }
  }

  setAllExportScreeningUsers(isExportScreeningUser: boolean) {
    const tracking: HttpRequest[] = [];
    for (let userInfo of this.selectedUsers) {
      if (this.isAdmin && userInfo.canSetExportScreeningUser(isExportScreeningUser)) {
        const setScreeningUserRequest = this.cloudService.getRequest(HttpRequestType.POST, "ExportScreening/SetExportScreeningUser");
        setScreeningUserRequest.query.userId = userInfo.id;
        setScreeningUserRequest.query.isExportScreeningUser = isExportScreeningUser;

        setScreeningUserRequest.onComplete.on(() => {
          if (tracking.every(x => x.isComplete)) {
            this.statusLoading = false;
            this.getUsersFromDatabase();
            this.setStatus(`Updated Screening Privileges for ${tracking.filter(x => x.isCompleteWithoutError).length} user(s).`, true);
          }
        });
        setScreeningUserRequest.onError.on(x => {
          this.setStatus(x.errorMessage, false);
          this.statusLoading = false; // do this in onError instead of onComplete since onSuccess will fire off another request
        });

        tracking.push(setScreeningUserRequest);
      }
    }
    if (tracking.length > 0) {
      this.statusLoading = true;
      tracking.forEach(request => request.send());
    }
  }

  shouldDisableAddAllToGraylist() {
    // check if any user can be added to graylist manual check
    return !this.selectedUsers.some(userInfo => userInfo.exportScreeningStatus!.canGraylistManualCheck);
  }

  // disable if current user is not admin, no users selected, or if all selected users already set to new value
  shouldDisableAddRemoveExportScreeningUsers(newValue: boolean) {
    return !this.isAdmin ||
      this.selectedUsers.length === 0  ||
      this.selectedUsers.every(userInfo => !userInfo.canSetExportScreeningUser(newValue));
  }

  showEscalateExportScreeningButton(users: UserInfo[]): boolean{
    return this.isAdmin &&
     users.length > 0 &&
     users.every(userInfo => userInfo.exportScreeningStatus?.status !== "No Match");
  }
}
