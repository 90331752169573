
//
// Copyright (C) 2023 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { ITreeObject } from "app/exportScreening/treeView/treeObject";

export class JsonResponseTreeObject implements ITreeObject {
    constructor(private jsonObject: any) { }

    getChildSubtree(childName: string): ITreeObject | null {
        const child = this.getChildJsonObject(childName);
        if (child) {
            return new JsonResponseTreeObject(child);
        } else {
            return null;
        }
    }
    getChidrenNames(): String[] {
        return Object.keys(this.jsonObject);
    }
    isNullOrEmpty(childName: string): boolean {
        const child = this.getChildJsonObject(childName);
        return child === null || child === "";
    }
    getChildValue(childName: string): string {
        const child = this.getChildJsonObject(childName);
        if (Object.keys(child).length == 0) {
            // Display empty JSON array node as "EMPTY".
            return "EMPTY";
        } else {
            return child as string;
        }
    }
    hasChildren(childName: string): boolean {
        const value = this.getChildJsonObject(childName);
        if (value == null)
            return false;
        return typeof value === 'object';
    }

    private getChildJsonObject(childName: string): any {
        return this.jsonObject[childName];
    }
}