//
// Copyright (C) 2023 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, Input, OnInit } from '@angular/core'

@Component({
    selector: 'export-screening-icon',
    template: `
    <ng-container *ngIf="exportStatus != 'None' && exportStatus">
        <clr-icon [attr.shape]="this.iconShape" [ngClass]="'is-solid ' + this.className"></clr-icon>       
    </ng-container>
    `
})


export class ExportScreeningIcon implements OnInit{
   
    @Input() exportStatus: string

    iconShape: string
    className: string

    ngOnInit(): void {
        switch(this.exportStatus) { 
            case "No Match": { 
                this.iconShape = "success-standard"
                this.className = "is-success"
                break; 
            } 
            case "Potential Match": { 
                this.iconShape = "warning-standard"
                this.className = "is-warning"
                break; 
            } 
            case "Match": { 
                this.iconShape = "error-standard"
                this.className = "is-error"
                break; 
            } 
            default: { 
               this.iconShape = "warning-standard"
               this.className = "is-warning"
               break; 
            } 
        } 
    }
}
