//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component } from '@angular/core';
import { UpdatableCollectionComponent, IEntryField } from 'userregistration/updatablecollection.component';

interface IWhitelistEntry {
    id: string;
    companyName: string;
    emailDomainName: string;
    countryCode: string;
    expirationDate: string;
}

@Component({
    selector: 'whitelist-table',
    templateUrl: 'whitelist.component.html',
})
export class WhitelistComponent {

    constructor() {
    }

    public getEntryFields(): IEntryField[] {
        return [
            { name: 'Id', caption: 'Id' },
            { name: 'CompanyName', caption: 'Company Name (Informational)'},
            { name: 'CountryCode', caption: 'Country' },
            { name: 'EmailDomainName', caption: 'Email Domain' },
            { name: 'Expiration', caption: 'Expiration' }
        ]
    }

    public getEntryFieldValues(entry: IWhitelistEntry): string[] {
        let fields: string[] = [entry.id, entry.companyName, entry.countryCode, entry.emailDomainName, entry.expirationDate];
        return fields;
    }
}
