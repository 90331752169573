<request-presenter [request]="productRequest">
    <ng-template>
        <div class="centerText">
            <button
                class="btn btn-primary"
                (click)="openDiscoveryWizard = true"
            >
                Create Discovery Subscriptions
            </button>

            <button
                class="btn btn-primary btn-separated"
                (click)="openCloudWizard = true"
            >
                Create Ansys Cloud Subscriptions
            </button>

            <button
                class="btn btn-primary btn-separated"
                (click)="cloudTrialWizard.isWizardOpen = true"
            >
                Create Ansys Cloud Trial
            </button>

            <button
                *ngIf="enableAnsysOnline"
                class="btn btn-primary btn-separated"
                (click)="openOnlineWizard = true"
            >
                Create Ansys Online Subscriptions
            </button>
        </div>

        <discoverySubscriptionWizard [(open)]="openDiscoveryWizard" [products]="discoveryProducts"></discoverySubscriptionWizard>

        <cloudSubscriptionWizard [(open)]="openCloudWizard" [products]="cloudProducts"></cloudSubscriptionWizard>

        <ACCTrialsWizard #cloudTrialWizard [products]="cloudTrialProducts"></ACCTrialsWizard>

        <onlineSubscriptionWizard *ngIf="enableAnsysOnline" [(open)]="openOnlineWizard" [products]="onlineProducts"></onlineSubscriptionWizard>

        <subscriptionTable [products]="products"></subscriptionTable>
    </ng-template>
</request-presenter>
