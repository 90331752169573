<clr-datagrid [clrDgLoading]="getEntriesGetRequest.isLoading"
              (clrDgRefresh)="refresh($event)">
    <!-- *** May the upcoming generations of developers have mercy on me ***
        I *had* to write this awful piece of shit after losing too many hours
        trying to make the nicer version below work. clrDgField does not seem to work with ngFor. -->
    <!--<clr-dg-column *ngFor="let field of entryFields" [clrDgField]="field.name">{{field.caption}}</clr-dg-column>-->
    <clr-dg-column *ngIf="entryFields.length > 0" [clrDgField]="entryFields[0].name">{{entryFields[0].caption}}</clr-dg-column>
    <clr-dg-column *ngIf="entryFields.length > 1" [clrDgField]="entryFields[1].name">{{entryFields[1].caption}}</clr-dg-column>
    <clr-dg-column *ngIf="entryFields.length > 2" [clrDgField]="entryFields[2].name">{{entryFields[2].caption}}</clr-dg-column>
    <clr-dg-column *ngIf="entryFields.length > 3" [clrDgField]="entryFields[3].name">{{entryFields[3].caption}}</clr-dg-column>
    <clr-dg-column *ngIf="entryFields.length > 4" [clrDgField]="entryFields[4].name">{{entryFields[4].caption}}</clr-dg-column>
    <clr-dg-column *ngIf="entryFields.length > 5" [clrDgField]="entryFields[5].name">{{entryFields[5].caption}}</clr-dg-column>
    <clr-dg-column *ngIf="entryFields.length > 6" [clrDgField]="entryFields[6].name">{{entryFields[6].caption}}</clr-dg-column>
    <clr-dg-column *ngIf="entryFields.length > 7" [clrDgField]="entryFields[7].name">{{entryFields[7].caption}}</clr-dg-column>
    <clr-dg-column *ngIf="entryFields.length > 8" [clrDgField]="entryFields[8].name">{{entryFields[8].caption}}</clr-dg-column>
    <clr-dg-column *ngIf="entryFields.length > 9" [clrDgField]="entryFields[9].name">{{entryFields[9].caption}}</clr-dg-column>
    <span *ngIf="entryFields.length > 10">*** WARNING: This datagrid is hardwired for a maximum of 10 columns. Search for this error message in the source code and may your indulgence forgive me. ***</span>

    <clr-dg-placeholder>No Result</clr-dg-placeholder>

    <!-- if server driven use ngFor and not clrDgItems ! -->
    <clr-dg-row *ngFor="let entry of entries" [clrDgItem]="entry">
        <clr-dg-cell *ngFor="let fieldValue of getEntryFieldValues(entry)">{{fieldValue}}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        <request-page-presenter [controller]="entriesController"></request-page-presenter>
    </clr-dg-footer>
</clr-datagrid>
<request-presenter [request]="getEntriesPostRequest" [alwaysShowContent]="true" [locker]="'true'">
    <ng-template>
        <input id="importFileInput" type="file" accept=".csv" name="importFileInput" (change)="import($event)" hidden="true" #importFileInput />
        <span>
            <button (click)="importFileInput.click()" class="btn btn-primary">Import</button>
        </span>
        <ng-container *ngIf="isUpdateCurrent">
            <span>Import in progress, please wait...</span>
            <span class='spinner spinner-sm'></span>
        </ng-container>
    </ng-template>
</request-presenter>
<h1>History</h1>
<clr-datagrid [clrDgLoading]="areUpdatesBeingLoadedForTheFirstTime()">
    <clr-dg-column> Date</clr-dg-column>
    <clr-dg-column> Updated By</clr-dg-column>
    <clr-dg-column> Status</clr-dg-column>
    <clr-dg-column> Details</clr-dg-column>

    <clr-dg-placeholder>No Result</clr-dg-placeholder>

    <!-- if server driven use ngFor and not clrDgItems ! -->
    <clr-dg-row *ngFor="let update of updates" [clrDgItem]="update">
        <clr-dg-cell>{{update.date}}</clr-dg-cell>
        <clr-dg-cell>{{update.adminEmail}}</clr-dg-cell>
        <clr-dg-cell>{{update.status}}</clr-dg-cell>
        <clr-dg-cell>{{update.details}}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        <request-page-presenter [controller]="updatesController" [spinner]="false"></request-page-presenter>
    </clr-dg-footer>
</clr-datagrid>
