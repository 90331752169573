<request-presenter [request]="companyRequest" [locker]="true">
    <ng-template>
        <h3>{{companyFound ? "Existing Company" : "New Company"}}</h3>
        <form clrForm>
            <clr-input-container>
                <label for="companyIdReadonly">Customer Number</label>
                <input clrInput type="text" id="companyIdReadonly" [(ngModel)]="company.id" name="companyIdReadonly"
                    readonly>
            </clr-input-container>
            <clr-input-container>
                <label for="companyName">Company Name</label>
                <input clrInput type="text" id="companyName" [(ngModel)]="company.displayName" name="companyName"
                    [readonly]="companyFound">
            </clr-input-container>
            <address-enrichment [url]="environment.oidc" [street]="company.streetAddress"
                (streetChange)="handleStreetChange($event)" [city]="company.city"
                (cityChange)="handleCityChange($event)" [postal]="company.postalCode"
                (postalChange)="handlePostalChange($event)" [state]="company.state"
                (stateChange)="handleStateChange($event)" [country]="company.country"
                (countryChange)="handleCountryChange($event)" [readonly]="companyFound">
            </address-enrichment>
        </form>
    </ng-template>
</request-presenter>