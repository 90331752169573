//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, Input, Output, EventEmitter } from "@angular/core";
import { StringUtils } from "@Shared/utils/stringUtils";

@Component({
    selector: "csv-file-input",
    template: `
    <clr-control-container>
        <!-- ngIf to not activate the browser input in enter -->
        <label for="csvFile">{{fileInputLabel}}  (.csv)</label>
        <input id="csvFile" type="file" accept=".csv" name="csvFile" (change)="onCsvFileChange($event)"
            hidden="true" #csvFile />
        <span>
            <input clrControl *ngIf="!this.csvFileName" readonly type="text" value="No File Selected"
                (click)="csvFile.click()" />
            <input clrControl *ngIf="this.csvFileName" readonly type="text"
                value="{{rowCount}} {{itemName}}(s) [{{this.csvFileName}}]"
                (click)="csvFile.click()" />
            <button (click)="csvFile.click()" class="btn btn-secondary btn-sm btn-icon">Load</button>
        </span>
    </clr-control-container>
    `
})
export class CsvFileInputComponent {
    @Input() public itemName: string;
    @Input() public fileInputLabel: string;

    @Output() public fileRowsEvent = new EventEmitter<string[]>();

    public csvFileName: string | null = null;
    public rowCount: number;

    public onCsvFileChange(event: any) {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            let file = event.target.files[0];
            this.csvFileName = file.name;
            reader.onload = () => {
                let content: string = <string>reader.result;
                let rows = content
                    .split("\n")
                    .filter(m => !StringUtils.IsNullOrEmpty(m.replace(/,/g, '').trim()));
                this.rowCount = rows.length;
                this.fileRowsEvent.emit(rows);
            }
            reader.readAsText(<Blob>file);

            // we want to be able to select multipletimes the same files (to reload content).
            event.target.value = "";
        } else {
            this.csvFileName = null;
        }
    }
}
