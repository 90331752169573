//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { MsalHttpRequestService } from "@Msal/services/msalHttpRequest.service";
import { HttpRequest, HttpRequestType } from '@Shared/utils/httpRequest';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Subscription, OpenMode, Product, IEditableSubscription } from './subscription';
import { RegionTypes, CustomerTypes } from './cloud.constants';
import { CommonSubscriptionManager, CommonSubscriptionWizardComponent, CommonSubscriptionWizardResult } from './commonSubscriptionWizard.component';


@Component({
    selector: 'renewSubscriptionWizard',
    templateUrl: 'renewSubscriptionWizard.component.html',
})
export class RenewSubscriptionWizardComponent implements OnInit {
    @ViewChild('wizard') wizard: CommonSubscriptionWizardComponent;

    @Input() open: boolean = false;
    @Output() openChange = new EventEmitter<boolean>();

    @Input() products: Product[] = [];

    mode: OpenMode = OpenMode.Renewal;

    subscriptionManager: CommonSubscriptionManager = new CommonSubscriptionManager();

    containCloudSubscriptions: boolean = false;

    postSubscriptionsRequest: HttpRequest;
    postCloudSubscriptionsRequest: HttpRequest;

    region: string = RegionTypes.Americas;
    customerType: string = CustomerTypes.Customer;

    regionTypes = RegionTypes;
    customerTypes = CustomerTypes;

    constructor(private httpService: MsalHttpRequestService) { }

    ngOnInit(): void {
        this.postSubscriptionsRequest = this.httpService.getRequest(HttpRequestType.POST, 'Admin/RenewSubscriptions');
        this.postSubscriptionsRequest.onSuccess.on(() => this.openChange.emit(false));
        this.postCloudSubscriptionsRequest = this.httpService.getRequest(HttpRequestType.POST, 'Admin/RenewCloudSubscriptions')
    }

    setCompanyForFirstStep(companyId: string): void {
        this.wizard.setCompanyForFirstStep(companyId);
    }

    setASCForSecondStep(ascEmail: string): void {
        this.wizard.setASCForSecondStep(ascEmail);
    }

    setSubscriptionsForThirdStep(subscriptions: IEditableSubscription[]) {
        this.subscriptionManager.setSubscriptions(subscriptions);
        for (let editableSubscription of this.subscriptionManager.subscriptions) {
            if (this.subscriptionManager.getProductNameFromPartNumber(this.products, editableSubscription.product).includes('Cloud')) {
                this.containCloudSubscriptions = true;
                break;
            }
        }
    }

    submit(data: CommonSubscriptionWizardResult): void {
        // Unfold subscription groups, if any.
        let subscriptions = new Array<Subscription>();
        let cloudSubscriptions = new Array<Subscription>();
        for (let editableSubscription of this.subscriptionManager.subscriptions) {
            if (this.subscriptionManager.getProductNameFromPartNumber(this.products, editableSubscription.product).includes('Cloud')) {
                cloudSubscriptions = cloudSubscriptions.concat(editableSubscription.getModelSubscriptions());
            } else {
                subscriptions = subscriptions.concat(editableSubscription.getModelSubscriptions());
            }
        }

        if (this.containCloudSubscriptions) {
            this.postCloudSubscriptionsRequest.body = {
                company: data.company,
                ansysSupportCoordinator: data.asc,
                orderId: data.orderId,
                region: this.region,
                customerType: this.customerType,
                subscriptions: cloudSubscriptions,
                additionalCCs: data.additionalCC.getEmails(),
            };
    
            this.postCloudSubscriptionsRequest.send();
        }

        this.postSubscriptionsRequest.body = {
            company: data.company,
            ansysSupportCoordinator: data.asc,
            orderId: data.orderId,
            subscriptions: subscriptions,
            additionalCCs: data.additionalCC.getEmails(),
        };

        this.postSubscriptionsRequest.send();
    }

    areAllSubscriptionsValidForRenewal(): boolean {
        if (this.subscriptionManager.areAllSubscriptionsValid()) {
            return this.subscriptionManager.subscriptions.every(subscription => subscription.endDate && Date.now() < Date.parse(subscription.endDate));
        }
        
        return false;
    }

    reset(): void {
        this.subscriptionManager = new CommonSubscriptionManager();
        this.containCloudSubscriptions = false;
    }
}
