//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { StringUtils } from '@Shared/utils/stringUtils';
import { ClrWizard } from '@clr/angular';
import { MsalHttpRequestService } from "@Msal/services/msalHttpRequest.service";
import { HttpRequest, HttpRequestType } from '@Shared/utils/httpRequest';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AllotmentTypes } from './cloud.constants';
import { formatDateForMinMax } from './date.helper';

interface IEditableUserTrial {
    email: string;
    orderId: string;
    productPartNumber: string;
    productName: string;
    startDate: string;
    endDate: string;
    companyId: string;
    allotment: string;
    userTrialCcs: string[];
    additionalCCsInvalidAddresses: string[];
}

class Company {
    public id: string | null;
    public displayName: string | null;
    public streetAddress: string | null;
    public postalCode: string | null;
    public city: string | null;
    public state: string | null;
    public country: string | null;
}

export class TrialSubscription implements IEditableUserTrial {
    public email: string;
    public orderId: string = "";
    public productPartNumber: string;
    public productName: string;
    public startDate: string;
    public endDate: string;
    public companyId: string;
    public allotment: string = AllotmentTypes.AEU;
    public userTrialCcs: string[] = [];

    public get additionalCCsInvalidAddresses(): string[] {
        let additionalCCsInvalidAddresses = [];
        for (const additionalCC of this.userTrialCcs) {
            if (!StringUtils.validateEmailAddress(additionalCC)) {
                additionalCCsInvalidAddresses.push(additionalCC);
            }
        }
        return additionalCCsInvalidAddresses;
    }
    public get isValid(): boolean {
        if (this.startDate === undefined || this.endDate === undefined) {
            return false;
        }

        const startDate = new Date(this.startDate);
        const endDate = new Date(this.endDate);
        const currentDate = new Date();

        currentDate.setHours(0, 0, 0, 0);

        return (startDate < endDate && endDate >= currentDate &&
            !StringUtils.IsNullOrEmpty(this.orderId) && !StringUtils.IsNullOrEmpty(this.companyId) &&
            !StringUtils.IsNullOrEmpty(this.productName) && !StringUtils.IsNullOrEmpty(this.productPartNumber) &&
            StringUtils.validateEmailAddress(this.email) &&
            (this.additionalCCsInvalidAddresses.length == 0));
    }
}

class Product {
    public name: string;
    public partNumber: string;
    public category: string;
}

@Component({
    selector: 'ACCTrialsWizard',
    templateUrl: 'ACCTrialsWizard.component.html',
})


export class ACCTrialsWizardComponent implements OnInit {
    @ViewChild("wizard", { static: false }) wizard: ClrWizard;

    @Input() products: Product[] = [];

    private _isWizardOpen: boolean = false;
    public loadingFlag: boolean = false;
    public errorFlag: boolean = false;
    public validationVisible: boolean = false;
    public userInfoRequest: HttpRequest;
    public connectedUser: string;
    public cloudTrialCCEmail: string;

    public oneUserTrialSub: TrialSubscription;
    public multipleUserTrialSub: TrialSubscription[];
    public userTrialCcsInput: string;
    public productPartNumber: string;

    public postSubscriptionsRequest: HttpRequest;
    public getUserRequest: HttpRequest;
    public getCloudTrialsEmail: HttpRequest;

    public typeTrialSubInput: string = "one";
    public foundCompanies: Map<string, Company> = new Map<string, Company>();
    public notFoundCompanies: string[] = [];
    public allCompanies: string[] = [];
    public errorMessages: string[];

    public AllotmentTypes = AllotmentTypes;

    public allTrialAssignments(): TrialSubscription[] {
        let trials: TrialSubscription[];
        if (this.typeTrialSubInput == "one") {
            this.oneUserTrialSub.userTrialCcs = this.userTrialCcsInput ? this.userTrialCcsInput.split(',') : [];
            this.oneUserTrialSub.userTrialCcs.forEach((s, index) => this.oneUserTrialSub.userTrialCcs[index] = s.trim());
            trials = [this.oneUserTrialSub];
        } else {
            for (let trial of this.multipleUserTrialSub) {
                if (trial.userTrialCcs.indexOf(this.cloudTrialCCEmail) === -1) {
                    trial.userTrialCcs.push(this.cloudTrialCCEmail);
                }
            }
            trials = this.multipleUserTrialSub;
        }

        let productName: string = this.getProductNameFromPartNumber(this.productPartNumber);
        for (let i of trials) {
            i.productName = productName;
            i.productPartNumber = this.productPartNumber;
        }
        return trials;
    }

    public getTrialList(): TrialSubscription[] {
        let trials: TrialSubscription[];
        if (this.typeTrialSubInput == "one") {
            trials = [this.oneUserTrialSub];
        } else {
            trials = this.multipleUserTrialSub;
        }

        let productName: string = this.getProductNameFromPartNumber(this.productPartNumber);

        for (let i of trials) {
            i.productName = productName;
            i.productPartNumber = this.productPartNumber;
        }
        return trials;
    }

    public constructor(private httpService: MsalHttpRequestService) {
        this.reset();
    }

    public reset(): void {
        if (this.wizard != null) {
            this.wizard.reset();
        }
        this._isWizardOpen = false;
        this.loadingFlag = false;
        this.errorFlag = false;
        this.validationVisible = false;
        this.oneUserTrialSub = new TrialSubscription();
        this.multipleUserTrialSub = [];
        let today = new Date();
        this.oneUserTrialSub.startDate = this.printFormatedDate(today);
        let expiry = new Date(today.setDate(today.getDate() + 30));
        this.oneUserTrialSub.endDate = this.printFormatedDate(expiry);
        this.userTrialCcsInput = this.cloudTrialCCEmail;
        this.typeTrialSubInput = "one";
    }

    public ngOnInit(): void {
        this.getCloudTrialsEmail = this.httpService.getRequest(HttpRequestType.GET, 'Admin/CloudTrialsCcEmail');
        this.getCloudTrialsEmail.onSuccess.on(r => {
            this.cloudTrialCCEmail = r.responseObj.ccemail;
            this.userTrialCcsInput = this.cloudTrialCCEmail;
        });

        this.getCloudTrialsEmail.send();

        this.getUserRequest = this.httpService.getRequest(HttpRequestType.GET, "getuser/GetUserWithEmail");
        this.userInfoRequest = this.httpService.getRequest(HttpRequestType.GET, "user/info");
        this.userInfoRequest.onSuccess.on(r => {
            this.connectedUser = r.responseObj.mail;
        });
        this.userInfoRequest.send();

        this.postSubscriptionsRequest = this.httpService.getRequest(HttpRequestType.POST, 'Admin/CreateCloudFreeTrialSubscriptions');

        this.postSubscriptionsRequest.onStart.on(req => {
            req.body = {
                AnsysSupportCoordinatorEmail: this.connectedUser,
                FreeTrialSubscriptionsModels: this.allTrialAssignments()
            };
        })
        this.postSubscriptionsRequest.onSuccess.on(h => {
            this.reset();
            this.wizard.close();
        });
        if (this.products.length > 0) {
            this.productPartNumber = this.products[0].partNumber;
        }
    }

    public updateCompanyIdWithFNO() {
        for (let trial of this.allTrialAssignments()) {
            trial.companyId = <string>this.foundCompanies.get(trial.companyId)!.id;
        }
    }

    public doFinish(): void {
        this.updateCompanyIdWithFNO();
        this.postSubscriptionsRequest.send();
    }

    public get isWizardOpen(): boolean {
        return this._isWizardOpen;
    }

    public set isWizardOpen(value: boolean) {
        // No change?
        if (this._isWizardOpen === value) { return; }

        this._isWizardOpen = value;
        if (this._isWizardOpen) {
            this.onOpen();
        }
    }

    public getProductNameFromPartNumber(partNumber: string | null | undefined): string {
        return this.getProductInfoFromPartNumber(partNumber, p => p.name);
    }

    public getProductCategoryFromPartNumber(partNumber: string | null | undefined): string {
        return this.getProductInfoFromPartNumber(partNumber, p => p.category);
    }

    private getProductInfoFromPartNumber(partNumber: string | null | undefined, getter: (product: Product) => string): string {
        if ((partNumber === undefined) || (partNumber == null)) {
            return "";
        }
        const product: Product | undefined = this.products.find(p => p.partNumber === partNumber);
        return product !== undefined ? getter(product) : "<Product does not exist>";
    }

    public getFileRows(fileRows: string[]) {
        this.multipleUserTrialSub = fileRows.map(row => this.convertRowToTrialSubscription(row));
    }

    public convertRowToTrialSubscription(row: string) {
        let trial = new TrialSubscription;
        let rowData = row.split(",");
        if (rowData.length > 0) trial.email = rowData[0].trim();
        if (rowData.length > 1) trial.orderId = rowData[1].trim();
        if (rowData.length > 2) trial.startDate = this.printFormatedDate(new Date(rowData[2].trim()));
        if (rowData.length > 3) trial.endDate = this.printFormatedDate(new Date(rowData[3].trim()));
        if (rowData.length > 4) trial.companyId = rowData[4].trim();
        if (rowData.length > 5) trial.allotment = rowData[5].trim().toUpperCase();
        for (let i = 6; i < rowData.length; i++) {
            let trialSubCc = rowData[i].trim();
            if (trialSubCc) trial.userTrialCcs.push(trialSubCc);
        }

        let productName: string = this.getProductNameFromPartNumber(this.productPartNumber);
        trial.productName = productName;
        trial.productPartNumber = this.productPartNumber;

        return trial;
    }

    public isTrialSubValid() {
        if (this.allTrialAssignments().length == 0) return false;
        for (let trial of this.allTrialAssignments()) {
            if (!trial.isValid) {
                return false;
            }
        }
        return true;
    }

    public printFormatedDate(date: Date) {
        let year = date.getFullYear();

        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return `${month}/${day}/${year}`;
    }

    public checkCompanies(): void {
        this.allCompanies = [];
        this.foundCompanies = new Map<string, Company>();
        this.notFoundCompanies = [];
        for (let i of this.allTrialAssignments()) {
            if (this.allCompanies.indexOf(i.companyId) == -1) {
                this.allCompanies.push(i.companyId);
                let req = this.httpService.getRequest(HttpRequestType.GET, "Admin/GetCompanyById");
                req.onSuccess.on(h => {
                    var resp = h.responseObj;
                    if (!resp.error) {
                        this.foundCompanies.set(h.query.id, resp);
                        this.foundCompanies.set(resp.id, resp);
                    } else {
                        this.notFoundCompanies.push(h.query.id);
                    }
                    this.areCompaniesValid();
                })


                req.query.id = i.companyId;
                req.send();
            }
        }
    }

    public get allCompaniesSearched(): boolean {
        let searched: boolean = true;
        for (let comp of this.allCompanies) {
            if (!this.foundCompanies.has(comp) &&
                this.notFoundCompanies.indexOf(comp) == -1) {
                searched = false;
                break;
            }
        }
        return searched;
    }
    public areCompaniesValid() {
        if (this.allCompaniesSearched) {
            this.loadingFlag = false;
            if (this.notFoundCompanies.length == 0) {
                this.wizard.forceNext();

                /* hack to display the datagrid of all invitations properly 
                   When the wizard page and the datagrid is shown at the same time the columns length are not dynamic
                   and it make the display look bad (for example the email adress override the other entries of the row)
                   If the table if shown after (100ms timeout), the datagrid is shown a expected.
                */
                setTimeout(() => {
                    this.validationVisible = true;
                }, 100);
            } else {
                this.errorFlag = true;
            }
        }
    }

    public doNext() {
        this.errorFlag = false;
        this.loadingFlag = true;
        this.checkCompanies();
    }
    private onOpen(): void {
    }

    public doCancel() {
        this.wizard.close();
        this.reset();
    }
}
