//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, OnInit } from '@angular/core';
import { MsalHttpRequestService } from '@Msal/services/msalHttpRequest.service';
import { HttpRequest, HttpRequestType } from '@Shared/utils/httpRequest';
import { OpenMode, Product } from './subscriptionWizard/subscription';
import { environment } from '../environments/environment';

@Component({
    selector: 'business',
    templateUrl: 'business.component.html',
})
export class BusinessComponent implements OnInit {
    readonly cloudEssentialsPartNumber = '9001316';

    openMode = OpenMode;
    openDiscoveryWizard = false;
    openCloudWizard = false;
    openOnlineWizard = false;

    products: Product[] = [];
    productRequest: HttpRequest;

    private _getByCategory(category: string) {
        return this.products.filter(x => x.productCategory === category);
    }

    get discoveryProducts(): Product[] { return this._getByCategory('discovery'); }
    get cloudProducts(): Product[] { return this._getByCategory('cloud'); }
    get onlineProducts(): Product[] { return this._getByCategory('Ansys Online'); }

    get cloudTrialProducts(): Product[] {
        return this.products.filter(x => x.partNumber == this.cloudEssentialsPartNumber);
    }

    get enableAnsysOnline() { return environment.enableAnsysOnline; }

    constructor(private httpService: MsalHttpRequestService) { }

    ngOnInit(): void {
        this.productRequest = this.httpService.getRequest(HttpRequestType.GET, 'Admin/GetProducts');
        this.productRequest.onSuccess.on(request => this.products = <Product[]>request.responseObj);
        this.productRequest.send();
    }

}
