//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { MsalHttpRequestService } from "@Msal/services/msalHttpRequest.service";
import { HttpRequest, HttpRequestType } from '@Shared/utils/httpRequest';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Subscription, Product, OpenMode } from './subscription';
import { RegionTypes, CustomerTypes } from './cloud.constants';
import { CommonSubscriptionManager, CommonSubscriptionWizardResult } from './commonSubscriptionWizard.component';



class RegionOption {
    constructor(public name: string, public caption: string) {}
}



@Component({
    selector: 'onlineSubscriptionWizard',
    templateUrl: './onlineSubscriptionWizard.component.html',
    styles: [`
        input { width: 250px; }
        input[type='date'] { width: 200px; }
        .subscription-border { margin-bottom: 5px; }
        .subscription-form { margin: 10px 20px; }
        .quantity { width: 50px; }
    `],
})
export class OnlineSubscriptionWizardComponent implements OnInit {
    @Input() open: boolean = false;
    @Output() openChange = new EventEmitter<boolean>();

    @Input() products: Product[] = [];

    mode: OpenMode = OpenMode.Creation;

    subscriptionManager: CommonSubscriptionManager = new CommonSubscriptionManager();

    postSubscriptionsRequest: HttpRequest;

    regions = [
        new RegionOption('eastus', 'East US'),
    ];
    region: string = 'eastus';
    customerType: string = CustomerTypes.Customer;

    regionTypes = RegionTypes;
    customerTypes = CustomerTypes;

    constructor(private httpService: MsalHttpRequestService) { }

    ngOnInit(): void {
        this.postSubscriptionsRequest = this.httpService.getRequest(HttpRequestType.POST, 'Admin/CreateAnsysOnlineSubscriptions');
        this.postSubscriptionsRequest.onSuccess.on(() => this.openChange.emit(false));
    }

    submit(data: CommonSubscriptionWizardResult): void {
        // Unfold subscription groups, if any.
        let subscriptions = new Array<Subscription>();
        for (let editableSubscription of this.subscriptionManager.subscriptions) {
            subscriptions = subscriptions.concat(editableSubscription.getModelSubscriptions());
        }

        this.postSubscriptionsRequest.body = {
            company: data.company,
            ansysSupportCoordinator: data.asc,
            orderId: data.orderId,
            region: this.region,
            customerType: this.customerType,
            subscriptions: subscriptions,
            additionalCCs: data.additionalCC.getEmails(),
        };

        this.postSubscriptionsRequest.send();
    }

    reset(): void {
        this.subscriptionManager = new CommonSubscriptionManager();
    }
}
