<div style="text-align:center; width:100%;">
    <button class="btn btn-primary" (click)="refresh()">Refresh</button>
</div>

<request-presenter [request]="getStatsRequest" [isLarge]="true">
    <request-loading>
        <div class="centerText">
            <h3>Compute stats...</h3>
            <br/>
        </div>
    </request-loading>

    <ng-template>
        <div class="clr-row">
            <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12 clr-col-xs-12">
                <div class="card">
                    <div class="card-header">
                        Last sessions
                    </div>
                    <div class="card-block">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>IP</th>
                                    <th>Date</th>
                                    <th>Country(with ip)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let ping of stats.lastPings">
                                    <td>{{ping.localization ? ping.localization.ip : "Unknown"}}</td>
                                    <td>{{ping._id.creationTime | date: 'medium'}}</td>
                                    <td>{{ping.localization ? ping.localization.country_name : "Unknown"}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-block">
                        <div class="card-block">
                            <canvas baseChart width="100" height="100" [datasets]="sessionPerCategoryData"
                                [labels]="sessionPerCategoryLabel" chartType="bar">
                            </canvas>
                        </div>
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12 clr-col-xs-12">
                <div class="card">
                    <div class="card-header">
                        <clr-icon shape="applications"></clr-icon>
                        Globals stats
                    </div>
                    <div class="card-block">
                        <ul class="list">
                            <li><span class="mainColor">{{stats.userCount}}</span> users.</li>
                            <li><span class="mainColor">{{stats.userValidCount}}</span> valid users.</li>
                            <li><span class="mainColor">{{stats.userDlDiscoveryCount}}</span> users have downloaded
                                Discovery.</li>
                            <li><span class="mainColor">{{stats.dlDiscoveryCount}}</span> downloads.</li>
                            <li><span class="mainColor">{{stats.sessionCount}}</span> sessions.</li>
                            <li><span class="mainColor">{{stats.userDlDiscoveryCount == 0 ? 0 : stats.sessionCount /
                                    stats.userDlDiscoveryCount}}</span> sessions per users.</li>
                            <li>Average session : <span class="mainColor">{{stats.averageSessionDuration}}</span>.</li>
                        </ul>
                    </div>
                    <div class="card-block">
                        <div class="title">Top 5 country (download)</div>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Country</th>
                                    <th>Downloads</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let info of stats.dlPerCountryTop">
                                    <td>{{info.key}}</td>
                                    <td>{{info.value}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="card-block">
                        <div class="title">Top 5 source (users)</div>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Source</th>
                                    <th>Users</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let info of stats.userPerSourceTop">
                                    <td>{{info.key}}</td>
                                    <td>{{info.value}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12 clr-col-xs-12">
                <div class="card">
                    <div class="card-header">
                        <clr-icon shape="line-chart"></clr-icon>
                        Session per country
                    </div>
                    <div class="card-block">
                        <canvas baseChart width="100" height="100" [data]="sessionPerCountryData"
                            [labels]="sessionPerCountryLabel" chartType="pie">
                        </canvas>
                    </div>
                    <div class="card-block">
                        <div class="title">Top Country (sessions)</div>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Country</th>
                                    <th>Sessions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let info of stats.sessionPerCountry">
                                    <ng-container *ngIf="info.value != 0">
                                        <td>{{info.key}}</td>
                                        <td>{{info.value}}</td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12 clr-col-xs-12">
                <div class="card">
                    <div class="card-header">
                        <clr-icon shape="line-chart"></clr-icon>
                        Users per day
                    </div>
                    <div class="card-block">
                        <canvas baseChart width="100" height="100" [datasets]="userPerDayData"
                            [labels]="userPerDayLabel" chartType="line">
                        </canvas>
                    </div>
                    <div class="card-block">
                        <div class="title">Top Country (users)</div>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Country</th>
                                    <th>User</th>
                                    <th>Valid User</th>
                                    <th>Users Dl DiscoveryLive</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let info of stats.userPerCountry">
                                    <ng-container *ngIf="info.value.count != 0">
                                        <td>{{info.key}}</td>
                                        <td>{{info.value.count}}</td>
                                        <td>{{info.value.validCount}}</td>
                                        <td>{{info.value.firstDownloadPerProductCount.discoverylive == null ? 0 :
                                            info.value.firstDownloadPerProductCount.discoverylive}}</td>
                                    </ng-container>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12 clr-col-xs-12">
                <div class="card">
                    <div class="card-header">
                        <clr-icon shape="line-chart"></clr-icon>
                        Downloads last days
                    </div>
                    <div class="card-block">
                        <canvas baseChart width="100" height="100" [datasets]="downloadData" [labels]="downloadLabel"
                            [options]="chartOptions" chartType="line">
                        </canvas>
                    </div>
                </div>
            </div>

            <div class="clr-col-lg-6 clr-col-md-12 clr-col-sm-12 clr-col-xs-12">
                <div class="card">
                    <div class="card-header">
                        <clr-icon shape="line-chart"></clr-icon>
                        Sessions last days
                    </div>
                    <div class="card-block">
                        <canvas baseChart width="100" height="100" [datasets]="sessionsData" [labels]="sessionsLabel"
                            [options]="chartOptions" chartType="line">
                        </canvas>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</request-presenter>