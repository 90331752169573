
//
// Copyright (C) 2023 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, Input } from '@angular/core';
import { ITreeObject } from './treeObject';

@Component({
    selector: 'tree-view',
    template: `
            <ul class="list">
                <ng-container *ngFor="let childName of tree.getChidrenNames()">
                    <li>
                        {{childName}} :
                        <span *ngIf="tree.isNullOrEmpty(childName)"> NULL </span>
                        <span *ngIf="!tree.isNullOrEmpty(childName) && !tree.hasChildren(childName)">
                            {{tree.getChildValue(childName)}}
                        </span>
                        <span *ngIf="!tree.isNullOrEmpty(childName) && tree.hasChildren(childName)">
                            <tree-view [tree]="tree.getChildSubtree(childName)!"></tree-view>
                        </span>
                    </li>
                </ng-container>
            </ul>
    `
})
export class TreeViewComponent {
    @Input() public tree: ITreeObject;
}