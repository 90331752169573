//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Pipe, PipeTransform } from '@angular/core';
import { formatDateForMinMax } from 'business/subscriptionWizard/date.helper';

@Pipe({
  name: 'minEndDate'
})
export class MinEndDatePipe implements PipeTransform {

  transform(data: string): string {
    const start = new Date(data);
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const date = start > today ? start : today;
    return formatDateForMinMax(date);
  }

}
