//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { ClrWizard } from "@clr/angular";
import { MsalHttpRequestService } from "@Msal/services/msalHttpRequest.service";
import { UserFormComponent, UserModel } from "@Shared/components/userForm.component";
import { HttpRequest, HttpRequestType } from "@Shared/utils/httpRequest";
import { StringUtils } from "@Shared/utils/stringUtils";
import { Company } from "./company";
import { CompanyEditComponent } from "./companyEdit.component";
import { formatDateForMinMax } from "./date.helper";
import { EndDateType, IEditableSubscription, IGroupingKey, OpenMode, Product, Subscription, SubscriptionGroup } from "./subscription";

export interface CommonSubscriptionWizardResult {
    orderId: string;
    company: Company;
    asc: UserModel;
    additionalCC: CommonEmailListManager;
}

export class CommonEmailListManager {
    emails: string;
    invalid: string[] = [];

    public getEmails(): string[] {
        const additionalCCs = this.emails ? this.emails.split(',') : [];
        additionalCCs.forEach((s, index) => additionalCCs[index] = s.trim())
        return additionalCCs;
    }

    public findInvalidAddresses(): void {
        this.invalid = [];
        for (const email of this.getEmails()) {
            if (!StringUtils.validateEmailAddress(email)) {
                this.invalid.push(email);
            }
        }
    }
}

export class CommonSubscriptionManager {
    subscriptions: IEditableSubscription[] = [new Subscription()];

    public setSubscriptions(subscriptions: IEditableSubscription[]) {
        this.subscriptions = subscriptions;
        this.groupSubscriptionsByProduct();
    }

    public addSubscription() {
        this.subscriptions.push(new Subscription());
    }

    public removeSubscription(value: Subscription) {
        var index = this.subscriptions.indexOf(value, 0);
        if (index > -1) {
            this.subscriptions.splice(index, 1);
        }
    }

    public canRemoveSubscription() {
        return this.subscriptions.length > 1;
    }

    public groupSubscriptionsByProduct() {
        // Group by products.
        function getGroupingKey(subs: IEditableSubscription): IGroupingKey {
            return {
                product: subs.product,
                startDate: subs.startDate,
                endDate: subs.endDate,
                endDateType: subs.endDateType,
                equals(other: IGroupingKey): boolean {
                    return this.product === other.product && this.startDate === other.startDate && this.endDate === other.endDate && this.endDateType === other.endDateType;
                }
            };
        }

        let groupedSubscriptions: Array<SubscriptionGroup> = new Array<SubscriptionGroup>();
        for (let subscriptionIx = 0; subscriptionIx < this.subscriptions.length; ++subscriptionIx) {
            let subscription: IEditableSubscription = this.subscriptions[subscriptionIx];
            if (subscription instanceof Subscription) {
                const key: IGroupingKey = getGroupingKey(subscription);
                let group = groupedSubscriptions.find(s => getGroupingKey(s).equals(key));
                if (!group) {
                    group = new SubscriptionGroup();
                    groupedSubscriptions.push(group);
                }
                group.addSubscription(subscription);
            }
        }

        this.subscriptions = groupedSubscriptions;
    }

    private getProductInfoFromPartNumber(products: Product[], partNumber: string | null | undefined, getter: (product: Product) => string): string {
        if (partNumber === undefined || partNumber == null) {
            return "";
        }
        const product: Product | undefined = products.find(p => p.partNumber === partNumber);
        return product !== undefined ? getter(product) : "<Product does not exist>";
    }

    public getProductNameFromPartNumber(products: Product[], partNumber: string | null | undefined): string {
        return this.getProductInfoFromPartNumber(products, partNumber, p => p.name);
    }

    public getProductCategoryFromPartNumber(products: Product[], partNumber: string | null | undefined): string {
        return this.getProductInfoFromPartNumber(products, partNumber, p => p.productCategory);
    }

    public isLicenseExtensionCheckboxVisible(mode: OpenMode, products: Product[], subscription: Subscription): boolean {
        if (mode == OpenMode.Renewal || subscription.product == null) return false;
        const category = this.getProductCategoryFromPartNumber(products, subscription.product);
        const isVisible = !['cloud', 'Ansys Online'].includes(category);
        if (!isVisible) {
            subscription.endDateType = EndDateType.Expiration;
        }
        return isVisible;
    }

    public areAllSubscriptionsValid(): boolean {
        if (this.subscriptions.length < 1) {
            return false;
        }

        return this.subscriptions.every(subscription => subscription.isValid);
    }
}

@Component({
    selector: 'common-subscription-wizard',
    templateUrl: 'commonSubscriptionWizard.component.html',
})
export class CommonSubscriptionWizardComponent implements OnInit, AfterViewInit, OnChanges {
    @ViewChild('wizard') wizard: ClrWizard;
    @ViewChild('companyEdit') companyEdit: CompanyEditComponent;
    @ViewChild('ascForm') ascForm: UserFormComponent;

    @Input() open: boolean = false;
    @Input() loading: boolean = false;
    @Input() mode: OpenMode = OpenMode.Creation;
    @Input() products: Product[] = [];
    @Input() allSubscriptionsValid: boolean = false;

    @Output() close = new EventEmitter();
    @Output() dispose = new EventEmitter();
    @Output() process = new EventEmitter<CommonSubscriptionWizardResult>();

    get title(): string {
        switch (this.mode) {
            case OpenMode.Creation:
                return "Create Subscriptions";
            case OpenMode.Renewal:
                return "Renew Subscriptions";
            default:
                throw new Error("Unsupported open mode " + this.mode);
        }
    }

    get isInRenewalMode(): boolean {
        return this.mode == OpenMode.Renewal;
    }

    orderId: string = '';
    companyId: string = '';
    company: Company = new Company();
    additionalCC: CommonEmailListManager = new CommonEmailListManager();

    userRequest: HttpRequest;

    constructor(private httpService: MsalHttpRequestService) { }

    ngOnInit(): void {
        this.userRequest = this.httpService.getRequest(HttpRequestType.GET, "getuser/GetUserWithEmail");
    }

    ngAfterViewInit(): void {
        this.ascForm.onUserNotFound.on(model => {
            if (this.company) {
                model.city = this.company.city;
                model.country = this.company.country;
                model.state = this.company.state;
                model.postalCode = this.company.postalCode;
                model.streetAddress = this.company.streetAddress;
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['open'] && changes['open'].currentValue == false && !changes['open'].isFirstChange()) {
            this.reset();
        }
    }

    setCompanyForFirstStep(companyId: string): void {
        this.companyId = companyId;
        this.companyEdit.check(companyId);
    }

    setASCForSecondStep(ascEmail: string): void {
        this.ascForm.model.email = ascEmail;
    }

    handleSubmit(): void {
        const result: CommonSubscriptionWizardResult = {
            orderId: this.orderId,
            company: this.company,
            asc: this.ascForm.model,
            additionalCC: this.additionalCC
        };

        this.process.emit(result);
    }

    isOrderIdValid(): boolean {
        return !StringUtils.IsNullOrEmpty(this.orderId);
    }

    reset(): void {
        this.wizard?.reset();
        this.companyEdit?.reset();
        this.ascForm?.reset();
        this.orderId = '';
        this.companyId = '';
        this.additionalCC = new CommonEmailListManager();
        this.dispose.emit();
    }
}
