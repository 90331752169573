//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component, Input, ViewChild } from '@angular/core';
import { StringUtils } from '@Shared/utils/stringUtils';
import { MsalHttpRequestService } from '@Msal/services/msalHttpRequest.service';
import { HttpRequestType, HttpRequest } from '@Shared/utils/httpRequest';
import { HttpRequestFragmenter } from '@Shared/utils/httpRequestFragmenter';
import { ServerSkipPageDatagridController } from '@Shared/utils/serverSkipPageDatagridController';
import { EndDateType, Product, Subscription } from 'business/subscriptionWizard/subscription';
import { RenewSubscriptionWizardComponent } from 'business/subscriptionWizard/renewSubscriptionWizard.component';

abstract class SubscriptionInfo {
    constructor(public activationId: string,
        public orderId: string,
        public creationDate: string,
        public startDate: string,
        public expirationDate: string,
        public tecsDate: string,
        public productName: string,
        public renewalPeriod: string,
        public isObsolete: string,
        public company: string,
        public userEmail: string,
        public partNumber: string,
        public productCategory: string,
        public freeActivationsCount: string) {
            this.creationDate = this.getFormattedDate(creationDate);
            this.startDate = this.getFormattedDate(startDate);
            this.expirationDate = this.getFormattedDate(expirationDate);
            this.tecsDate = this.getFormattedDate(tecsDate);

            if (!isObsolete){
                this.userEmail = userEmail ? userEmail : "Not Assigned";
            } else {
                this.userEmail = "Deleted";
            }
        }

    private getFormattedDate(dateStr: any) {
        const date = new Date(dateStr);

        let year = date.getFullYear();

        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        return `${month}/${day}/${year}`;
    }

    abstract assignment(): string;
}

class CompanySubscriptionInfo extends SubscriptionInfo {
    public assignment(): string {
        return this.userEmail;
    }
}

class UserSubscriptionInfo extends SubscriptionInfo {
    public assignment(): string {
        return this.company;
    }
}

@Component({
    selector: 'subscriptionTable',
    templateUrl: 'subscriptionTable.component.html',
    styleUrls: ['subscriptionTable.component.css'],
})
export class SubscriptionTableComponent {
    @Input() products: Product[] = [];
    @ViewChild("renewSubscriptionWizard", { static: false }) private renewSubscriptionWizard: RenewSubscriptionWizardComponent;
    public openRenewSubscriptionsWizard = false;
    public companyId: string;
    public orderId: string;
    public userId: string;
    public userMail: string;
    public errorMessage: string;

    public getSubscriptionsFromCompanyIdRequest: HttpRequest;
    public getSubscriptionsFromOrderIdRequest: HttpRequest;
    public getSubscriptionsFromUserRequest: HttpRequest;
    public getUserWithMailRequest: HttpRequest;
    public getUserWithIdRequest: HttpRequest;
    public getUserRequest: HttpRequest;
    public lastGetRequest: HttpRequest | null;
    public getSubscriptionsController: ServerSkipPageDatagridController;

    public deleteSubscriptionsRequest: HttpRequest;
    public deleteSubscriptionsFragmenter: HttpRequestFragmenter;
    public showDeleteConfirmationModel = false;

    public resendSubscriptionsRequest: HttpRequest;
    public resendSubscriptionsFragmenter: HttpRequestFragmenter;

    public subscriptions: any[] | null;
    public selectedSubscriptions: SubscriptionInfo[] = [];
    public includeExpired: boolean;
    public getAdminsForSelectedSubscriptionsRequest: HttpRequest;

    public showResendOrderConfirmationModel = false;
    public tryResendMailModel = { "mail": "" };

    public constructor(private api: MsalHttpRequestService) {
        this.getSubscriptionsFromCompanyIdRequest = api.getRequest(HttpRequestType.GET, "admin/GetSubscriptionsFromCompanyId");
        this.getSubscriptionsController = new ServerSkipPageDatagridController(this.getSubscriptionsFromCompanyIdRequest);

        this.getSubscriptionsFromCompanyIdRequest.onStart.on((req: any) => {
            req.query.companyId = this.getCompanyWithPrefix(this.companyId);
        });

        this.getSubscriptionsFromCompanyIdRequest.onSuccess.on((req: any) => {
            this.onGetSubscriptionsFromCompany(req.responseObj);
        });

        this.getSubscriptionsFromCompanyIdRequest.onError.on(req => {
            this.setErrorMessage(req);
        });

        this.getSubscriptionsFromOrderIdRequest = api.getRequest(HttpRequestType.GET, "admin/GetSubscriptionsFromOrderId");

        this.getSubscriptionsFromOrderIdRequest.onStart.on((req: any) => {
            req.query.orderId = this.orderId;
        });

        this.getSubscriptionsFromOrderIdRequest.onSuccess.on((req: any) => {
            this.onGetSubscriptionsFromCompany(req.responseObj);
        });

        this.getSubscriptionsFromOrderIdRequest.onError.on(req => {
            this.setErrorMessage(req);
        });

        this.getSubscriptionsFromUserRequest = api.getRequest(HttpRequestType.GET, "admin/GetSubscriptionsFromUserMail");

        this.getSubscriptionsFromUserRequest.onSuccess.on((req: any) => {
            this.onGetSubscriptionsFromUser(req.responseObj);
        });

        this.getSubscriptionsFromUserRequest.onError.on(req => {
            this.setErrorMessage(req);
        });

        this.deleteSubscriptionsRequest = this.api.getRequest(HttpRequestType.DELETE, "admin/DeleteSubscription");
        this.deleteSubscriptionsFragmenter = new HttpRequestFragmenter(this.deleteSubscriptionsRequest);

        this.deleteSubscriptionsFragmenter.onStartFragment.on((fragmenter: HttpRequestFragmenter) => {
            fragmenter.request.query.activationId = this.selectedSubscriptions[fragmenter.currentIndex].activationId;
        });

        this.deleteSubscriptionsFragmenter.onStop.on((fragmenter: HttpRequestFragmenter) => {
            if(fragmenter.isInError)
                this.errorMessage = fragmenter.errorMessages.join("; ");

            this.selectedSubscriptions = [];
            this.lastGetRequest!.send();
        });

        this.resendSubscriptionsRequest = this.api.getRequest(HttpRequestType.POST, "admin/ResendSubscriptionsNotification");
        this.resendSubscriptionsFragmenter = new HttpRequestFragmenter(this.resendSubscriptionsRequest);

        this.resendSubscriptionsFragmenter.onStartFragment.on((fragmenter: HttpRequestFragmenter) => {
            fragmenter.request.query.orderId = this.selectedSubscriptions[fragmenter.currentIndex].orderId;
            fragmenter.request.query.resendEmail = this.tryResendMailModel.mail;
        });

        this.resendSubscriptionsFragmenter.onStop.on((fragmenter: HttpRequestFragmenter) => {
            if(fragmenter.isInError)
                this.errorMessage = fragmenter.errorMessages.join("; ");

            this.selectedSubscriptions = [];
            this.lastGetRequest!.send();
        });

        this.getAdminsForSelectedSubscriptionsRequest = this.api.getRequest(HttpRequestType.POST, "admin/AdminsForSubscriptions");
        this.getAdminsForSelectedSubscriptionsRequest.onStart.on((req: any) => {
            req.body = {
                activationIds: []
            };
            for (const subscription of this.selectedSubscriptions) {
                req.body.activationIds.push(subscription.activationId);
            }
        });

        this.getAdminsForSelectedSubscriptionsRequest.onComplete.on(req => {
            if(req.error){
                this.setErrorMessage(req);
            } else {
                let ascEmail: string | null = null;
                let response = this.getAdminsForSelectedSubscriptionsRequest.responseObj;

                if (response !== null && response.emailAddresses.length === 1) {
                    ascEmail = response.emailAddresses[0];
                }

                if (ascEmail !== null) 
                    this.renewSubscriptionWizard.setASCForSecondStep(ascEmail);

                this.openRenewSubscriptionsWizard = true;
            }
        });

        this.getUserWithMailRequest = this.api.getRequest(HttpRequestType.GET, "Admin/GetADB2CUserWithMail");

        this.getUserWithMailRequest.onSuccess.on(req => {
            let response = req.responseObj;

            if (response.error) {
                this.errorMessage = response.errorInfo;
            } else {
                this.dispatchGetSubscriptionsFromUser(response.email);
            }
        });

        this.getUserWithMailRequest.onError.on(req => {
            this.setErrorMessage(req);
        });

        this.getUserWithIdRequest = this.api.getRequest(HttpRequestType.GET, "Admin/GetADB2CUserWithId");
        this.getUserWithIdRequest.onSuccess.on(req => {
            let response = req.responseObj;
            
            if (response.error) {
                this.errorMessage = `No user with id '${this.userId}'`;
            } else {
                this.dispatchGetSubscriptionsFromUser(response.email);
            }
        });

        this.getUserWithIdRequest.onError.on(req => {
            this.setErrorMessage(req);
        });
    }

    public findByCompany(): void {
        this.errorMessage = "";
        this.getSubscriptionsController = new ServerSkipPageDatagridController(this.getSubscriptionsFromCompanyIdRequest);
        if (this.includeExpired) {
            this.getSubscriptionsController.askCount = false;
        }
        this.lastGetRequest = this.getSubscriptionsFromCompanyIdRequest;
        this.subscriptions = null;
        this.getSubscriptionsFromCompanyIdRequest.send();
    }

    public findByOrder(): void {
        this.errorMessage = "";
        this.getSubscriptionsController = new ServerSkipPageDatagridController(this.getSubscriptionsFromOrderIdRequest);
        this.lastGetRequest = this.getSubscriptionsFromOrderIdRequest;
        this.subscriptions = null;
        this.getSubscriptionsFromOrderIdRequest.send();
    }

    public findByUserMail(): void {
        this.errorMessage = "";
        this.lastGetRequest = this.getSubscriptionsFromUserRequest;
        this.subscriptions = null;
        this.getSubscriptionsByUserMail(this.userMail);
    }

    public findByUserId(): void {
        this.errorMessage = "";
        this.lastGetRequest = this.getSubscriptionsFromUserRequest;
        this.subscriptions = null;
        this.getSubscriptionsByUserId(this.userId);
    }

    // Extracted because it will be moved to services in the future.
    // Triggers getSubscriptionsFromUserRequest
    public getSubscriptionsByUserMail(value: string) {
        this.getUserWithMailRequest.query.mail = value;
        this.getUserWithMailRequest.send();
    }

    // Extracted because it will be moved to services in the future.
    // Triggers getSubscriptionsFromUserRequest
    public getSubscriptionsByUserId(value: string) {
        this.getUserWithIdRequest.query.user = value;
        this.getUserWithIdRequest.send();
    }

    public searchIsNullOrEmpty(field: string): boolean {
        return !StringUtils.IsNullOrEmpty(field);
    }

    public isSubscriptionSelectable(subscription: any): boolean {
        if (subscription.isObsolete) {
            return false;
        }

        return this.selectedSubscriptions.length == 0 || this.selectedSubscriptions.every(sub => sub.company == subscription.company);
    }

    private onGetSubscriptionsFromUser (resp: any) {
        this.subscriptions = [];
        if (resp.collection !== null) {
            resp.collection.forEach((s: UserSubscriptionInfo) => {
                let subscription = new UserSubscriptionInfo(s.activationId, s.orderId, s.creationDate, s.startDate, s.expirationDate, s.tecsDate, s.productName, s.renewalPeriod, s.isObsolete, this.getFormattedCompany(s.company), s.userEmail, s.partNumber, s.productCategory, s.freeActivationsCount);
                this.subscriptions?.push(subscription);
            });
        }

        if (resp.company !== null) {
            this.companyId = resp.company;
        }
    }

    private onGetSubscriptionsFromCompany (resp: any) {
        this.subscriptions = [];
        if (resp.collection !== null) {
            resp.collection.forEach((s: CompanySubscriptionInfo) => {
                let subscription = new CompanySubscriptionInfo(s.activationId, s.orderId, s.creationDate, s.startDate, s.expirationDate, s.tecsDate, s.productName, s.renewalPeriod, s.isObsolete, this.getFormattedCompany(s.company), s.userEmail, s.partNumber, s.productCategory, s.freeActivationsCount);
                this.subscriptions?.push(subscription);
            });
        }

        if (resp.company !== null) {
            this.companyId = resp.company;
        }
    }

    public deleteSelectedSubscriptions() {
        this.errorMessage = "";
        this.deleteSubscriptionsFragmenter.reset();
        this.deleteSubscriptionsFragmenter.maxIndex = this.selectedSubscriptions.length;
        this.deleteSubscriptionsFragmenter.start();
    }

    public resendSubscriptionEmail() {
        this.errorMessage = "";
        this.resendSubscriptionsFragmenter.reset();
        this.resendSubscriptionsFragmenter.maxIndex = this.selectedSubscriptions.length;
        this.resendSubscriptionsFragmenter.start();
    }

    public openWizardForRenewal(): void {
        this.errorMessage = "";
        // Set subscriptions to edit in the wizard.
        const wizardSubscriptions = new Array<Subscription>();
        var companyId = this.getFormattedCompany(this.selectedSubscriptions[0].company);

        for (const subscription of this.selectedSubscriptions) {
            const wizardSubscription = new Subscription();
            wizardSubscription.product = subscription.partNumber;
            wizardSubscription.quantity = "1";
            wizardSubscription.startDate = subscription.startDate;
            wizardSubscription.endDate = subscription.tecsDate;
            wizardSubscription.endDateType = EndDateType.Tecs;
            wizardSubscription.activationId = subscription.activationId;
            wizardSubscriptions.push(wizardSubscription);
        }

        if (companyId !== null) this.renewSubscriptionWizard.setCompanyForFirstStep(companyId);
        this.renewSubscriptionWizard.setSubscriptionsForThirdStep(wizardSubscriptions);
        this.getAdminsForSelectedSubscriptionsRequest.send();
    }

    private getCompanyWithPrefix(id: string | null): string {
        return `Company_${id}`;
    }

    private getFormattedCompany(company: string): string {
        let tokens = company.split('_');
        if (tokens.length > 1)
            company = tokens[1];

        return company;
    }

    public isGetSubscriptionsRequestInProgress() : boolean {
        return this.getSubscriptionsFromCompanyIdRequest.isLoading || 
                this.getSubscriptionsFromOrderIdRequest.isLoading ||
                this.getSubscriptionsFromUserRequest.isLoading ||
                this.getUserWithIdRequest.isLoading ||
                this.getUserWithMailRequest.isLoading ||
                this.getAdminsForSelectedSubscriptionsRequest.isLoading ||
                this.deleteSubscriptionsRequest.isLoading ||
                this.resendSubscriptionsRequest.isLoading;
    }

    private setErrorMessage(req: HttpRequest) {
        if (req.error.name == "HttpErrorResponse")
                this.errorMessage = "Server Error";
            else
                this.errorMessage = req.errorMessage;
    }

    private dispatchGetSubscriptionsFromUser(email: string) {
        this.getSubscriptionsFromUserRequest.query.userMail = email;
        this.getSubscriptionsController = new ServerSkipPageDatagridController(this.getSubscriptionsFromUserRequest);
        this.getSubscriptionsFromUserRequest.send(); 
    }
}
