//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { HttpRequest } from "./httpRequest";
import { ServerDatagridController } from "./serverDatagridController";

export class ServerSkipPageDatagridController<ElementType = any> extends ServerDatagridController<ElementType> {
    private _askCount: boolean;

    public constructor(request: HttpRequest, page: number = 1, pageSize: number = 10) {
        super(request, page, pageSize);
        this._askCount = true;
    }

    public set askCount(value: boolean) {
        this._askCount = value;
    }

    protected onRequestStart(): void {
        super.onRequestStart();

        this.request.query.page = this.getPage();
        this.request.query.pageSize = this.pageSize;
        this.request.query.askCount = this._askCount;

        this.collection = [];
    }

    protected onRequestSuccess(): void {
        super.onRequestSuccess();

        let response = this.request.responseObj;
        if (response.count) {
            this.totalCount = response.count;
        }
        this.collection = response.collection;
    }

    protected onFilterChanged():void {
        this.askCount = true;
        super.onFilterChanged();
    }

    public refresh():void {
        super.refresh();
        this._request.send();
    }
}
