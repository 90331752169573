<style>
    input {
        width: 250px
    }

    input[type="date"] {
        width: 200px;
    }
</style>

<clr-wizard (clrWizardOnCancel)="doCancel()" #wizard [(clrWizardOpen)]="isWizardOpen" clrWizardSize="xl">
    <clr-wizard-title>Create Trial Subscriptions</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">Cancel</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">Back</clr-wizard-button>
    <clr-wizard-button [type]="'next'">Next</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">Create</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="!isTrialSubValid()" (clrWizardPageOnCommit)="doNext()"
        (clrWizardPageOnCancel)="doCancel()" clrWizardPagePreventDefault="true">
        <ng-template clrPageTitle>Trial Assignment</ng-template>
        <locker [enabled]="loadingFlag"></locker>
        <div class="spinner" *ngIf="loadingFlag">
            Loading...
        </div>
        <clr-alert *ngIf="errorFlag" [clrAlertType]="'alert-danger'">
            <div class="alert-item" *ngFor="let company of notFoundCompanies">
                Invalid {{(notFoundCompanies.length > 0) ? "company:" : "companies"}} {{company}}
            </div>
        </clr-alert>
        <form clrForm style="margin-bottom: 20px">
            <clr-control-container>
                <label for="productInput">Product</label>
                <select clrControl id="productInput" required [(ngModel)]="productPartNumber" name="productInput">
                    <option *ngFor="let p of products" [value]="p.partNumber">{{p.name}}</option>
                </select>
            </clr-control-container>
            <clr-select-container>
                <label for="typeInput">Assign</label>
                <select clrSelect id="typeInput" name="typeInput" [(ngModel)]="typeTrialSubInput">
                    <option value="one">Single user</option>
                    <option value="multiple">Multiple users</option>
                </select>
            </clr-select-container>
            <csv-file-input *ngIf="typeTrialSubInput == 'multiple'" itemName="Invitation" fileInputLabel="Emails"
                (fileRowsEvent)="getFileRows($event)">
            </csv-file-input>
        </form>
        <div style="margin-bottom: 5px;" [class.mainBorder]="oneUserTrialSub.isValid"
            [class.errorBorder]="!oneUserTrialSub.isValid" *ngIf="typeTrialSubInput == 'one'">
            <form clrForm>
                <div style="margin: 10px 20px 10px 20px;">
                    <clr-input-container>
                        <!-- hidden to have #invitationEmailModel defined -->
                        <label for="email">Email</label>
                        <input clrInput type="text" id="email" name="email" [(ngModel)]="oneUserTrialSub.email" email
                            required #invitationEmailModel="ngModel" />
                    </clr-input-container>
                    <clr-input-container>
                        <label for="orderId">Order ID</label>
                        <input clrInput type="text" id="orderId" [(ngModel)]="oneUserTrialSub.orderId" name="orderId">
                    </clr-input-container>
                    <clr-date-container>
                        <label for="startDate">Start Date</label>
                        <input clrDate type="date" id="startDate" required [(ngModel)]="oneUserTrialSub.startDate"
                            name="startDate">
                    </clr-date-container>
                    <clr-date-container>
                        <label for="endDate">End Date</label>
                        <input clrDate type="date" id="endDate" required [(ngModel)]="oneUserTrialSub.endDate"
                            [min]="oneUserTrialSub.startDate | minEndDate" name="endDate">
                    </clr-date-container>
                    <clr-input-container>
                        <label for="companyId">Customer Number</label>
                        <input clrInput type="text" placeholder="Customer Number" id="companyId"
                            [(ngModel)]="oneUserTrialSub.companyId" name="companyId">
                    </clr-input-container>
                    <clr-radio-container clrInline>
                        <label>Allotment</label>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio [name]="AllotmentTypes.AEU" required
                                [value]="AllotmentTypes.AEU" [(ngModel)]="oneUserTrialSub.allotment" />
                            <label>{{AllotmentTypes.AEU}} (legacy)</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio [name]="AllotmentTypes.AEC" required
                                [value]="AllotmentTypes.AEC" [(ngModel)]="oneUserTrialSub.allotment" />
                            <label>{{AllotmentTypes.AEC}}</label>
                        </clr-radio-wrapper>
                        <clr-radio-wrapper>
                            <input type="radio" clrRadio [name]="AllotmentTypes.AHC" required
                                [value]="AllotmentTypes.AHC" [(ngModel)]="oneUserTrialSub.allotment" />
                            <label>{{AllotmentTypes.AHC}}</label>
                        </clr-radio-wrapper>
                    </clr-radio-container>
                    <div style="margin-top:10px">
                        Optional: use the CC field below to specify additional contact(s) you wish to CC.
                        To copy multiple people, enter email addresses separated by commas.
                    </div>
                    <clr-textarea-container>
                        <label for="invitationCcs">CC for Notification Emails</label>
                        <textarea clrTextarea id="invitationCcs" [(ngModel)]="userTrialCcsInput" name="invitationCcs"
                            style="width: 100%">
                        </textarea>
                    </clr-textarea-container>

                    <div *ngIf="oneUserTrialSub.additionalCCsInvalidAddresses.length > 0" class="errorBlock">
                        <span>Invalid address:</span>
                        <ul>
                            <li *ngFor="let invalidAddress of oneUserTrialSub.additionalCCsInvalidAddresses">
                                {{invalidAddress}}
                            </li>
                        </ul>
                    </div>
                </div>
            </form>
        </div>
        <div *ngIf="typeTrialSubInput == 'multiple'">
            <clr-datagrid class="datagrid-compact">
                <clr-dg-column>Email</clr-dg-column>
                <clr-dg-column>Order</clr-dg-column>
                <clr-dg-column>Start Date</clr-dg-column>
                <clr-dg-column>End Date</clr-dg-column>
                <clr-dg-column>Customer Number</clr-dg-column>
                <clr-dg-column>Allotment</clr-dg-column>
                <clr-dg-column>Notification CCs</clr-dg-column>

                <clr-dg-row *ngFor="let trial of getTrialList()">
                    <clr-dg-cell [style.color]="(trial.isValid) ? 'rgb(0,0,0)' : 'rgb(255, 0, 0)'">{{trial.email}}
                    </clr-dg-cell>
                    <clr-dg-cell [style.color]="(trial.isValid) ? 'rgb(0,0,0)' : 'rgb(255, 0, 0)'">{{trial.orderId}}
                    </clr-dg-cell>
                    <clr-dg-cell [style.color]="(trial.isValid) ? 'rgb(0,0,0)' : 'rgb(255, 0, 0)'">{{trial.startDate}}
                    </clr-dg-cell>
                    <clr-dg-cell [style.color]="(trial.isValid) ? 'rgb(0,0,0)' : 'rgb(255, 0, 0)'">{{trial.endDate}}
                    </clr-dg-cell>
                    <clr-dg-cell [style.color]="(trial.isValid) ? 'rgb(0,0,0)' : 'rgb(255, 0, 0)'">{{trial.companyId}}
                    </clr-dg-cell>
                    <clr-dg-cell [style.color]="(trial.isValid) ? 'rgb(0,0,0)' : 'rgb(255, 0, 0)'">{{trial.allotment}}
                    </clr-dg-cell>
                    <clr-dg-cell [style.color]="(trial.isValid) ? 'rgb(0,0,0)' : 'rgb(255, 0, 0)'">
                        {{trial.userTrialCcs.join(", ")}}</clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>{{getTrialList().length}} Trial Subscription(s)</clr-dg-footer>
            </clr-datagrid>
            <div *ngIf="!isTrialSubValid() && this.multipleUserTrialSub.length > 0" class="errorBlock">
                <span>One or more trial subscriptions is not valid.</span>
            </div>
        </div>
    </clr-wizard-page>

    <clr-wizard-page clrWizardPagePreventDefaultNext="true" (clrWizardPageOnCommit)="doFinish()">
        <request-presenter [request]="postSubscriptionsRequest" [locker]="true"></request-presenter>
        <ng-template clrPageTitle>Validation</ng-template>
        <div>
            <h4>Trial Subscription</h4>
            {{getProductNameFromPartNumber(productPartNumber)}}
        </div>
        <div *ngIf="validationVisible">
            <clr-datagrid class="datagrid-compact">
                <clr-dg-column>Email</clr-dg-column>
                <clr-dg-column>Order</clr-dg-column>
                <clr-dg-column>Start Date</clr-dg-column>
                <clr-dg-column>End Date</clr-dg-column>
                <clr-dg-column>Company Name</clr-dg-column>
                <clr-dg-column>Allotment</clr-dg-column>
                <clr-dg-column>Notification CCs</clr-dg-column>

                <clr-dg-row *ngFor="let trial of getTrialList()">
                    <clr-dg-cell>{{trial.email}}</clr-dg-cell>
                    <clr-dg-cell>{{trial.orderId}}</clr-dg-cell>
                    <clr-dg-cell>{{trial.startDate}}</clr-dg-cell>
                    <clr-dg-cell>{{trial.endDate}}</clr-dg-cell>
                    <clr-dg-cell>{{foundCompanies.get(trial.companyId).displayName}}</clr-dg-cell>
                    <clr-dg-cell>{{trial.allotment}}</clr-dg-cell>
                    <clr-dg-cell>{{trial.userTrialCcs.join(", ")}}</clr-dg-cell>
                </clr-dg-row>
                <clr-dg-footer>{{getTrialList().length}} Trial Subscription(s)</clr-dg-footer>
            </clr-datagrid>
        </div>
    </clr-wizard-page>
</clr-wizard>