//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

// Source: https://devblogs.microsoft.com/premier-developer/angular-how-to-add-application-insights-to-an-angular-spa/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
export interface IAppConfig{
    ExportControl : {
        EscalationScreeningFormUrl: string
    }
}

@Injectable()
export class Globals {

    constructor(private httpClient: HttpClient){}

    public get IsAdmin(): boolean {
        return localStorage.getItem("IsAdmin") == "true";
    }

    public set IsAdmin(value: boolean) {
        localStorage.setItem("IsAdmin", value ? "true" : "false");
    }

    public get IsExportScreeningUser(): boolean {
        return localStorage.getItem("IsExportScreeningUser") == "true";
    }

    public set IsExportScreeningUser(value: boolean) {
        localStorage.setItem("IsExportScreeningUser", value ? "true" : "false");
    }

    public GetExportScreeningEscalationFormUrl(): Promise<string>{
        const configFile = `assets/config.json`;
        const EscalationFormUrlKey = "escalation-form";
        let escalationFormUrl = sessionStorage.getItem(EscalationFormUrlKey);
        
        return new Promise<string>((resolve, reject) => {
            //checks the value is present in the localstorage            
            if(escalationFormUrl) resolve(escalationFormUrl);
            else{
                this.httpClient.get(configFile).toPromise().then((response : IAppConfig) => {
                    escalationFormUrl = response.ExportControl.EscalationScreeningFormUrl;
                    sessionStorage.setItem(EscalationFormUrlKey, escalationFormUrl);
                    resolve(escalationFormUrl);
                })
                .catch(() => {
                    reject(`Could not load file '${configFile}'`);
                });
            }
        });
    }

    public get DefaultRoute(): string {
        if (this.IsExportScreeningUser) {
            return '/screening';
        } else if (this.IsAdmin) {
            return '/dashboard';
        } else {
            return '/';
        }
    }
}
