//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { HttpRequest, HttpRequestType } from '@Shared/utils/httpRequest';
import { Component, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from "@clr/angular";
import { MsalHttpRequestService } from '@Msal/services/msalHttpRequest.service';
import { FilterService } from 'services/filter.service';

class RecordsRequestResponse {
    public records: UserActionRecord[];
    public start: number;
    public count: number;
}

class TypesRequestResponse {
    public types: string[];
}

class UserActionRecord {
    id: string;
    date: string;
    authorId: string;
    authorEmail: string;
    actionName: string;
    targetId: string;
    targetEmail: string;
    company: string;
    product: string;
}

@Component({
    selector: 'records-table',
    templateUrl: 'records.component.html',
})
export class RecordsComponent implements OnInit {

    public noResultInfo: string | null = null;

    public currentState: ClrDatagridStateInterface | null = null;

    public currentPage: number = 1;
    public pageSize: number = 20;
    public totalItem: number = 0;
    public records: UserActionRecord[] = [];
    public types: string[] = [];

    public loading = false;

    public getRecordsRequest: HttpRequest;
    public getRecordTypesRequest: HttpRequest;

    constructor(
        private cloudService: MsalHttpRequestService,
        private filterService: FilterService
    ) {
    }
    public ngOnInit(): void {
        this.getRecordsRequest = this.cloudService.getRequest(HttpRequestType.GET, "Admin/Records")
        this.getRecordsRequest.onSuccess.on(r => {
            let response: RecordsRequestResponse = r.responseObj;

            this.records = response.records;

            this.totalItem = response.start + response.count;
            if (response.count >= this.pageSize) {
                this.totalItem += this.pageSize;
            }

            this.loading = false;

            console.log("records updated");
        });
        this.getRecordsRequest.onError.on(r => {
            this.noResultInfo = "Error " + r.errorMessage.toString();
            this.loading = false;

            console.log("records failed to update");
        });

        this.getRecordTypesRequest = this.cloudService.getRequest(HttpRequestType.GET, "Admin/RecordTypes")
        this.getRecordTypesRequest.onSuccess.on(r => {
            let response: TypesRequestResponse = r.responseObj;

            this.types = response.types;
            this.types.unshift("All actions");

            console.log("record types updated");
        });
        this.getRecordTypesRequest.onError.on(r => {
            this.noResultInfo = "Error " + r.errorMessage.toString();
            console.log("record types failed to update");
        });
    }

    public refresh() {
        this.refreshWithState(<ClrDatagridStateInterface>this.currentState);
    }

    public refreshWithState(state: ClrDatagridStateInterface) {
        this.loading = true;

        //sorting
        let sortBy: string = "Id";
        let sortReverse: boolean = true;
        if (state.sort != null) {
            sortBy = state.sort.by.toString();
            sortReverse = state.sort.reverse;
        }

        //filtering
        let oldFiltersU: Array<any> | undefined = undefined;
        if (this.currentState != null) {
            oldFiltersU = this.currentState.filters;
        }
        if (oldFiltersU == undefined) {
            oldFiltersU = new Array();
        }
        let oldFilters: Array<any> = <Array<any>>oldFiltersU;

        let filtersU: any[] | undefined = state.filters;
        if (filtersU == undefined) {
            filtersU = new Array();
        }
        let filters = <any[]>filtersU;

        //add sorting/filtering to query

        const start = (state.page!.from || -1) >= 0
            ? state.page!.from as number
            : 0;

        this.getRecordsRequest.query = {
            start: start,
            count: this.pageSize,
            sortBy: sortBy,
            sortReverse: sortReverse
        };

        for (let i = 0; i < filters.length; i++) {
            // The "filter_" query parameter will filter the database results based on the property.
            // The property needs to match the table column name and be in the correct case.
            // The method "getPropertyForFilter" converts from camelCase to PascalCase.
            // Example: profile.email -> Profile.Email
            this.getRecordsRequest.query["filter_" + this.filterService.getPropertyForFilter(filters[i].property)] = filters[i].value;
        }

        this.getRecordsRequest.send();
        if (this.types.length == 0) this.getRecordTypesRequest.send();
    }



}
