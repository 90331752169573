//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Component } from '@angular/core';
import { UpdatableCollectionComponent, IEntryField} from 'userregistration/updatablecollection.component';

interface IBlacklistEntry {
    id: string;
    word: string; // Forbidden word in company name.
}

@Component({
    selector: 'blacklist-table',
    templateUrl: 'blacklist.component.html',
})
export class BlacklistComponent {

    constructor() {
    }

    public getEntryFields(): IEntryField[] {
        return [
            { name: 'Id', caption: 'Id' },
            { name: 'ForbiddenWordInCompanyName', caption: 'Forbidden Word In Company Name' }
        ]
    }

   public getEntryFieldValues(entry: IBlacklistEntry): string[] {
        let fields: string[] = [entry.id, entry.word];
        return fields;
    }
}
