//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { MsalHttpRequestService } from "@Msal/services/msalHttpRequest.service";
import { HttpRequest, HttpRequestType } from '@Shared/utils/httpRequest';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subscription, OpenMode, Product } from './subscription';
import { CommonSubscriptionManager, CommonSubscriptionWizardResult } from './commonSubscriptionWizard.component';


@Component({
    selector: 'discoverySubscriptionWizard',
    templateUrl: 'discoverySubscriptionWizard.component.html',
})
export class DiscoverySubscriptionWizardComponent implements OnInit {
    @Input() open: boolean = false;
    @Output() openChange = new EventEmitter<boolean>();

    @Input() products: Product[] = [];

    mode: OpenMode = OpenMode.Creation;

    subscriptionManager: CommonSubscriptionManager = new CommonSubscriptionManager();

    postSubscriptionsRequest: HttpRequest;

    constructor(private httpService: MsalHttpRequestService) { }

    ngOnInit(): void {
        this.postSubscriptionsRequest = this.httpService.getRequest(HttpRequestType.POST, 'Admin/CreateSubscriptions');
        this.postSubscriptionsRequest.onSuccess.on(() => this.openChange.emit(false));
    }

    submit(data: CommonSubscriptionWizardResult): void {
        // Unfold subscription groups, if any.
        let subscriptions = new Array<Subscription>();
        for (let editableSubscription of this.subscriptionManager.subscriptions) {
            subscriptions = subscriptions.concat(editableSubscription.getModelSubscriptions());
        }

        this.postSubscriptionsRequest.body = {
            company: data.company,
            ansysSupportCoordinator: data.asc,
            orderId: data.orderId,
            subscriptions: subscriptions,
            additionalCCs: data.additionalCC.getEmails(),
        };

        this.postSubscriptionsRequest.send();
    }

    reset(): void {
        this.subscriptionManager = new CommonSubscriptionManager();
    }
}
