<!--The content below is only a placeholder and can be replaced.-->
<clr-main-container [ngClass]="isOnline && isAuthorized ? 'authorized' : 'not-authorized'">
    <clr-header class="header-6 accountHeader" *ngIf="isOnline && isAuthorized">
        <div class="branding">
            <span class="title">
                <img class="clr-icon" src="assets/images/account-admin-logo-yellow-white.svg" id="banner-logo"
                    style="width: auto; height: 1.5rem;" />
            </span>
            <span *ngIf="environmentName!='prod'">{{environmentName}}</span>
            <span *ngIf="!inProd">DEV</span>
        </div>
        <div class="header-actions">
            <clr-dropdown class="nav-icon">
                <button type="button" clrDropdownToggle clrDropdownTrigger>
                    <clr-icon shape="user"></clr-icon>
                    <clr-icon shape="caret down"></clr-icon>
                </button>
                <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                    <button type="button" clrDropdownItem (click)="logout()">Log Out</button>
                </clr-dropdown-menu>
            </clr-dropdown>
        </div>
    </clr-header>

    <div class="signInContainer" *ngIf="isOnline && !isAuthorized">
        <div class="signInPanel">
            <img src="assets/images/account-admin-logo-yellow-black.svg">
            <ng-container *ngIf="!loginService.checkInProgress">
                <h6>
                    You must be an admin or export screening user to use this portal.
                </h6>
                <button class="btn btn-primary" (click)="logout()">Please Log Out</button>
            </ng-container>
            <ng-container *ngIf="loginService.checkInProgress">
                <h3>Verifying user credentials...</h3>
                <span class='spinner spinner-sm'>
                    Loading...
                </span>
            </ng-container>
        </div>
    </div>

    <div class="content-container" *ngIf="isOnline && isAuthorized">
        <clr-vertical-nav clr-vertical-nav [clrVerticalNavCollapsible]="true" [clr-nav-level]="1" class="navigation">

            <a clrVerticalNavLink routerLink="/dashboard" [routerLinkActive]="['active']" class="nav-link"
                *ngIf="isAdmin">
                <clr-icon clrVerticalNavIcon shape="bar-chart" class="is-solid">

                </clr-icon>
                <span>
                    Analytics
                </span>
            </a>


            <a clrVerticalNavLink routerLink="/users" [routerLinkActive]="['active']" class="nav-link" *ngIf="isAdmin">
                <clr-icon clrVerticalNavIcon shape="users" class="is-solid">

                </clr-icon>
                <span>
                    Users
                </span>
            </a>


            <a clrVerticalNavLink routerLink="/subscriptions" [routerLinkActive]="['active']" class="nav-link"
                *ngIf="isAdmin">
                <clr-icon clrVerticalNavIcon shape="bundle" class="is-solid">

                </clr-icon>
                <span>
                    Subscriptions
                </span>
            </a>


            <a clrVerticalNavLink routerLink="/company" [routerLinkActive]="['active']" class="nav-link"
                *ngIf="isAdmin">
                <clr-icon clrVerticalNavIcon shape="building" class="is-solid">

                </clr-icon>
                <span>
                    Company
                </span>
            </a>


            <a clrVerticalNavLink routerLink="/whitelist" [routerLinkActive]="['active']" class="nav-link"
                *ngIf="isAdmin">
                <clr-icon clrVerticalNavIcon shape="shield-check" class="is-solid">

                </clr-icon>
                <span>
                    Whitelist
                </span>
            </a>


            <a clrVerticalNavLink routerLink="/blacklist" [routerLinkActive]="['active']" class="nav-link"
                *ngIf="isAdmin">
                <clr-icon clrVerticalNavIcon shape="shield-x" class="is-solid">

                </clr-icon>
                <span>
                    Blacklist
                </span>
            </a>


            <a clrVerticalNavLink routerLink="/records" [routerLinkActive]="['active']" class="nav-link"
                *ngIf="isAdmin">
                <clr-icon clrVerticalNavIcon shape="scroll" class="is-solid">

                </clr-icon>
                <span>
                    User Records
                </span>
            </a>

            <a clrVerticalNavLink routerLink="/fulfillment" [routerLinkActive]="['active']" class="nav-link"
                *ngIf="isAdmin">
                <clr-icon clrVerticalNavIcon shape="replay-all" class="is-solid">

                </clr-icon>
                <span>
                    Fulfillment
                </span>
            </a>

            <a clrVerticalNavLink routerLink="/screening" [routerLinkActive]="['active']" class="nav-link"
                *ngIf="isAdmin || isExportScreeningUser">
                <clr-icon clrVerticalNavIcon shape="details" class="is-solid">

                </clr-icon>
                <span>
                    Export Screening
                </span>
            </a>

        </clr-vertical-nav>
        <main class="content-area">
            <router-outlet></router-outlet>
        </main>
    </div>
</clr-main-container>