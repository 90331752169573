<clr-datagrid [(clrDgSelected)]="selectedUsers" [appClrCheckboxDisplay]="false" [clrDgLoading]="loading"
    (clrDgRefresh)="refreshWithState($event)" (clrDgSelectedChange)="selectedUserChange()">
    <clr-dg-column [clrDgField]="'id'"> ID</clr-dg-column>
    <clr-dg-column [clrDgField]="'displayName'"> Name</clr-dg-column>
    <clr-dg-column [clrDgField]="'country'"> Country</clr-dg-column>
    <clr-dg-column [clrDgField]="'company'"> Company</clr-dg-column>
    <clr-dg-column [clrDgField]="'email'"> Email</clr-dg-column>
    <clr-dg-column [clrDgField]="'exportControlStatus'">
        <clr-dg-filter>
            <select-filter [availableValues]="['', 'No Match', 'Potential Match', 'Match', 'None']"
                [property]="'exportControlStatus'"></select-filter>
        </clr-dg-filter>
        Export Control Status
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'exportControlSource'">
        <clr-dg-filter>
            <select-filter [availableValues]="exportControlSources" [property]="'exportControlSource'"></select-filter>
        </clr-dg-filter>
        Export Control Source
    </clr-dg-column>
    <clr-dg-column [clrDgField]="'creationDate'"> Creation Date </clr-dg-column>

    <clr-dg-placeholder>No Result {{noResultInfo}}</clr-dg-placeholder>

    <!-- if server driven use ngFor and not clrDgItems ! -->
    <clr-dg-row *ngFor="let user of users" [clrDgItem]="user">
        <clr-dg-cell>{{user.id}}</clr-dg-cell>
        <clr-dg-cell>{{user.displayName}}</clr-dg-cell>
        <clr-dg-cell>{{user.country}}</clr-dg-cell>
        <clr-dg-cell>{{user.company}}</clr-dg-cell>
        <clr-dg-cell>{{user.email}}</clr-dg-cell>
        <clr-dg-cell>{{user.exportControlStatus}}
        </clr-dg-cell>
        <clr-dg-cell>{{user.exportControlSource}}
        </clr-dg-cell>
        <clr-dg-cell>{{user.creationDate.replace("T", " ").replace("Z", "")}}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        {{pagination.firstItem + 1}} - {{pagination.lastItem + 1}} of {{totalItem}}
        <clr-dg-pagination #pagination [(clrDgPage)]="currentPage" [clrDgPageSize]="pageSize"
            [clrDgTotalItems]="totalItem">
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>

<button class="btn btn-primary" (click)="refresh()">Refresh</button>
<button class="btn btn-primary" (click)="export()" [disabled]="exportingUsers">Export</button>

<span *ngIf="exportingUsers" class="progress-block">
    <span> Exporting </span>
    <span class="progress">
        <progress max="1" [value]="exportProgression || 0"></progress>
    </span>
</span>

<div *ngIf="selectedUsersDetails.length > 0">
    <div *ngFor="let selectedUser of selectedUsersDetails" class="card">
        <div class="card-header">
            Selected user: <span class="mainColor">{{ selectedUser.profile.displayName }}</span>
        </div>
        <div class="card-block">
            <div class="card-text">
                <ul class="list">
                    <object-view [object]="selectedUser"></object-view>
                    <li>
                        Subscriptions:
                        <request-presenter [request]="getSelectedUserSubscriptionsRequest">
                            <ng-template>
                                <object-view [object]="getSelectedUserSubscriptionsRequest.responseObj"></object-view>
                            </ng-template>
                        </request-presenter>

                    </li>
                </ul>

                <button class="btn btn-primary" (click)="selectedUser.showDeleteModal=true">Delete</button>
                <clr-modal [(clrModalOpen)]="selectedUser.showDeleteModal">
                    <h3 class="modal-title">Delete user</h3>
                    <div class="modal-body">
                        Are you sure you want to delete {{selectedUser.profile.displayName}}?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline"
                            (click)="selectedUser.showDeleteModal = false">Cancel</button>
                        <button type="button" class="btn btn-primary"
                            (click)="selectedUser.showDeleteModal = false;delete(selectedUser);">Ok</button>
                    </div>
                    <div class="modal-backdrop" aria-hidden="true"></div>
                </clr-modal>

                <button class="btn btn-primary" (click)="selectedUser.showSetARModal=true">Add Manual Check</button>
                <clr-modal [(clrModalOpen)]="selectedUser.showSetARModal">
                    <h3 class="modal-title">Add Manual Check</h3>
                    <div class="modal-body">
                        <form clrForm #checkForm="ngForm"
                            (ngSubmit)="selectedUser.showSetARModal = false; addManualCheck(selectedUser);">
                            <clr-select-container>
                                <label for="ar_status">Status</label>
                                <select clrSelect id="ar_status" name="status" #status="ngModel"
                                    [(ngModel)]="addManualCheckModel.status">
                                    <option>No Match</option>
                                    <option>Potential Match</option>
                                    <option>Match</option>
                                </select>
                            </clr-select-container>
                            <clr-textarea-container>
                                <label for="ar_just">Justification</label>
                                <textarea clrTextarea id="ar_just" rows="5" name="justification"
                                    #justification="ngModel" [(ngModel)]="addManualCheckModel.justification"></textarea>
                            </clr-textarea-container>

                            <div class="errorColor">
                                If the status becomes "No Match": an email is sent to the user to notify him that he
                                can retry.
                                <br />
                                <br />
                            </div>
                            <button type="button" class="btn btn-outline"
                                (click)="selectedUser.showSetARModal = false">Cancel</button>
                            <button type="submit" class="btn btn-primary">Submit</button>
                        </form>
                    </div>
                    <div class="modal-backdrop" aria-hidden="true"></div>
                </clr-modal>

                <span *ngIf="selectedUser!.isAdmin != null">
                    <button [hidden]="selectedUser!.isAdmin == true" class="btn btn-primary"
                        (click)="setAdmin(selectedUser)">Add to Admin group</button>
                    <button [hidden]="selectedUser!.isAdmin == false" class="btn btn-primary"
                        (click)="removeAdmin(selectedUser)">Remove from Admin group</button>
                </span>
            </div>
        </div>
    </div>
</div>
<div *ngIf="refreshingSelectedUsers" style="text-align:center; width:100%; margin:20px">
    <span class="spinner spinner-lg"></span>
</div>

<div class="card">
    <div class="card-header">
        ADB2C User Account Search
    </div>
    <div class="card-block">
        <div class="card-text">
            <form clrForm clrLayout="vertical">
                <clr-input-container>
                    <label for="ADB2CUserId">User Id</label>
                    <input clrInput id="ADB2CUserId" name="ADB2CUserId" [(ngModel)]="azureSearchId" />
                </clr-input-container>
                <clr-input-container>
                    <label for="ADB2CUserMail">User Mail</label>
                    <input clrInput id="ADB2CUserMail" name="ADB2CUserMail" [(ngModel)]="azureSearchMail" />
                </clr-input-container>
                <button id="searchBtn" class="btn btn-primary" (click)="searchUser()">Search</button>
            </form>
        </div>
    </div>
    <div class="card-block" *ngIf="azureUser || azureUserLoading || azureUserError">
        <div class="card-text">
            <div *ngIf="azureUserLoading" style="text-align:center; width:100%;">
                <span class='spinner spinner-lg'></span>
            </div>
            <ng-container *ngIf="azureUserError">
                <div class="errorColor">Error - {{azureUserError}}</div>
            </ng-container>
            <ng-container *ngIf="azureUser && !azureUserError">
                <button id="searchBtn" class="btn btn-primary" (click)="reset()">Close</button>
                <object-view [object]="azureUser"></object-view>
                <li>
                    Subscriptions:
                    <request-presenter [request]="getSearchedUserSubscriptionsRequest">
                        <ng-template>
                            <object-view [object]="getSearchedUserSubscriptionsRequest.responseObj"></object-view>
                        </ng-template>
                    </request-presenter>
                </li>

                <button class="btn btn-primary" (click)="showTryAgainModal=true">Send Try again mail</button>
                <clr-modal [(clrModalOpen)]="showTryAgainModal">
                    <h3 class="modal-title">Send Try Again Mail</h3>
                    <div class="modal-body">
                        <form clrForm #checkForm="ngForm" (ngSubmit)="showTryAgainModal = false; sendTryAgainMail();">
                            <section class="form-block">
                                <div class="form-group">
                                    <label for="try_mail">Mail</label>
                                    <input id="try_mail" name="mail" #status="ngModel" [(ngModel)]="tryMailModel.mail">
                                </div>
                                <div class="form-group">
                                    <label for="try_name">Name</label>
                                    <input id="try_name" name="name" #status="ngModel" [(ngModel)]="tryMailModel.name">
                                </div>

                                <br />
                                <button type="button" class="btn btn-outline"
                                    (click)="showTryAgainModal = false">Cancel</button>
                                <button type="submit" class="btn btn-primary">Submit</button>
                            </section>
                        </form>
                    </div>
                    <div class="modal-backdrop" aria-hidden="true"></div>
                </clr-modal>
            </ng-container>
        </div>
    </div>
</div>

<clr-modal [(clrModalOpen)]="isConfirmationModalOpen" [clrModalClosable]="false">
    <h3 class="modal-title">Deletion Result</h3>
    <div class="modal-body">
        <p>{{userDeletedStatus}}</p>
    </div>
    <div class="modal-footer">
        <button id="confirmationModalOK" type="button" class="btn btn-primary"
            (click)="disableUserDeletedConfirmationModalOKButton()" disabled>Ok</button>
    </div>
</clr-modal>