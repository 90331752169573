//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { HttpRequest, HttpRequestType } from '@Shared/utils/httpRequest';
import { MsalHttpRequestService } from '@Msal/services/msalHttpRequest.service';
import { Component, Input, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from "@clr/angular";
import { Sort } from '@Shared/utils/iDatagridController';
import { ServerSkipPageDatagridController } from '@Shared/utils/serverSkipPageDatagridController';

export interface IEntryField {
    name: string;
    caption: string;
}

type FieldValuesGetter = (entry: any) => string[];
@Component({
    selector: 'updatablecollection',
    templateUrl: 'updatablecollection.component.html',
})
export class UpdatableCollectionComponent implements OnInit {
    @Input() entryFields: IEntryField[];
    @Input() getEntryFieldValues: FieldValuesGetter;
    @Input() entriesUrl: string;
    @Input() entriesUpdatesUrl: string;

    private entriesGetRequest: HttpRequest;
    private entriesPostRequest: HttpRequest;
    public entriesController: ServerSkipPageDatagridController;
    public entries: any[];
    private updatesGetRequest: HttpRequest;
    private inProgressUpdatesGetRequest: HttpRequest;
    public updatesController: ServerSkipPageDatagridController;
    public updates: any[];
    public isUpdateCurrent: boolean = false;
    public entryForTest: string;

    constructor(private cloudService: MsalHttpRequestService) {
        this.entryForTest = "Id";
    }

    public getEntriesForTest(): any[] {
        return [['Id', 'Id'], ['Id', 'Id'], ['Id', 'Id'], ['Id', 'Id'], ['Id', 'Id']];
    }

    public get getEntriesGetRequest(): HttpRequest {
        return this.entriesGetRequest;
    }

    public get getEntriesPostRequest(): HttpRequest {
        return this.entriesPostRequest;
    }

    public get getUpdatesGetRequest(): HttpRequest {
        return this.updatesGetRequest;
    }

    public ngOnInit(): void {
        this.entriesGetRequest = this.cloudService.getRequest(HttpRequestType.GET, this.entriesUrl);
        this.entriesController = new ServerSkipPageDatagridController(this.entriesGetRequest, 1, 10);

        this.entriesGetRequest.onError.on(req => {
            this.entries = [];
        });
        this.entriesGetRequest.onSuccess.on(h => {
            this.entries = h.responseObj.collection;
        });

        this.entriesPostRequest = this.cloudService.getRequest(HttpRequestType.POST, this.entriesUrl);
        this.entriesPostRequest.onStart.on(r => {
            this.isUpdateCurrent = true;
        });
        this.entriesPostRequest.onSuccess.on(h => {
            // Refresh tables.
            this.updatesGetRequest.send();
        })
        this.entriesPostRequest.onError.on(r => {
            this.isUpdateCurrent = false;
        });

        this.updatesGetRequest = this.cloudService.getRequest(HttpRequestType.GET, this.entriesUpdatesUrl);
        this.updatesController = new ServerSkipPageDatagridController(this.updatesGetRequest, 1, 5);
        this.updatesGetRequest.onStart.on(req => {
            this.inProgressUpdatesGetRequest.send();
        });
        this.updatesGetRequest.onSuccess.on(h => {
            this.updates = h.responseObj.collection;
        });
        this.updatesGetRequest.onError.on(h => {
            this.updates = [];
        });

        this.entriesGetRequest.send();

        this.inProgressUpdatesGetRequest = this.cloudService.getRequest(HttpRequestType.GET, this.entriesUpdatesUrl);
        this.inProgressUpdatesGetRequest.onStart.on(r => {
            r.query.page = 1;
            r.query.pageSize = 5;
            r.query.askCount = true;
            r.query.onlyInProgress = true;
        })
        this.inProgressUpdatesGetRequest.onSuccess.on(h => {
            let wasUpdateCurrent = this.isUpdateCurrent;
            this.isUpdateCurrent = h.responseObj.count > 0;
            if (this.isUpdateCurrent) {
                setTimeout(() => {
                    this.updatesGetRequest.send();
                }, 2000);
            }
            else if (wasUpdateCurrent) {
                this.entriesGetRequest.send();
            }
        })
        this.updatesGetRequest.send();
    }

    public areUpdatesBeingLoadedForTheFirstTime() {
        return this.updatesGetRequest.isLoading && (!this.updates || this.updates.length == 0);
    }

    public refresh(state: ClrDatagridStateInterface): void {
        let sort: Sort = {
            sortBy: state.sort ? state.sort.by.toString() : null,
            isReversed: state.sort ? state.sort.reverse : false
        };
        this.entriesController.setFilters(<{ property: string, value: string }[]>state.filters);
        this.entriesController.setSort(sort);
    }

    public import(event: any): void {
        if (event.target.files && event.target.files.length > 0) {
            let reader = new FileReader();
            let file = event.target.files[0];
            reader.onload = () => {
                let content: string = reader.result as string;
                this.entriesPostRequest.body = content;
                this.entriesPostRequest.send();
            }
            reader.readAsText(<Blob>file);

            // Get ready to import this file again if needed.
            event.target.value = "";
        }
    }
}
