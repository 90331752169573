//
// Copyright (C) 2022 ANSYS, Inc. Unauthorized use, distribution, or duplication is prohibited.
//

import { Injectable } from '@angular/core';

import { HttpRequest, HttpRequestType } from '@Shared/utils/httpRequest';
import { MsalHttpRequestService } from '@Msal/services/msalHttpRequest.service';
import { AuthService } from '@Authentication';

import { Globals } from './globals.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public rolesRequest: HttpRequest;
  public checkInProgress: boolean = false;

  constructor(
    private globals: Globals,
    private httpService: MsalHttpRequestService,
    private auth: AuthService,
    private router: Router
  ) {
    this.rolesRequest = this.httpService.getRequest(HttpRequestType.GET, 'user/roles');
    this.rolesRequest.onStart.on(x => this.checkInProgress = true);
    this.rolesRequest.onComplete.on(x => this.checkInProgress = false);
    this.rolesRequest.onSuccess.on(x => {
      this.globals.IsAdmin = <boolean>x.responseObj.isAdmin;
      this.globals.IsExportScreeningUser = <boolean>x.responseObj.isExportScreeningUser;
      if (this.router.url == '/') {
        this.router.navigate([this.globals.DefaultRoute]);
      }
    });
    this.rolesRequest.onError.on(h => {
      this.globals.IsAdmin = false;
      this.globals.IsExportScreeningUser = false;
      console.error(h.errorMessage);
    });

    this.auth.token.subscribe(token => {
      if (token == null) {
        this.globals.IsAdmin = false;
        this.globals.IsExportScreeningUser = false;
        return;
      }

      this.rolesRequest.send();
    });
  }
}
